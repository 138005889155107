import {convertColor} from './convertColor.js';
export function convertGradient(gradient) {
    if (!gradient.type) {
        let colorStops = gradient.stops.map(function (stop) {
            return convertColor(stop.color) + ' ' + (stop.pos * 100) + '%';
        }).join(', ');

        let x = gradient.to[0] - gradient.from[0];
        let y = gradient.to[1] - gradient.from[1];
        //let deg = Math.atan2(y, x) * 180 / Math.PI + 90;
        let deg = (Math.atan2(y, x) * 180 / Math.PI + 90) % 360;

        if (deg < 0) {
            deg = 360 + deg;
        }

        return 'linear-gradient(' + deg + 'deg, ' + colorStops + ')';
    } else if (gradient.type == 1) {
        let colorStops = gradient.stops.map(function (stop) {
            return convertColor(stop.color) + ' ' + (stop.pos * 100) + '%';
        }).join(', ');

        let x = gradient.from[0] * 100 + '%';
        let y = gradient.from[1] * 100 + '%';

        return 'radial-gradient(circle at ' + x + ' ' + y + ', ' + colorStops + ')';
    } else if (gradient.type == 2) {
        let colorStops = gradient.stops.map(function (stop) {
            return convertColor(stop.color) + ' ' + (stop.pos * 100) + '%';
        }).join(', ');

        let x = gradient.to[0] - gradient.from[0];
        let y = gradient.to[1] - gradient.from[1];
        let deg = (Math.atan2(y, x) * 180 / Math.PI + 90) % 360;
        console.log(deg);

        if (deg < 0) {
            deg = 360 + deg;
        }

        let centerX = gradient.from[0] * 100 + '%';
        let centerY = gradient.from[1] * 100 + '%';

        return 'conic-gradient(from ' + deg + 'deg at ' + centerX + ' ' + centerY + ', ' + colorStops + ')';
    }
}