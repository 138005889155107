export const config = {
    "darkTheme": true,
    "host": "https://shareproto.com",
    "endpoint": "https://api.shareproto.com/v1",
    "project": "65e0ba9e8cc7e97b05cb",
    "database": "65e0bf7048b8e4d3bad6",
    "tableLifetime": 2, // Hours
    "cacheName": 'cache-v1.0.0',
    "vapidKey": "BK2bt8nFoJ6OkTO9xdDBLE0MDGxFjxOfAsaHmucTTYUXUbKoBTRHZ6YZI-v0cvlp7rBlF-Q4MgHDBZqosIxVMK0", //Firebase public key
    "firebase" : {
        apiKey: "AIzaSyBC2oRzu7Oq6dTPwrIr_D0PBwU-FWgQLzo",
        authDomain: "shareproto-3a54b.firebaseapp.com",
        projectId: "shareproto-3a54b",
        storageBucket: "shareproto-3a54b.appspot.com",
        messagingSenderId: "435410397573",
        appId: "1:435410397573:web:1432c7115aff9458493cec"
    }
};