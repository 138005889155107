import { postData } from './postData.js';

export async function processPendingTasks() {
    if (!navigator.onLine) {
        return;
    }

    const tasks = JSON.parse(localStorage.getItem('tasks')) || [];

    if (tasks.length > 0) {
        for (let i = 0; i < tasks.length; i++) {
            const { form, collection, documentID, data, permissions, success, error } = tasks[i];

            try {
                await postData('', form, collection, documentID, JSON.stringify(data), permissions, success, error).then().catch(e => {
                    console.error(`Ошибка при синхронизации задания: ${documentID}`, e);
                });

                /* await postData(
                    '', form, collection, documentID, data, permissions, '', error,
                    () => console.log(`Успешно обработано задание: ${id}`),
                    (err) => {
                        console.error(`Ошибка при обработке задания: ${id}`, err);
                    }
                ); */
                // Удалить задание
                tasks.splice(i, 1);
                i--;  // Сдвиг индекса назад после удаления элемента
                localStorage.setItem('tasks', JSON.stringify(tasks));
            } catch (e) {
                // Удалить задание
                tasks.splice(i, 1);
                i--;  // Сдвиг индекса назад после удаления элемента
                localStorage.setItem('tasks', JSON.stringify(tasks));
            }
        }
        // window.snackbarManager.addItem({ type: 'info', message: 'Данные успешно синхронизированы с серверром', duration: 0 });
    } else {
        return;
    }
}