{
    "id": "N4fiWD7nKUKdzW6lYExhZg",
    "colorVariables": [
        {
            "id": "gSL3ql8tzoan4wtRMQaRSA",
            "name": "Red l 10",
            "value": "FFA799"
        },
        {
            "id": "gSVZjUL1X3E8ixWIIE9dBg",
            "name": "Blue d 08",
            "value": "1E3EA9"
        },
        {
            "id": "gSIsYpNbJtLHpQo517YDAA",
            "name": "Green Variant l 10",
            "value": "6DD56D"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFysg",
            "name": "Red Variant d 12",
            "value": "A95447"
        },
        {
            "id": "gSe7rmt_Uoj0o9wINP1gWw",
            "name": "Yellow Variant",
            "value": "795B06"
        },
        {
            "id": "gSIsYpNbJtLHpQo57baNGA",
            "name": "Green Variant d 11",
            "value": "007500"
        },
        {
            "id": "gSbwKxsH0hgXGPSibO7HRA",
            "name": "Orange d 10",
            "value": "8A4C0F"
        },
        {
            "id": "gSFhGQdp9bfOBLmRi1vNNg",
            "name": "Green d 06",
            "value": "004700"
        },
        {
            "id": "gSPC9GsO-ltea9FjkSqEFg",
            "name": "Magenta l 06",
            "value": "FFD1F8"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV7A",
            "name": "Neutral Variant l 01",
            "value": "F"
        },
        {
            "id": "gSVZhkJ8LLeRiodOjg1abw",
            "name": "Secondary d 08",
            "value": "694002"
        },
        {
            "id": "gSYk2g7b3zY05y897tcQyA",
            "name": "Orange Variant l 05",
            "value": "FFDFC2"
        },
        {
            "id": "gSPDA2vjWpOWJxcHitAc_A",
            "name": "Tertiary d 02",
            "value": "171E00"
        },
        {
            "id": "gSL3ql8txoZO5mMu-AhatA",
            "name": "Magenta d 12",
            "value": "A9479B"
        },
        {
            "id": "gSbwJRqM949R-HPAZqTBeA",
            "name": "Neutral Variant l 03",
            "value": "FCF1E4"
        },
        {
            "id": "gSYk6Q_3FMiJyS7563Kn8g",
            "name": "Tertiary d 10",
            "value": "4E6600"
        },
        {
            "id": "gSL3ql8tzIaRLpz1FkA_qA",
            "name": "Magenta Variant d 01",
            "value": "24001F"
        },
        {
            "id": "gSL3ql8tzIaRLpz1IkCeTA",
            "name": "Magenta Variant d 13",
            "value": "BB58AD"
        },
        {
            "id": "gSYk2w7uv9mBylfXFTTDvw",
            "name": "Blue d 10",
            "value": "3052C5"
        },
        {
            "id": "gSPC82sAEPjNcZv4uy6Weg",
            "name": "Primary l 15",
            "value": "CA8F44"
        },
        {
            "id": "gSPC9GsPAFuqJnOBdFX1yg",
            "name": "Magenta Variant d 02",
            "value": "380030"
        },
        {
            "id": "gSL3pF7iPuZjk5rx3Mm2Bg",
            "name": "Secondary d 12",
            "value": "896734"
        },
        {
            "id": "gSL3uV_qh5VWGbrhSR9lrw",
            "name": "Neutral d 04",
            "value": "342818"
        },
        {
            "id": "gSL3qV8gZDg5ReEzbTdlMA",
            "name": "Green d 01",
            "value": "001400"
        },
        {
            "id": "gSL3ql8t0obTWNWgWiEOQg",
            "name": "Cyan Variant d 12",
            "value": "047B6B"
        },
        {
            "id": "gSL3qV8gZDg5ReEzYTcGbA",
            "name": "Green d 13",
            "value": "0A8A0A"
        },
        {
            "id": "gSYk2g7b3zY03C6QfwizPQ",
            "name": "Magenta Variant l 03",
            "value": "FFEBFC"
        },
        {
            "id": "gSbwKhrzmFtZzb8oiHsPxA",
            "name": "Blue l 08",
            "value": "BDCCFF"
        },
        {
            "id": "gSFhGwd9pghr1oBejnjAyg",
            "name": "Blue d 06",
            "value": "0E2C9A"
        },
        {
            "id": "gSYk6Q_3FMiJyS7573LhHA",
            "name": "Tertiary d 14",
            "value": "778B37"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOjw",
            "name": "Orange Variant d 01",
            "value": "1A0C00"
        },
        {
            "id": "gSYk4Q9gH682EVQggsO5tA",
            "name": "Blue Variant l 13",
            "value": "90A6FE"
        },
        {
            "id": "gSbwMxurWx6w04dxFmb2EQ",
            "name": "Red Variant l 03",
            "value": "FFEDEB"
        },
        {
            "id": "gSFhFAc7jwR0Kx2TdyVOKw",
            "name": "Secondary d 06",
            "value": "5C3100"
        },
        {
            "id": "gSYk1g6P_qKkHmtkg5WGYQ",
            "name": "Neutral Variant l 09",
            "value": "D8C5AC"
        },
        {
            "id": "gSL3q186VMtFwtqCvs_OxA",
            "name": "Green Variant d 13",
            "value": "1C891A"
        },
        {
            "id": "gSPC9GsPAFuqJnOBdVX_Aw",
            "name": "Magenta Variant d 03",
            "value": "420039"
        },
        {
            "id": "gSPC9GsPAFuqJnOBZlVxjA",
            "name": "Magenta Variant l 15",
            "value": "DD73CE"
        },
        {
            "id": "gSbwKRrfKprGpVn0lAv_lw",
            "name": "Cyan Variant l 12",
            "value": "61C2B4"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALuA",
            "name": "Neutral l 02",
            "value": "FBF6EF"
        },
        {
            "id": "gSbwLxtZLROa7tiTmaMCNA",
            "name": "Yellow Variant l 08",
            "value": "EFCD7B"
        },
        {
            "id": "gSVZkkNLNQ7aPDo6cb6D_w",
            "name": "Yellow Variant l 05",
            "value": "FFE48A"
        },
        {
            "id": "gSIsXZMjzsxFfkqcMsvIWQ",
            "name": "Neutral Variant d 06",
            "value": "49371D"
        },
        {
            "id": "gSbwKRrfHpnjuauScKybCA",
            "name": "Magenta l 12",
            "value": "FC88EB"
        },
        {
            "id": "gSYk2w7uv9mBylfXGTT86Q",
            "name": "Blue d 14",
            "value": "677EF4"
        },
        {
            "id": "gSPC9WsdG7QZPtlUaMrkPw",
            "name": "Green Variant l 06",
            "value": "82F882"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB-EQ",
            "name": "Red Variant d 15",
            "value": "D3786F"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7Mg",
            "name": "Neutral l 14",
            "value": "AAA092"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyaySA",
            "name": "Neutral Variant d 02",
            "value": "231706"
        },
        {
            "id": "gSL3ql8txoZO5mMu8Qgjrg",
            "name": "Magenta d 07",
            "value": "770366"
        },
        {
            "id": "gSIsb5PqcHeQsvizEcEqcw",
            "name": "Yellow l 10",
            "value": "E0BC67"
        },
        {
            "id": "gSIsZ5ORitBIFVQ-O89bbg",
            "name": "Yellow Variant d 11",
            "value": "7C6004"
        },
        {
            "id": "gSVZjELkEeksqh1ApPsoVg",
            "name": "Magenta Variant l 05",
            "value": "FFDBFA"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdhQ",
            "name": "Orange Variant l 15",
            "value": "C59163"
        },
        {
            "id": "gSIsYZNQMZ9TEM0xqTtBog",
            "name": "Magenta Variant d 11",
            "value": "9E3D90"
        },
        {
            "id": "gSVZiEKefcMkJDl_fRHUcw",
            "name": "Neutral Variant d 09",
            "value": "605039"
        },
        {
            "id": "gSPC9GsO-ltea9FjmyrjDw",
            "name": "Magenta d 03",
            "value": "420039"
        },
        {
            "id": "gSVZi0LR_FTPs_-_fmhVgg",
            "name": "Cyan l 09",
            "value": "68D9CA"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6Tbg",
            "name": "Error d 07",
            "value": "850F00"
        },
        {
            "id": "gSPDAmvVVTw84a_2ziqvwA",
            "name": "Yellow l 06",
            "value": "FFDC7A"
        },
        {
            "id": "gSYk2Q7INoVf7YK31gKRSg",
            "name": "Cyan l 13",
            "value": "46B9AA"
        },
        {
            "id": "gSYk2g7b5Tac0GEYnjNpvQ",
            "name": "Cyan Variant l 03",
            "value": "B3FFF5"
        },
        {
            "id": "gSL3uV_qiJVhI-8cCGeJHQ",
            "name": "Tertiary l 02",
            "value": "EBFFA3"
        },
        {
            "id": "gSL3ql8tzIaRLpz1IUCWsw",
            "name": "Magenta Variant d 12",
            "value": "AD489E"
        },
        {
            "id": "gSYk4A9MdP4-wpvHP2ho4w",
            "name": "Yellow Variant l 11",
            "value": "D5B66D"
        },
        {
            "id": "gSPC82sAEPjNcZv4qS3tNA",
            "name": "Primary d 02",
            "value": "291700"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFyrA",
            "name": "Red Variant l 04",
            "value": "FFE8E5"
        },
        {
            "id": "gSYk2w7uv9mBylfW_TNwbw",
            "name": "Blue l 13",
            "value": "8AA1FF"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFymg",
            "name": "Red Variant l 01",
            "value": "F"
        },
        {
            "id": "gSbwOBwR9-pslzv4RGktDw",
            "name": "Neutral d 14",
            "value": "8A8175"
        },
        {
            "id": "gSL3pF7iPuZjk5rx5cn8cQ",
            "name": "Secondary d 07",
            "value": "613700"
        },
        {
            "id": "gSbwKRrfHpnjuauSgq210w",
            "name": "Magenta d 05",
            "value": "605"
        },
        {
            "id": "gSbwKBrJ6MqNwXi6ucnmnw",
            "name": "Cyan l 12",
            "value": "53C1B2"
        },
        {
            "id": "gSL3q186VMtFwtqCvc_GIw",
            "name": "Green Variant d 12",
            "value": "0A7F0A"
        },
        {
            "id": "gSPC9WsdG7QZPtlUX8qOeQ",
            "name": "Green Variant l 15",
            "value": "4BAF4B"
        },
        {
            "id": "gSYk2Q7INoVf7YK3zgIfMA",
            "name": "Cyan d 10",
            "value": "006B5D"
        },
        {
            "id": "gSVZi0LR_FTPs_-_gmiILw",
            "name": "Cyan l 05",
            "value": "7DFCEC"
        },
        {
            "id": "gSYk2g7b3zY05y897tcQ1A",
            "name": "Orange Variant l 09",
            "value": "FBBB84"
        },
        {
            "id": "gSbwOBwR9-pslzv4RGks8w",
            "name": "Neutral l 13",
            "value": "AFA597"
        },
        {
            "id": "gSL3qV8gcDi8yErJaXNgMQ",
            "name": "Primary l 01",
            "value": "F"
        },
        {
            "id": "gSL3q186VMtFwtqCpM8CEg",
            "name": "Green Variant l 02",
            "value": "DCFFDB"
        },
        {
            "id": "gSbwJRqM949R-HPAZqTBfg",
            "name": "Neutral Variant d 14",
            "value": "8F8270"
        },
        {
            "id": "gSFhGQdqAbgrvd5UvJXfDA",
            "name": "Primary d 06",
            "value": "5C3100"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhaJk6xQ",
            "name": "Blue d 03",
            "value": "001270"
        },
        {
            "id": "gSL3tF-rSDddfyghh9Sp4w",
            "name": "Red Variant d 11",
            "value": "9E4C3D"
        },
        {
            "id": "gSYk2g7b5Tac0GEYlDLcjw",
            "name": "Cyan Variant d 10",
            "value": "006B5C"
        },
        {
            "id": "gSVZjELkF-mLK7sCrMzDSA",
            "name": "Cyan Variant l 05",
            "value": "84FBEA"
        },
        {
            "id": "gSPC7mq6AESMmEmzTbUxhw",
            "name": "Secondary l 15",
            "value": "B9956A"
        },
        {
            "id": "gSbwKRrfJpp7sdZsGXaWMg",
            "name": "Red d 14",
            "value": "CD6B60"
        },
        {
            "id": "gSYk1A5qh2EL74FrvQ-Qgg",
            "name": "Secondary l 11",
            "value": "DCB27F"
        },
        {
            "id": "gSVZjELkF-mLK7sCuM1arw",
            "name": "Cyan Variant l 09",
            "value": "71DACC"
        },
        {
            "id": "gSYk1g6P_qKkHmtkg5WGVw",
            "name": "Neutral Variant d 08",
            "value": "58462D"
        },
        {
            "id": "gSL3qV8gcDi8yErJXHL5zg",
            "name": "Primary d 01",
            "value": "1A0E00"
        },
        {
            "id": "gSL3qV8gcDi8yErJV3LSMA",
            "name": "Primary l 04",
            "value": "FFE9C7"
        },
        {
            "id": "gSe7e2cbraVm1OD-tgp4ow",
            "name": "Orange l 08",
            "value": "FFC285"
        },
        {
            "id": "gSVZk0NdTKNWbDMUJlqQDQ",
            "name": "Blue Variant l 05",
            "value": "E0E6FF"
        },
        {
            "id": "gSVZjkMGcvW7z9D4ZxX-7g",
            "name": "Orange d 09",
            "value": "804200"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB9-w",
            "name": "Red Variant l 14",
            "value": "ED877E"
        },
        {
            "id": "gSbwKBrJ3Mmrx9q35UjyBQ",
            "name": "Green l 12",
            "value": "59C959"
        },
        {
            "id": "gSYk2Q7INoVf74LXDN29wg",
            "name": "Primary l 11",
            "value": "ECAD65"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhgw",
            "name": "Red l 04",
            "value": "FFE9E5"
        },
        {
            "id": "gSL3sF94mB5lmzW3122SaQ",
            "name": "Yellow Variant l 02",
            "value": "FFF6D6"
        },
        {
            "id": "gSL3uF_eFlGfvzF8zkMHjw",
            "name": "Yellow l 01",
            "value": "FFFEFA"
        },
        {
            "id": "gSL3rF9Guw6CnSTOb2O-3w",
            "name": "Orange d 04",
            "value": "422000"
        },
        {
            "id": "gSYk6Q_3FMiJyS755XJS6g",
            "name": "Tertiary l 03",
            "value": "E5FD96"
        },
        {
            "id": "gSIsYJNEaGQMcsswspWvbA",
            "name": "Primary l 10",
            "value": "F2B569"
        },
        {
            "id": "gSIsYZNQMZ9TEM0xpjsvBw",
            "name": "Magenta Variant d 04",
            "value": "520046"
        },
        {
            "id": "gSYk2w7us9iywQB-uhN7AQ",
            "name": "Green Variant d 10",
            "value": "007000"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB-DQ",
            "name": "Red Variant d 03",
            "value": "470800"
        },
        {
            "id": "gSSOPrblQmnoKUdiirbCNg",
            "name": "Primary d 09",
            "value": "804700"
        },
        {
            "id": "gSIsYZNQN5-LyKyTaXS4rA",
            "name": "Cyan Variant l 10",
            "value": "6CD0C3"
        },
        {
            "id": "gSL3qV8gcDi8yErJdXO-3g",
            "name": "Primary l 07",
            "value": "FFCD80"
        },
        {
            "id": "gSL3sV-F9mxPyu2Uw-g-Gw",
            "name": "Blue Variant d 12",
            "value": "5C6FBC"
        },
        {
            "id": "gSPC7mq6AESMmEmzP7StSQ",
            "name": "Secondary d 02",
            "value": "291700"
        },
        {
            "id": "gSL3qV8gcDi8yErJYXMhfA",
            "name": "Primary d 12",
            "value": "986210"
        },
        {
            "id": "gSYk5A-YVpHgi-L04B95Sw",
            "name": "Red Variant d 08",
            "value": "862719"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6Tew",
            "name": "Error l 01",
            "value": "F"
        },
        {
            "id": "gSL3uV_qiJVhI-8b9mb8Eg",
            "name": "Tertiary d 01",
            "value": "0C0F00"
        },
        {
            "id": "gSYk3A8BZnjiyKegjQ0JKA",
            "name": "Orange l 05",
            "value": "FFDFC2"
        },
        {
            "id": "gSbwIxpkWhK_DUc1tMsgAQ",
            "name": "Secondary d 05",
            "value": "4D2B00"
        },
        {
            "id": "gSL3sV-F9mxPyu2UzuiUHg",
            "name": "Blue Variant d 07",
            "value": "213887"
        },
        {
            "id": "gSL3uF_eFlGfvzF80kMnhA",
            "name": "Yellow l 07",
            "value": "F9D276"
        },
        {
            "id": "gSL3qV8gcDi8xkq2uCqYyg",
            "name": "Cyan d 01",
            "value": "001412"
        },
        {
            "id": "gSL3q186YMvJOEOd1CezDg",
            "name": "Blue d 01",
            "value": "000B38"
        },
        {
            "id": "gSVZjELkC-jOCn3WDruUfA",
            "name": "Magenta d 08",
            "value": "841574"
        },
        {
            "id": "gSYk4A9MdP4-wpvHNWfarg",
            "name": "Yellow Variant d 14",
            "value": "988034"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4PA",
            "name": "Orange d 01",
            "value": "1A0C00"
        },
        {
            "id": "gSL3q186YMvJOEOd4SgZbQ",
            "name": "Blue d 12",
            "value": "5069DC"
        },
        {
            "id": "gSYk2g7b3zY05y897tcQ1g",
            "name": "Orange Variant d 08",
            "value": "753900"
        },
        {
            "id": "gSbwKRrfJJpVPoPHC1UfTA",
            "name": "Magenta Variant l 08",
            "value": "FFB8F5"
        },
        {
            "id": "gSbwIxpkWhK_DUc1xsw64Q",
            "name": "Secondary l 08",
            "value": "F8C891"
        },
        {
            "id": "gSL3sF94mB5lmzW31W2DGQ",
            "name": "Yellow Variant d 13",
            "value": "8F7324"
        },
        {
            "id": "gSL3ql8t0obTWNWgTyC3XA",
            "name": "Cyan Variant l 02",
            "value": "D1FFF9"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4QA",
            "name": "Orange d 13",
            "value": "A36B33"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOdg",
            "name": "Orange Variant l 07",
            "value": "FFCB94"
        },
        {
            "id": "gSL3qV8gcDi8xkq2virIIw",
            "name": "Cyan d 07",
            "value": "00473F"
        },
        {
            "id": "gSPC9GsO-ltea9FjiSo4lQ",
            "name": "Magenta l 14",
            "value": "EA7BDA"
        },
        {
            "id": "gSYk6Q_3E8h4cqZbyIawSw",
            "name": "Neutral l 09",
            "value": "D3C6B6"
        },
        {
            "id": "gSbwKRrfJJpVSYSF3jWP2Q",
            "name": "Orange Variant d 14",
            "value": "B07B4A"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxNQ",
            "name": "Orange d 02",
            "value": "2E1500"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxIg",
            "name": "Orange l 14",
            "value": "D49663"
        },
        {
            "id": "gSSORbdTdXZ4UrrQKKgwLw",
            "name": "Yellow Variant d 09",
            "value": "705100"
        },
        {
            "id": "gSbwKRrfJpp7sdZsGXaWHA",
            "name": "Red l 11",
            "value": "FF9C8A"
        },
        {
            "id": "gSPC9WsdG7QZPtlUfsuz3Q",
            "name": "Green Variant d 15",
            "value": "40A040"
        },
        {
            "id": "gSe7g2fLz61xRNfOFJ38VA",
            "name": "Red Variant d 05",
            "value": "6B0C00"
        },
        {
            "id": "gSL3qV8gZDg5ReEzejfLdA",
            "name": "Green l 01",
            "value": "F"
        },
        {
            "id": "gSL3q186YMvJOEOdySdcRw",
            "name": "Blue l 01",
            "value": "F"
        },
        {
            "id": "gSSOQLcFc1Lr210SaOt1Qw",
            "name": "Green Variant d 09",
            "value": "006100"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOiw",
            "name": "Orange Variant d 13",
            "value": "9C6D3A"
        },
        {
            "id": "gSIsW5MOFmc2TyBzY7TEoA",
            "name": "Secondary l 10",
            "value": "E6B884"
        },
        {
            "id": "gSYk2Q7INoVf7YK3yAHKKA",
            "name": "Cyan l 03",
            "value": "B3FFF5"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rLf4D7A",
            "name": "Yellow Variant l 15",
            "value": "AD9648"
        },
        {
            "id": "gSYk2w7uv9mBylfW_zOMhQ",
            "name": "Blue l 11",
            "value": "9EB3FF"
        },
        {
            "id": "gSVZjUL1U3CAZuZcqW6A7Q",
            "name": "Green Variant l 09",
            "value": "73DE73"
        },
        {
            "id": "gSYk1g6P_qKkHmtkg5WGZQ",
            "name": "Neutral Variant l 05",
            "value": "F5E5D1"
        },
        {
            "id": "gSL3q186YMvJOEOdxidE9g",
            "name": "Blue l 02",
            "value": "F5F7FF"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhjQ",
            "name": "Red d 12",
            "value": "B65143"
        },
        {
            "id": "gSL3qV8gZDg5ReEzdjervw",
            "name": "Green l 07",
            "value": "73F273"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7RA",
            "name": "Neutral d 03",
            "value": "281F10"
        },
        {
            "id": "gSL3ql8tzIaROZ1dQds_3Q",
            "name": "Orange Variant d 11",
            "value": "8C5921"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdfg",
            "name": "Orange Variant l 06",
            "value": "FFD5AD"
        },
        {
            "id": "gSIsYpNbMNMlpnplT7reAA",
            "name": "Error d 06",
            "value": "7A0D00"
        },
        {
            "id": "gSYk1A5qh2EL74Frow4gGg",
            "name": "Secondary d 10",
            "value": "7B5419"
        },
        {
            "id": "gSL3pF7iPuZjk5rx6MoUhw",
            "name": "Secondary l 07",
            "value": "FFCF99"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyayRw",
            "name": "Neutral Variant d 03",
            "value": "2E1F0A"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyayWQ",
            "name": "Neutral Variant l 14",
            "value": "AC9E8B"
        },
        {
            "id": "gSbwKRrfJJpVPoPHB1Tfxw",
            "name": "Magenta Variant l 12",
            "value": "FC88EB"
        },
        {
            "id": "gSYk2Q7INoVf74LXDt3Z2Q",
            "name": "Primary l 13",
            "value": "DA9E58"
        },
        {
            "id": "gSYk2g7b3zY03C6QgQjPTw",
            "name": "Magenta Variant d 10",
            "value": "973589"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIgg",
            "name": "Error l 14",
            "value": "FF7561"
        },
        {
            "id": "gSPC-2tyL8wyt28xRenyvA",
            "name": "Blue Variant d 02",
            "value": "000F5C"
        },
        {
            "id": "gSL3sV-F9mxPyu2U4ukxpw",
            "name": "Blue Variant l 02",
            "value": "F5F7FF"
        },
        {
            "id": "gSiG7vZh7YXxYcVq_s40GQ",
            "name": "Neutral Variant",
            "value": "79572A"
        },
        {
            "id": "gSbwKhrzlls0WX3DvyE4jA",
            "name": "Error l 11",
            "value": "FF9B8F"
        },
        {
            "id": "gSL3qV8gcDi8xkq2yisl1Q",
            "name": "Cyan l 02",
            "value": "D1FFF9"
        },
        {
            "id": "gSYk3A8BZnjiyKegjQ0JHA",
            "name": "Orange l 09",
            "value": "FFB980"
        },
        {
            "id": "gSFhKAf4a-t-z0AS4OPdyg",
            "name": "Yellow d 06",
            "value": "4D3800"
        },
        {
            "id": "gSL3ql8tzIaRLpz1GEBPJA",
            "name": "Magenta Variant d 07",
            "value": "770367"
        },
        {
            "id": "gSL3q186YMvJOEOdzSd7-w",
            "name": "Blue l 07",
            "value": "C7D3FF"
        },
        {
            "id": "gSFhGwd9mggOKlvtOf_8Dw",
            "name": "Green Variant d 06",
            "value": "004700"
        },
        {
            "id": "gSL3uF_eFlGfvzF84UOdTw",
            "name": "Yellow d 07",
            "value": "523C00"
        },
        {
            "id": "gSYk2g7b3zY03C6QhQkHeQ",
            "name": "Magenta Variant d 14",
            "value": "C464B6"
        },
        {
            "id": "gSYk2w7us9iywQB-vhO0Mg",
            "name": "Green Variant d 14",
            "value": "329532"
        },
        {
            "id": "gSYk2g7b4TZYKUmjGorTVg",
            "name": "Red l 05",
            "value": "FFE0DB"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIcw",
            "name": "Error d 02",
            "value": "3D0600"
        },
        {
            "id": "gSPC82sAEPjNb5vi78jTfA",
            "name": "Cyan l 14",
            "value": "41AFA1"
        },
        {
            "id": "gSPC82sAEPjNb5vi4shYuw",
            "name": "Cyan d 02",
            "value": "001F1B"
        },
        {
            "id": "gSL3qV8gcDi8xkq2zSs-Bg",
            "name": "Cyan l 01",
            "value": "FAFFFE"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tX1A",
            "name": "Red d 03",
            "value": "470900"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4NQ",
            "name": "Orange l 07",
            "value": "FFCB94"
        },
        {
            "id": "gSIsW5MOFmc2TyBzVLRmaw",
            "name": "Secondary d 04",
            "value": "3D2300"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tXzg",
            "name": "Red l 06",
            "value": "FFD3CC"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4Og",
            "name": "Orange d 07",
            "value": "6B3000"
        },
        {
            "id": "gSYk2w7us9iywQB-tBMl-A",
            "name": "Green Variant l 03",
            "value": "C7FFC7"
        },
        {
            "id": "gSVZhkJ8LLeRiodOrA7UtQ",
            "name": "Secondary l 09",
            "value": "EFC08A"
        },
        {
            "id": "gSIsYZNQK58aOuzkLRzn-w",
            "name": "Magenta d 04",
            "value": "520046"
        },
        {
            "id": "gSL3q186YMvJOEOd2yfqKg",
            "name": "Blue l 04",
            "value": "E6EBFF"
        },
        {
            "id": "gSL3pl77HW2kCdwCAj-sUg",
            "name": "Neutral Variant d 04",
            "value": "35250D"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhaZlD7g",
            "name": "Blue d 02",
            "value": "000F5C"
        },
        {
            "id": "gSVZmkPWfVziSec8kapf3Q",
            "name": "Yellow d 08",
            "value": "614700"
        },
        {
            "id": "gSVZmkPWfVziSec8g6mvtw",
            "name": "Yellow l 05",
            "value": "FFE385"
        },
        {
            "id": "gSL3sF94mB5lmzW3yW0kVA",
            "name": "Yellow Variant d 01",
            "value": "140F00"
        },
        {
            "id": "gSIsYpNbMtM4NcIhJ40jJQ",
            "name": "Blue l 10",
            "value": "A8BBFF"
        },
        {
            "id": "gSPC9GsPBlv1wxRVJWwBUw",
            "name": "Cyan Variant l 14",
            "value": "4BAFA1"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dheQ",
            "name": "Red l 07",
            "value": "FFC9C2"
        },
        {
            "id": "gSYk2Q7INoVf7YK30gJYWQ",
            "name": "Cyan d 14",
            "value": "1C9282"
        },
        {
            "id": "gSSOPrblNmk-3SIBwSWEdQ",
            "name": "Green d 09",
            "value": "006100"
        },
        {
            "id": "gSbwKRrfJpp7sdZsGXaWLg",
            "name": "Red d 10",
            "value": "9D3C2F"
        },
        {
            "id": "gSIsgpS7GU2HnE6NPpFhYQ",
            "name": "Magenta",
            "value": "9D218B"
        },
        {
            "id": "gSPC9GsO-ltea9FjiipB8Q",
            "name": "Magenta l 15",
            "value": "DD73CE"
        },
        {
            "id": "gSL3qV8gcDi8xkq2xCr3cA",
            "name": "Cyan d 13",
            "value": "098676"
        },
        {
            "id": "gSe7e2cbraVm1OD-tgp4pw",
            "name": "Orange l 12",
            "value": "E4A46C"
        },
        {
            "id": "gSL3rF9Guw6CnSTOb2O-7A",
            "name": "Orange d 11",
            "value": "915618"
        },
        {
            "id": "gSIsYJNEaGQMcMshLWWFHg",
            "name": "Cyan d 04",
            "value": "002E28"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhhA",
            "name": "Red d 07",
            "value": "7E1201"
        },
        {
            "id": "gSYk2Q7IKoSQ2Sqx8BFrkQ",
            "name": "Green d 10",
            "value": "007000"
        },
        {
            "id": "gSVZi0LR_FTPs_-_cGelIA",
            "name": "Cyan d 08",
            "value": "00524A"
        },
        {
            "id": "gSPDA2vjWZOJidFrylAL0w",
            "name": "Neutral d 12",
            "value": "766D60"
        },
        {
            "id": "gSL3uF_eFlGfvzF850PMlw",
            "name": "Yellow d 13",
            "value": "917412"
        },
        {
            "id": "gSIscJP1TqorQ9jFeuCFQQ",
            "name": "Neutral d 06",
            "value": "453827"
        },
        {
            "id": "gSL3q186YMvJOEOd4CgQ1A",
            "name": "Blue d 13",
            "value": "5B74E6"
        },
        {
            "id": "gSL3ql8t0obTWNWgUiDPjg",
            "name": "Cyan Variant l 01",
            "value": "FAFFFE"
        },
        {
            "id": "gSYk2g7b3zY03C6QaQd6vw",
            "name": "Magenta Variant l 13",
            "value": "F381E3"
        },
        {
            "id": "gSVZi0LR_FTPtf_blRefTA",
            "name": "Primary l 05",
            "value": "FFE1B3"
        },
        {
            "id": "gSPC82sABPg3CmNcYLrV4w",
            "name": "Green d 15",
            "value": "30A130"
        },
        {
            "id": "gSe7iGg6TBq13UTqV9EApA",
            "name": "Neutral l 12",
            "value": "BAAFA0"
        },
        {
            "id": "gSYk2g7b2TXMyfowlNOpig",
            "name": "Magenta d 10",
            "value": "973589"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIgA",
            "name": "Error d 15",
            "value": "FE5843"
        },
        {
            "id": "gSPC-2tyL8wyt28xVOqANg",
            "name": "Blue Variant l 14",
            "value": "8B9DF9"
        },
        {
            "id": "gSL3qV8gcDi8yErJanNoAA",
            "name": "Primary l 02",
            "value": "FFF5E5"
        },
        {
            "id": "gSVZjUL1U3CAZuZcu29j2A",
            "name": "Green Variant d 08",
            "value": "005700"
        },
        {
            "id": "gSL3ql8t0obTWNWgWSEGqQ",
            "name": "Cyan Variant d 13",
            "value": "148576"
        },
        {
            "id": "gSYk-hE3spt0WTAYKa3jFw",
            "name": "Blue Variant",
            "value": "455AB0"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6TdA",
            "name": "Error d 13",
            "value": "F11E04"
        },
        {
            "id": "gSL3sF94mB5lmzW33G259A",
            "name": "Yellow Variant l 01",
            "value": "FFFEFA"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6Tfw",
            "name": "Error l 07",
            "value": "FFC9C2"
        },
        {
            "id": "gSSOQLcFf1OVGoHPZPXUiA",
            "name": "Blue d 09",
            "value": "2A4AB7"
        },
        {
            "id": "gSL3ql8tzoan4wtRMQaRWg",
            "name": "Red d 11",
            "value": "A44637"
        },
        {
            "id": "gSIsYJNEXGOb1RLi9nvM3g",
            "name": "Green d 11",
            "value": "007A00"
        },
        {
            "id": "gSL3sF94mB5lmzW3ym0sFQ",
            "name": "Yellow Variant l 04",
            "value": "FFEBA8"
        },
        {
            "id": "gSIshZTcqu51L9KJtpcHew",
            "name": "Neutral",
            "value": "76572E"
        },
        {
            "id": "gSVZjUL1X3E8ixWIDk553w",
            "name": "Blue l 09",
            "value": "B3C4FF"
        },
        {
            "id": "gSFhGgd0PeOOW3N5haziGg",
            "name": "Cyan Variant d 06",
            "value": "004239"
        },
        {
            "id": "gSYk2g7b5Tac0GEYmDMUuQ",
            "name": "Cyan Variant d 14",
            "value": "2B9183"
        },
        {
            "id": "gSL3ql8txoZO5mMu6wf0VQ",
            "name": "Magenta d 01",
            "value": "24001F"
        },
        {
            "id": "gSIsYZNQMZ9TG82IfU1ipw",
            "name": "Orange Variant d 06",
            "value": "612F00"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhdJmsRQ",
            "name": "Blue d 15",
            "value": "7188FE"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tX4A",
            "name": "Red d 15",
            "value": "D97468"
        },
        {
            "id": "gSL3ql8txoZO5mMu5Ae9Qg",
            "name": "Magenta l 07",
            "value": "FFC2F6"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV5A",
            "name": "Neutral Variant d 12",
            "value": "7B6D5B"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV2Q",
            "name": "Neutral Variant d 07",
            "value": "4E3B22"
        },
        {
            "id": "gSbwOBwR9-pslzv4RGks9Q",
            "name": "Neutral l 11",
            "value": "C0B5A5"
        },
        {
            "id": "gSL3qV8gcDi8xkq2xSr_SQ",
            "name": "Cyan d 12",
            "value": "00806F"
        },
        {
            "id": "gSL3pF7iPuZjk5rx38nNGA",
            "name": "Secondary d 01",
            "value": "1A0E00"
        },
        {
            "id": "gSL3pl77HW2kCdwCAj-sYQ",
            "name": "Neutral Variant l 10",
            "value": "CFBDA5"
        },
        {
            "id": "gSYk6Q_3E8h4cqZbyIawTw",
            "name": "Neutral l 05",
            "value": "EFE5D7"
        },
        {
            "id": "gSVZkkNLNQ7aPDo6Y73TyQ",
            "name": "Yellow Variant d 08",
            "value": "614800"
        },
        {
            "id": "gSbwKBrJ6MqNw3jdGNZWrw",
            "name": "Primary l 12",
            "value": "E3A65F"
        },
        {
            "id": "gSYk6A_kVieJox927q8Odg",
            "name": "Yellow d 14",
            "value": "9B7F22"
        },
        {
            "id": "gSPC9GsPAFuqJnOBb1XGUQ",
            "name": "Magenta Variant l 06",
            "value": "FFD1F8"
        },
        {
            "id": "gSL3ql8t0obTWNWgYyFUzQ",
            "name": "Cyan Variant d 07",
            "value": "00473F"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhcA",
            "name": "Red l 02",
            "value": "FFF6F5"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOlA",
            "name": "Orange Variant l 04",
            "value": "FFE8D1"
        },
        {
            "id": "gSVZjELkC-jOCn3WALrkIg",
            "name": "Magenta l 05",
            "value": "FFDBFA"
        },
        {
            "id": "gSIscJP1T6o0uv4ZomTvzQ",
            "name": "Tertiary d 11",
            "value": "536C04"
        },
        {
            "id": "gSbwKRrfJpp7sdZsGXaWLA",
            "name": "Red l 03",
            "value": "FFEDEB"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOgg",
            "name": "Orange Variant l 01",
            "value": "F"
        },
        {
            "id": "gSVZjELkF-mLK7sCus1zcQ",
            "name": "Cyan Variant d 08",
            "value": "01554A"
        },
        {
            "id": "gSe7dWaXYFs7rxHKhNyjDA",
            "name": "Neutral Variant d 05",
            "value": "412F16"
        },
        {
            "id": "gSL3ql8tzIaRLpz1CT_ZRQ",
            "name": "Magenta Variant l 01",
            "value": "F"
        },
        {
            "id": "gSVZjELkE-lM0QW2Do06gQ",
            "name": "Red d 09",
            "value": "923220"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALuw",
            "name": "Neutral l 01",
            "value": "F"
        },
        {
            "id": "gSe7dWaXYFs7rxHKhNyjGg",
            "name": "Neutral Variant l 08",
            "value": "DFCDB3"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB-Aw",
            "name": "Red Variant l 06",
            "value": "FFD3CC"
        },
        {
            "id": "gSIsaJOdVguhVp6ZcDzxYA",
            "name": "Blue Variant d 04",
            "value": "041D72"
        },
        {
            "id": "gSFhTwloEY9tKNRmvgN9uQ",
            "name": "Yellow",
            "value": "7C5B00"
        },
        {
            "id": "gSPC82sABPg3CmNcbLtHcg",
            "name": "Green d 03",
            "value": "002900"
        },
        {
            "id": "gSIsaJOdVguhVp6ZgT1b7Q",
            "name": "Blue Variant l 10",
            "value": "A8BBFF"
        },
        {
            "id": "gSe7eWbv2-fFdwclDgLCLQ",
            "name": "Red l 12",
            "value": "FF9385"
        },
        {
            "id": "gSbwKBrJ6MqNwXi6q8kKPQ",
            "name": "Cyan d 05",
            "value": "013C33"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdlw",
            "name": "Orange Variant d 02",
            "value": "2E1500"
        },
        {
            "id": "gSYk4A9MdP4-wpvHMWeixA",
            "name": "Yellow Variant d 10",
            "value": "755900"
        },
        {
            "id": "gSbwRB0Hzv55VflYWVkJLg",
            "name": "Green Variant",
            "value": "057605"
        },
        {
            "id": "gSFhGgd0MeMvskjOhn_XoA",
            "name": "Magenta d 06",
            "value": "700060"
        },
        {
            "id": "gSIsYZNQM59mnxzEtrzlqg",
            "name": "Red d 06",
            "value": "7A0F00"
        },
        {
            "id": "gSYk3A8BZnjiyKegjQ0JNg",
            "name": "Orange d 08",
            "value": "7A3900"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFysQ",
            "name": "Red Variant d 13",
            "value": "BC645C"
        },
        {
            "id": "gSYk2Q7INoVf74LW_NzakQ",
            "name": "Primary l 03",
            "value": "FFEFD6"
        },
        {
            "id": "gSIsYZNQN5-LyKyTenUjOQ",
            "name": "Cyan Variant d 04",
            "value": "002E28"
        },
        {
            "id": "gSYk2g7b4TZYKUmjGorTUg",
            "name": "Red l 09",
            "value": "FFB3A8"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFynw",
            "name": "Red Variant l 02",
            "value": "FFF6F5"
        },
        {
            "id": "gSIsYZNQK58aOuzkHBx9bg",
            "name": "Magenta l 10",
            "value": "FF9EF1"
        },
        {
            "id": "gSFhIQe2VueWiAyhRlsb7w",
            "name": "Blue Variant d 06",
            "value": "1B3383"
        },
        {
            "id": "gSL3ql8txoZO5mMu8ggrcQ",
            "name": "Magenta l 04",
            "value": "FFE5FB"
        },
        {
            "id": "gSSORrdj-fDz9eMIAPQgGA",
            "name": "Blue Variant d 09",
            "value": "384D99"
        },
        {
            "id": "gSL3pF7iPuZjk5rx9MpyNA",
            "name": "Secondary l 01",
            "value": "F"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhXpjbzA",
            "name": "Blue l 06",
            "value": "D1DCFF"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV1w",
            "name": "Neutral Variant d 01",
            "value": "170E02"
        },
        {
            "id": "gSL3uF_eFlGfvzF8zUL_5A",
            "name": "Yellow l 02",
            "value": "FFF6D6"
        },
        {
            "id": "gSPDAmvVVTw84a_22CsOqg",
            "name": "Yellow d 03",
            "value": "2E2000"
        },
        {
            "id": "gSIsb5PqcHeQsvizJ8G1iw",
            "name": "Yellow d 11",
            "value": "856100"
        },
        {
            "id": "gSbwIxpkWhK_DUc1wsv8cw",
            "name": "Secondary l 12",
            "value": "D2AC79"
        },
        {
            "id": "gSPC-2tyL8wyt28xRun8JQ",
            "name": "Blue Variant d 03",
            "value": "00156B"
        },
        {
            "id": "gSbwOBwR-Op_gN_hmWWnWA",
            "name": "Tertiary d 05",
            "value": "2B3800"
        },
        {
            "id": "gSbwKBrJ6MqNw3jdBtU64w",
            "name": "Primary d 05",
            "value": "4D2C00"
        },
        {
            "id": "gSPC7mq6AESMmEmzTLUoKg",
            "name": "Secondary l 14",
            "value": "C29D70"
        },
        {
            "id": "gSYk2w7uvdlffExzEsgR7w",
            "name": "Error d 08",
            "value": "910"
        },
        {
            "id": "gSL3uV_qiJVhI-8cD2fAQw",
            "name": "Tertiary l 07",
            "value": "C9DF81"
        },
        {
            "id": "gSL3uF_eFlGfvzF84EOVjA",
            "name": "Yellow l 04",
            "value": "FFEBA8"
        },
        {
            "id": "gSIsYpNbMtM4NcIhPY2taA",
            "name": "Blue d 11",
            "value": "415DD2"
        },
        {
            "id": "gSbwNxv9pyv9Q10injWfDQ",
            "name": "Yellow l 12",
            "value": "D1AE5C"
        },
        {
            "id": "gSL3qV8gcDi8yErJVnLKdQ",
            "name": "Primary d 07",
            "value": "613700"
        },
        {
            "id": "gSVZm0PnqOLa7BBYS7eMpA",
            "name": "Tertiary d 08",
            "value": "3E5200"
        },
        {
            "id": "gSbwKRrfJpp7sdZsGXaWFg",
            "name": "Red l 13",
            "value": "FB897E"
        },
        {
            "id": "gSbwKhrzjFp33yHketnOiQ",
            "name": "Green Variant l 08",
            "value": "78E878"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rKP3UXw",
            "name": "Yellow Variant d 15",
            "value": "A78D44"
        },
        {
            "id": "gSSOObaXUkdbBDMG8Llg3Q",
            "name": "Secondary d 09",
            "value": "734A0D"
        },
        {
            "id": "gSL31GE-heYwTQpzhnTVGQ",
            "name": "Tertiary",
            "value": "556D08"
        },
        {
            "id": "gSIsYJNEaGQMcsswrJWJcg",
            "name": "Primary d 11",
            "value": "8F5904"
        },
        {
            "id": "gSe7emcF3siqCxEsoBxeXw",
            "name": "Error d 05",
            "value": "6B0C00"
        },
        {
            "id": "gSL3pF7iPuZjk5rx5soENA",
            "name": "Secondary l 04",
            "value": "FFE9C7"
        },
        {
            "id": "gSPC9GsPBlv1wxRVOGy01Q",
            "name": "Cyan Variant d 02",
            "value": "001F1A"
        },
        {
            "id": "gSIsa5O97qNe0_nZ1YK-uw",
            "name": "Red Variant d 06",
            "value": "771104"
        },
        {
            "id": "gSYk2w7us9iywQB-pBJDhw",
            "name": "Green Variant l 11",
            "value": "6ACD6A"
        },
        {
            "id": "gSbwJRqM949R-HPAZqTBiA",
            "name": "Neutral Variant l 11",
            "value": "CAB7A0"
        },
        {
            "id": "gSIsYZNQK58aOuzkMh0HsQ",
            "name": "Magenta d 11",
            "value": "9E3D90"
        },
        {
            "id": "gSIsW5MOFmc2TyBzSbQgsA",
            "name": "Secondary d 11",
            "value": "825C27"
        },
        {
            "id": "gSPDAmvVVTw84a_2xipkXw",
            "name": "Yellow l 14",
            "value": "BE9D41"
        },
        {
            "id": "gSIsaJOdVguhVp6ZbzzrGg",
            "name": "Blue Variant d 11",
            "value": "4D61B3"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALzA",
            "name": "Neutral d 07",
            "value": "493D2C"
        },
        {
            "id": "gSYk4Q9gH682EVQgasJmJA",
            "name": "Blue Variant d 10",
            "value": "4357A3"
        },
        {
            "id": "gSL3sF94mB5lmzW3y20z0Q",
            "name": "Yellow Variant d 07",
            "value": "523C00"
        },
        {
            "id": "gSL3qV8gZDg5ReEzaDc9kw",
            "name": "Green l 04",
            "value": "ADFFAD"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7Mw",
            "name": "Neutral l 15",
            "value": "A49A8E"
        },
        {
            "id": "gSYk2w7us9iywQB-ohIncA",
            "name": "Green Variant l 13",
            "value": "5CBC5C"
        },
        {
            "id": "gSPDAmvVVTw84a_2xSpa-g",
            "name": "Yellow l 15",
            "value": "B0953B"
        },
        {
            "id": "gSVZm0PnqOLa7BBYXbhvsw",
            "name": "Tertiary l 05",
            "value": "DAF28C"
        },
        {
            "id": "gSIsYJNEaGQMcMshPGXkUw",
            "name": "Cyan l 10",
            "value": "64D3C3"
        },
        {
            "id": "gSYk2g7b5Tac0GEYjjKHiw",
            "name": "Cyan Variant l 11",
            "value": "68CABD"
        },
        {
            "id": "gSYk5A-YVpHgi-L04B95PQ",
            "name": "Red Variant l 05",
            "value": "FFDFDB"
        },
        {
            "id": "gSVZk0NdTKNWbDMUKlrC0g",
            "name": "Blue Variant l 09",
            "value": "B3C4FF"
        },
        {
            "id": "gSL3qV8gZDg5ReEzYjcOTQ",
            "name": "Green d 12",
            "value": "008000"
        },
        {
            "id": "gSYk6A_kVieJox925K6BPA",
            "name": "Yellow l 03",
            "value": "FFF1C2"
        },
        {
            "id": "gSYk2g7b2TXMyfowfNJWOg",
            "name": "Magenta l 13",
            "value": "F381E3"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rLv4NMA",
            "name": "Yellow Variant l 14",
            "value": "BBA158"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6TbQ",
            "name": "Error l 04",
            "value": "FFE8E5"
        },
        {
            "id": "gSL3sV-F9mxPyu2UzeiMew",
            "name": "Blue Variant l 04",
            "value": "E6EBFF"
        },
        {
            "id": "gSL3q186YMvJOEOd2ifiZw",
            "name": "Blue d 07",
            "value": "14339F"
        },
        {
            "id": "gSVZi0LR8FQThM_33q5T4Q",
            "name": "Green d 08",
            "value": "005700"
        },
        {
            "id": "gSPDA2vjWpOWJxcHmNCg-g",
            "name": "Tertiary l 15",
            "value": "90A352"
        },
        {
            "id": "gSPDA2vjWpOWJxcHl9CXvg",
            "name": "Tertiary l 14",
            "value": "95A956"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFypg",
            "name": "Red Variant l 07",
            "value": "FFC9C2"
        },
        {
            "id": "gSL3q186VMtFwtqCt8-Xug",
            "name": "Green Variant l 04",
            "value": "ADFFAD"
        },
        {
            "id": "gSVZjELkEekstR3c0NR0GQ",
            "name": "Orange Variant d 09",
            "value": "7D4407"
        },
        {
            "id": "gSPC9GsO-ltea9FjpytUjw",
            "name": "Magenta d 15",
            "value": "D06DC1"
        },
        {
            "id": "gSPC82sABPg3CmNcdrulZQ",
            "name": "Green l 06",
            "value": "77F877"
        },
        {
            "id": "gSbwOBwR9-pslzv4RGktBQ",
            "name": "Neutral l 03",
            "value": "F8F1E8"
        },
        {
            "id": "gSSOTbfSJP0SD4POJizmWg",
            "name": "Yellow d 09",
            "value": "705000"
        },
        {
            "id": "gSPC82sABPg3CmNcf7v7Ig",
            "name": "Green l 15",
            "value": "3EB13E"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tX0w",
            "name": "Red d 02",
            "value": "3D0600"
        },
        {
            "id": "gSYk4A9MdP4-wpvHOWgT3w",
            "name": "Yellow Variant l 13",
            "value": "C3A760"
        },
        {
            "id": "gSYk2Q7IKoSQ2Sqx7BEyaA",
            "name": "Green d 14",
            "value": "209720"
        },
        {
            "id": "gSPC9GsPBlv1wxRVHWu10Q",
            "name": "Cyan Variant l 06",
            "value": "7EF1E2"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tXxQ",
            "name": "Red l 15",
            "value": "E97C72"
        },
        {
            "id": "gSbwKBrJ6MqNwXi6vcol7g",
            "name": "Cyan l 08",
            "value": "6DE3D4"
        },
        {
            "id": "gSYk2g7b2TXMyfowmNPitA",
            "name": "Magenta d 14",
            "value": "C464B6"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4KQ",
            "name": "Orange l 01",
            "value": "F"
        },
        {
            "id": "gSYk4Q9gH682EVQgZsIs8w",
            "name": "Blue Variant d 14",
            "value": "7283D5"
        },
        {
            "id": "gSbwOBwR-Op_gN_hp2aDug",
            "name": "Tertiary l 12",
            "value": "A6B96A"
        },
        {
            "id": "gSIscJP1T6o0uv4ZnWTQFw",
            "name": "Tertiary d 04",
            "value": "232E00"
        },
        {
            "id": "gSbwLxtZLROa7tiTi6Ilzw",
            "name": "Yellow Variant d 05",
            "value": "423100"
        },
        {
            "id": "gSL3ql8tzIaRLpz1DT_5OQ",
            "name": "Magenta Variant l 07",
            "value": "FFC2F6"
        },
        {
            "id": "gSL3q186Xsuzgt6qYmhagQ",
            "name": "Error d 04",
            "value": "570A00"
        },
        {
            "id": "gSPC82sAEPjNcZv4ui6NHg",
            "name": "Primary l 14",
            "value": "D29851"
        },
        {
            "id": "gSPC82sAEPjNcZv4rC4I_Q",
            "name": "Primary d 15",
            "value": "BB8135"
        },
        {
            "id": "gSYk2Q7IKoSQ2SqyCBK-4Q",
            "name": "Green l 13",
            "value": "4EC14E"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxKg",
            "name": "Orange l 06",
            "value": "FFD5AD"
        },
        {
            "id": "gSL3uV_qh5VWGbrhSR9ltA",
            "name": "Neutral d 11",
            "value": "6D6355"
        },
        {
            "id": "gSbwOBwR-Op_gN_hq2bDCQ",
            "name": "Tertiary l 08",
            "value": "C3D97D"
        },
        {
            "id": "gSbwKRrfKprGpVn0mAw-5Q",
            "name": "Cyan Variant l 08",
            "value": "75E1D2"
        },
        {
            "id": "gSe7g2fLz61xRNfOFJ38Rg",
            "name": "Red Variant l 08",
            "value": "FFBEB3"
        },
        {
            "id": "gSPC-2tyL8wyt28xU-p2-g",
            "name": "Blue Variant l 15",
            "value": "8495F1"
        },
        {
            "id": "gSbwLxtZLROa7tiTlaLCzw",
            "name": "Yellow Variant l 12",
            "value": "CBAF67"
        },
        {
            "id": "gSPDA2vjWpOWJxcHldCFMg",
            "name": "Tertiary d 15",
            "value": "829546"
        },
        {
            "id": "gSbwMxurWx6w04dxFmb2Hw",
            "name": "Red Variant d 10",
            "value": "974235"
        },
        {
            "id": "gSPDI22o9bz0pdpw26DohQ",
            "name": "Secondary",
            "value": "845415"
        },
        {
            "id": "gSe7emcF3siqCxEsoBxecQ",
            "name": "Error l 08",
            "value": "FFBFB8"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhgg",
            "name": "Red d 01",
            "value": "240400"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALvw",
            "name": "Neutral l 07",
            "value": "E2D6C5"
        },
        {
            "id": "gSVZi0LR8FQThM_38K83CA",
            "name": "Green l 09",
            "value": "68DF68"
        },
        {
            "id": "gSYk1A5qh2EL74Fruw90aw",
            "name": "Secondary l 13",
            "value": "C8A474"
        },
        {
            "id": "gSPC82sABPg3CmNcbbtQ3A",
            "name": "Green d 02",
            "value": "001F00"
        },
        {
            "id": "gSL3uV_qiJVhI-8cAmdauA",
            "name": "Tertiary d 13",
            "value": "6A8023"
        },
        {
            "id": "gSIscJP1T6o0uv4ZrGUvTA",
            "name": "Tertiary l 10",
            "value": "B4C874"
        },
        {
            "id": "gSPC9GsPBlv1wxRVJmwKjw",
            "name": "Cyan Variant l 15",
            "value": "49AB9E"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyayYQ",
            "name": "Neutral Variant l 06",
            "value": "EEDCC4"
        },
        {
            "id": "gSIsYJNEXGOb1RLjDHxXKQ",
            "name": "Green l 10",
            "value": "64D864"
        },
        {
            "id": "gSPC82sABPg3CmNcfrvw5g",
            "name": "Green l 14",
            "value": "42B842"
        },
        {
            "id": "gSPC82sAEPjNb5vi98ke3g",
            "name": "Cyan l 06",
            "value": "77F3E3"
        },
        {
            "id": "gSL3sV-F9mxPyu2U2-j6gQ",
            "name": "Blue Variant l 01",
            "value": "F"
        },
        {
            "id": "gSIsZ5ORitBIFVQ-Sc-znQ",
            "name": "Yellow Variant l 10",
            "value": "DBBE70"
        },
        {
            "id": "gSPC7mq6AESMmEmzOrR9vQ",
            "name": "Secondary d 15",
            "value": "AA8755"
        },
        {
            "id": "gSbwKhrzlls0WX3DvyE4gg",
            "name": "Error d 10",
            "value": "BC1501"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6Teg",
            "name": "Error l 02",
            "value": "FFF6F5"
        },
        {
            "id": "gSPC9GsPAFuqJnOBgVZwow",
            "name": "Magenta Variant d 15",
            "value": "D06CC2"
        },
        {
            "id": "gSYk2Q7IKoSQ2Sqx9hHAmQ",
            "name": "Green l 03",
            "value": "C7FFC7"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFyrQ",
            "name": "Red Variant d 01",
            "value": "290300"
        },
        {
            "id": "gSVZkkNLNQ7aPDo6bb5RQg",
            "name": "Yellow Variant l 09",
            "value": "E5C476"
        },
        {
            "id": "gSPC9GsPBlv1wxRVM2yFag",
            "name": "Cyan Variant d 15",
            "value": "3B9B8E"
        },
        {
            "id": "gSPDAmvVVTw84a_25Ct_Xg",
            "name": "Yellow d 15",
            "value": "A58A31"
        },
        {
            "id": "gSbwKxsH0hgXGPSibO7HJg",
            "name": "Orange l 11",
            "value": "EEAC72"
        },
        {
            "id": "gSVZk0NdTKNWbDMUFFms-g",
            "name": "Blue Variant d 08",
            "value": "2D448F"
        },
        {
            "id": "gSSOP7b1xORHtsBcBys4Zg",
            "name": "Magenta Variant d 09",
            "value": "8E257F"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIdA",
            "name": "Error d 03",
            "value": "470800"
        },
        {
            "id": "gSVZjELkEeksqh1AoPr2eQ",
            "name": "Magenta Variant l 09",
            "value": "FFA8F5"
        },
        {
            "id": "gSL3qV8gcDi8xkq20Stc-g",
            "name": "Cyan l 07",
            "value": "71EADA"
        },
        {
            "id": "gSe7eWbv2eeb6HjImdRMnA",
            "name": "Orange Variant l 12",
            "value": "E2A574"
        },
        {
            "id": "gSVZm0Pnp-LLKKCK08wk8g",
            "name": "Neutral d 09",
            "value": "5C5142"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4QQ",
            "name": "Orange d 12",
            "value": "9A5F28"
        },
        {
            "id": "gSe7eWbv2eeb6HjImdRMoA",
            "name": "Orange Variant l 08",
            "value": "FFC28A"
        },
        {
            "id": "gSL3q186VMtFwtqCqc8osQ",
            "name": "Green Variant l 07",
            "value": "7BEF7B"
        },
        {
            "id": "gSL3q186Xsuzgt6qYmhaiA",
            "name": "Error d 11",
            "value": "CA1802"
        },
        {
            "id": "gSYk2w7uvdlffExzEsgR_Q",
            "name": "Error l 09",
            "value": "FFB2A8"
        },
        {
            "id": "gSYk2g7b3zY03C6QbwfQAw",
            "name": "Magenta Variant l 11",
            "value": "FF8FEF"
        },
        {
            "id": "gSL3q186Xsuzgt6qYmhakg",
            "name": "Error l 10",
            "value": "FFA699"
        },
        {
            "id": "gSbwKRrfJJpVSYSF3jWP1Q",
            "name": "Orange Variant d 10",
            "value": "874F17"
        },
        {
            "id": "gSbwKRrfJJpVSYSF3jWPzw",
            "name": "Orange Variant l 11",
            "value": "EBAD7A"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxIw",
            "name": "Orange l 15",
            "value": "CC8E5C"
        },
        {
            "id": "gSe7nGnzP7t8NWAt_a6vGg",
            "name": "Orange Variant",
            "value": "8D4D11"
        },
        {
            "id": "gSL3uV_qiJVhI-8cA2dikQ",
            "name": "Tertiary d 12",
            "value": "60750F"
        },
        {
            "id": "gSYk4Q9gH682EVQgbMKCPw",
            "name": "Blue Variant l 03",
            "value": "F0F3FF"
        },
        {
            "id": "gSVZi0LR8FQThM_37K8EOw",
            "name": "Green l 05",
            "value": "84FF80"
        },
        {
            "id": "gSbwMBtuauOH8iY9xzK4kQ",
            "name": "Blue Variant l 08",
            "value": "BDCCFF"
        },
        {
            "id": "gSYk2Q7INoVf7YK32AKtYQ",
            "name": "Cyan l 11",
            "value": "5DCBBC"
        },
        {
            "id": "gSIskZVhIWELwXs7ZCGpOw",
            "name": "Red",
            "value": "A73121"
        },
        {
            "id": "gSbwMxurWx6w04dxFmb2Bw",
            "name": "Red Variant l 13",
            "value": "F68D84"
        },
        {
            "id": "gSbwKBrJ3Mmrx9q34UizoQ",
            "name": "Green l 08",
            "value": "6DE86D"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB9_A",
            "name": "Red Variant l 15",
            "value": "E08076"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7RQ",
            "name": "Neutral d 02",
            "value": "22180C"
        },
        {
            "id": "gSbwKhrzjFp33yHkiNqq6A",
            "name": "Green Variant d 05",
            "value": "003D00"
        },
        {
            "id": "gSVZlkOQyjTyOomiuXRPWA",
            "name": "Red Variant d 09",
            "value": "8E3529"
        },
        {
            "id": "gSCV7Lxb_fpkuf0_c-7hIQ",
            "name": "Error",
            "value": "F61B00"
        },
        {
            "id": "gSVZjUL1X3E8ixWIEk6srA",
            "name": "Blue l 05",
            "value": "E0E6FF"
        },
        {
            "id": "gSVZjUL1XXEdYzsv3Ju5pw",
            "name": "Error d 09",
            "value": "AD1000"
        },
        {
            "id": "gSSOSbeSvjjwxuaZnHB-Dg",
            "name": "Red Variant d 02",
            "value": "3D0600"
        },
        {
            "id": "gSbwKhrzmFtZzb8olnvsIQ",
            "name": "Blue d 05",
            "value": "032391"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7Og",
            "name": "Neutral l 06",
            "value": "E9DDCE"
        },
        {
            "id": "gSL3ql8t0obTWNWgZCFckA",
            "name": "Cyan Variant l 04",
            "value": "8FFFF0"
        },
        {
            "id": "gSe7iGg6TBq13UTqV9EAtg",
            "name": "Neutral d 05",
            "value": "3D301F"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyayWg",
            "name": "Neutral Variant l 15",
            "value": "A49684"
        },
        {
            "id": "gSIsY5Nl-gT6B0807bNssA",
            "name": "Orange d 06",
            "value": "612F00"
        },
        {
            "id": "gSPC-2tyL8wyt28xTOo01Q",
            "name": "Blue Variant l 06",
            "value": "D1DCFF"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdmA",
            "name": "Orange Variant d 03",
            "value": "381A00"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdhg",
            "name": "Orange Variant l 14",
            "value": "CE9869"
        },
        {
            "id": "gSPC82sAEPjNb5vi8MjcuQ",
            "name": "Cyan l 15",
            "value": "3EAC9E"
        },
        {
            "id": "gSYk6A_kVieJox921K2dyw",
            "name": "Yellow l 11",
            "value": "D7B560"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rG_1aZg",
            "name": "Yellow Variant d 02",
            "value": "241A00"
        },
        {
            "id": "gSYk1A5qh2EL74Frpw5ZBA",
            "name": "Secondary d 14",
            "value": "9D7D4D"
        },
        {
            "id": "gSbwKRrfHpnjuauSdKzZdg",
            "name": "Magenta l 08",
            "value": "FFB8F5"
        },
        {
            "id": "gSVZjUL1U3CAZuZcrW6zsg",
            "name": "Green Variant l 05",
            "value": "8AFF8A"
        },
        {
            "id": "gSL3qV8gcDi8yErJYHMY2w",
            "name": "Primary d 13",
            "value": "A06C1C"
        },
        {
            "id": "gSYk6Q_3FMiJyS759XM2JA",
            "name": "Tertiary l 11",
            "value": "AEC270"
        },
        {
            "id": "gSL3uV_qiJVhI-8cC2ehTg",
            "name": "Tertiary l 01",
            "value": "FDFFF5"
        },
        {
            "id": "gSbwKBrJ6MqNw3jdFNYXSg",
            "name": "Primary l 08",
            "value": "FFC475"
        },
        {
            "id": "gSL3pF7iPuZjk5rx8cpbIw",
            "name": "Secondary l 02",
            "value": "FFF5E5"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4Ow",
            "name": "Orange l 04",
            "value": "FFE8D1"
        },
        {
            "id": "gSPC82sAEPjNcZv4wi7Ynw",
            "name": "Primary l 06",
            "value": "FFD799"
        },
        {
            "id": "gSIsYJNEaGQMcMshMmWk1A",
            "name": "Cyan d 11",
            "value": "007566"
        },
        {
            "id": "gSbwKBrJ3Mmrx9q300fWag",
            "name": "Green d 05",
            "value": "003D00"
        },
        {
            "id": "gSVZhkJ8LLeRiodOoA49bg",
            "name": "Secondary l 05",
            "value": "FFE0B8"
        },
        {
            "id": "gSe7eWbv2eeb6HjImdRMrg",
            "name": "Orange Variant d 05",
            "value": "572700"
        },
        {
            "id": "gSYk2g7b2TXMyfowftJyUQ",
            "name": "Magenta l 11",
            "value": "FF8FEF"
        },
        {
            "id": "gSbwNxv9pyv9Q10isDa6sQ",
            "name": "Yellow d 05",
            "value": "423100"
        },
        {
            "id": "gSSOPrblQmnoJ0dJmi67Gg",
            "name": "Cyan d 09",
            "value": "006154"
        },
        {
            "id": "gSL3sV-F9mxPyu2U1-jbvQ",
            "name": "Blue Variant l 07",
            "value": "C7D3FF"
        },
        {
            "id": "gSL3pl77HW2kCdwCAj-sUw",
            "name": "Neutral Variant d 11",
            "value": "6F624D"
        },
        {
            "id": "gSYk6Q_3E8h4cqZbyIawXQ",
            "name": "Neutral d 08",
            "value": "534736"
        },
        {
            "id": "gSL3sF94mB5lmzW31G17Pw",
            "name": "Yellow Variant d 12",
            "value": "866A13"
        },
        {
            "id": "gR_KrrCCCcrRUv1Igvv1zQ",
            "name": "Blue",
            "value": "3351CD"
        },
        {
            "id": "gSbwKRrfJJpVSYSF3jWPzQ",
            "name": "Orange Variant l 13",
            "value": "D89E6F"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxNA",
            "name": "Orange d 03",
            "value": "381A00"
        },
        {
            "id": "gSL3uF_eFlGfvzF85kPE9g",
            "name": "Yellow d 12",
            "value": "896806"
        },
        {
            "id": "gSL3qV8gcDi8xkq2vyrP5g",
            "name": "Cyan l 04",
            "value": "8AFFF4"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhdQ",
            "name": "Red l 01",
            "value": "F"
        },
        {
            "id": "gSe7g2fLz61xRNfOFJ38Sg",
            "name": "Red Variant l 12",
            "value": "FE948B"
        },
        {
            "id": "gSYk6Q_3FMiJyS7583MaDQ",
            "name": "Tertiary l 13",
            "value": "9CAF60"
        },
        {
            "id": "gSL3qV8gZDg5ReEzezfTQw",
            "name": "Green l 02",
            "value": "DCFFDB"
        },
        {
            "id": "gSSOP7b1vuPyiacHW8rybg",
            "name": "Magenta d 09",
            "value": "8D257E"
        },
        {
            "id": "gSL3ql8tzIaRLpz1F0BHaQ",
            "name": "Magenta Variant l 04",
            "value": "FFE5FB"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOgQ",
            "name": "Orange Variant l 02",
            "value": "FFF4EB"
        },
        {
            "id": "gSIsj5VKafKTlkeLEp_j8Q",
            "name": "Primary",
            "value": "8C5000"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOig",
            "name": "Orange Variant d 12",
            "value": "94632E"
        },
        {
            "id": "gSbwMxurWx6w04dxFmb2Gw",
            "name": "Red Variant d 14",
            "value": "C77066"
        },
        {
            "id": "gSL3uV_qiJVhI-8b_WczLg",
            "name": "Tertiary l 04",
            "value": "E0F990"
        },
        {
            "id": "gSbwMxurWx6w04dxFmb2AQ",
            "name": "Red Variant l 11",
            "value": "FF9C8F"
        },
        {
            "id": "gSL3ql8t0obTWNWgRiBw4Q",
            "name": "Cyan Variant l 07",
            "value": "79E7D8"
        },
        {
            "id": "gSe7e2cbraVm1OD-tgp4sQ",
            "name": "Orange d 05",
            "value": "572700"
        },
        {
            "id": "gSe7emcF3siqCxEsoBxebQ",
            "name": "Error l 12",
            "value": "FF9080"
        },
        {
            "id": "gSbwJRqM949R-HPAZqTBgg",
            "name": "Neutral Variant l 13",
            "value": "B8A894"
        },
        {
            "id": "gSPC9GsPBlv1wxRVN2yrnA",
            "name": "Cyan Variant d 03",
            "value": "012722"
        },
        {
            "id": "gSL3ql8tzIaROZ1dQds_5g",
            "name": "Orange Variant d 04",
            "value": "422000"
        },
        {
            "id": "gSYk1A5qh2EL74FrrQ6uSA",
            "name": "Secondary l 03",
            "value": "FFEFD6"
        },
        {
            "id": "gSPC9GsPAlvEAQWbm9dhjg",
            "name": "Red d 13",
            "value": "C36055"
        },
        {
            "id": "gSL3uF_eFlGfvzF820Nt8w",
            "name": "Yellow d 01",
            "value": "140F00"
        },
        {
            "id": "gSSOTrfhvGpvjRLvvvy7UA",
            "name": "Neutral d 15",
            "value": "948B7F"
        },
        {
            "id": "gSPC7mq6AESMmEmzRLTbyQ",
            "name": "Secondary l 06",
            "value": "FFD79E"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALxg",
            "name": "Neutral d 01",
            "value": "140E06"
        },
        {
            "id": "gSbwMBtuauOH8iY9yzL3GA",
            "name": "Blue Variant l 12",
            "value": "94ABFF"
        },
        {
            "id": "gSL3uV_qiJVhI-8b_Gcraw",
            "name": "Tertiary d 07",
            "value": "364700"
        },
        {
            "id": "gSSOP7b1yuScxdg3Rm5jMw",
            "name": "Cyan Variant d 09",
            "value": "006154"
        },
        {
            "id": "gSL3q186VMtFwtqCuM-ffQ",
            "name": "Green Variant d 07",
            "value": "004D00"
        },
        {
            "id": "gSIsYpNbMtM4NcIhOI2Nsg",
            "name": "Blue d 04",
            "value": "001885"
        },
        {
            "id": "gSe7iGg6TBq13UTqV9EAqA",
            "name": "Neutral l 08",
            "value": "DBCEBD"
        },
        {
            "id": "gSSOO7a2fSHdJ-pZpyayUw",
            "name": "Neutral Variant d 15",
            "value": "998C7A"
        },
        {
            "id": "gSYk2Q7INoVf74LXAt0vlQ",
            "name": "Primary d 14",
            "value": "AC792A"
        },
        {
            "id": "gSFhPQi-u_e-o4cgzzlj_g",
            "name": "Orange",
            "value": "914C0C"
        },
        {
            "id": "gSYk2g7b2TXMyfowjtNUgg",
            "name": "Magenta l 03",
            "value": "FFEBFC"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6TaA",
            "name": "Error d 01",
            "value": "290300"
        },
        {
            "id": "gSL3ql8txoZO5mMu3QeGPQ",
            "name": "Magenta l 02",
            "value": "FFF5FE"
        },
        {
            "id": "gSbwKhrzlls0WX3DvyE4hg",
            "name": "Error d 14",
            "value": "FB2C0E"
        },
        {
            "id": "gSPC9WsdJbSWvYpBZu6Tcw",
            "name": "Error d 12",
            "value": "E21C03"
        },
        {
            "id": "gSYk4Q9gH682EVQgfMNkcA",
            "name": "Blue Variant l 11",
            "value": "9EB3FF"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rHP1j3w",
            "name": "Yellow Variant d 03",
            "value": "2E2000"
        },
        {
            "id": "gSSOTrfhvWp9vWxnGREz3w",
            "name": "Tertiary d 09",
            "value": "465C00"
        },
        {
            "id": "gSbwKhrzlls0WX3DvyE4fA",
            "name": "Error l 03",
            "value": "FFEDEB"
        },
        {
            "id": "gSbwKxsH0hgXGPSibO7HQA",
            "name": "Orange d 14",
            "value": "AE773D"
        },
        {
            "id": "gSPC9GsPAFuqJnOBZ1V68A",
            "name": "Magenta Variant l 14",
            "value": "EA7BDA"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV8A",
            "name": "Neutral Variant l 07",
            "value": "E7D4BB"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV5Q",
            "name": "Neutral Variant l 02",
            "value": "FDF6EC"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV2g",
            "name": "Neutral Variant l 04",
            "value": "F9EDDC"
        },
        {
            "id": "gSPC9msrFQrbFPxr0FS4Lg",
            "name": "Orange l 02",
            "value": "FFF4EB"
        },
        {
            "id": "gSbwKRrfJJpVPoPHHVY69Q",
            "name": "Magenta Variant d 05",
            "value": "660054"
        },
        {
            "id": "gSL3q186VMtFwtqCss9wIA",
            "name": "Green Variant d 01",
            "value": "001400"
        },
        {
            "id": "gSL3uV_qh5VWGbrhSR9lng",
            "name": "Neutral l 10",
            "value": "CBBFAE"
        },
        {
            "id": "gSPC9WsdG7QZPtlUcstCKQ",
            "name": "Green Variant d 03",
            "value": "002900"
        },
        {
            "id": "gSL3qV8gZDg5ReEzZzc12A",
            "name": "Green d 07",
            "value": "004D00"
        },
        {
            "id": "gSL3tF-rSDddfyghh9SpxQ",
            "name": "Red Variant l 10",
            "value": "FFA699"
        },
        {
            "id": "gSIsZ5ORitBIFVQ-OM9IFA",
            "name": "Yellow Variant d 04",
            "value": "332700"
        },
        {
            "id": "gSPC9GsPAFuqMXP69JUOlQ",
            "name": "Orange Variant d 07",
            "value": "6B3000"
        },
        {
            "id": "gSFhKQgBuA9_-peiIrCgmw",
            "name": "Tertiary d 06",
            "value": "314200"
        },
        {
            "id": "gSbwKRrfKprGpVn0pg0bUg",
            "name": "Cyan Variant d 05",
            "value": "013C33"
        },
        {
            "id": "gSYk2Q7IKoSQ2SqyBhKiyw",
            "name": "Green l 11",
            "value": "5ECF5E"
        },
        {
            "id": "gSYk6A_kVieJox920q2BtA",
            "name": "Yellow l 13",
            "value": "C9A654"
        },
        {
            "id": "gSbwKhrzmFtZzb8ohHrRVQ",
            "name": "Blue l 12",
            "value": "94ABFF"
        },
        {
            "id": "gSVZvEYjUXdi2IpwjWD6Bw",
            "name": "Red Variant",
            "value": "A33729"
        },
        {
            "id": "gSL3rF9Guw6CnSTOb2O-zg",
            "name": "Orange l 10",
            "value": "F7B178"
        },
        {
            "id": "gSVZmkPWfVziSec8f6l98g",
            "name": "Yellow l 09",
            "value": "EAC46C"
        },
        {
            "id": "gSPDA2vjWZOJidFrylAL0g",
            "name": "Neutral d 13",
            "value": "80776B"
        },
        {
            "id": "gSe7dWaXYFs7rxHKhNyjFg",
            "name": "Neutral Variant l 12",
            "value": "C1AF9A"
        },
        {
            "id": "gSPDA2vjWpOWJxcHidATkw",
            "name": "Tertiary d 03",
            "value": "1B2400"
        },
        {
            "id": "gSVZi0LR_FTPtf_bhxbvIg",
            "name": "Primary d 08",
            "value": "704000"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhV5iZpw",
            "name": "Blue l 15",
            "value": "7590FF"
        },
        {
            "id": "gSPC9GsO-ltea9FjnCrsOA",
            "name": "Magenta d 02",
            "value": "380030"
        },
        {
            "id": "gSL3ql8t0obTWNWgXSEldQ",
            "name": "Cyan Variant d 01",
            "value": "001412"
        },
        {
            "id": "gSIsYZNQN5-LyKyTb3Tdvg",
            "name": "Cyan Variant d 11",
            "value": "007565"
        },
        {
            "id": "gSL3ql8txoZO5mMu9whSGw",
            "name": "Magenta d 13",
            "value": "B856AA"
        },
        {
            "id": "gSPC82sAEPjNb5vi4chPUQ",
            "name": "Cyan d 03",
            "value": "022722"
        },
        {
            "id": "gSe7eWbv2-fFdwclDgLCMQ",
            "name": "Red l 08",
            "value": "FFBEB3"
        },
        {
            "id": "gSYk2g7b5Tac0GEYjDJqdQ",
            "name": "Cyan Variant l 13",
            "value": "5ABAAD"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIig",
            "name": "Error l 06",
            "value": "FFD3CC"
        },
        {
            "id": "gSSOP7b1xORHwcDmzikdlA",
            "name": "Orange Variant d 15",
            "value": "BB8658"
        },
        {
            "id": "gSSOQLcFfVN5GNONx5OIgQ",
            "name": "Error l 15",
            "value": "FF6552"
        },
        {
            "id": "gSPC7mq6AESMmEmzPrSjDw",
            "name": "Secondary d 03",
            "value": "331D00"
        },
        {
            "id": "gSL3ql8txoZO5mMu4Aedjg",
            "name": "Magenta l 01",
            "value": "F"
        },
        {
            "id": "gSYk6A_kVieJox926q7WRA",
            "name": "Yellow d 10",
            "value": "805900"
        },
        {
            "id": "gSe7eWbv2-fFdwclDgLCQw",
            "name": "Red d 05",
            "value": "6B0D00"
        },
        {
            "id": "gSVZi0LR_FTPtf_biRcI5A",
            "name": "Primary l 09",
            "value": "FBBD70"
        },
        {
            "id": "gSPC9WsdG7QZPtlUccs5AA",
            "name": "Green Variant d 02",
            "value": "001F00"
        },
        {
            "id": "gSL3sV-F9mxPyu2U0Oikmg",
            "name": "Blue Variant d 01",
            "value": "000B38"
        },
        {
            "id": "gSPC_mucOtIALlBQtAFyqw",
            "name": "Red Variant d 07",
            "value": "7B1809"
        },
        {
            "id": "gSbwOBwR9-pslzv4RGktCw",
            "name": "Neutral d 10",
            "value": "685D50"
        },
        {
            "id": "gSIsYJNEXGOb1RLi_Xv4-A",
            "name": "Green d 04",
            "value": "013201"
        },
        {
            "id": "gSYk2g7b4TZYKUmjGorTaA",
            "name": "Red d 08",
            "value": "892310"
        },
        {
            "id": "gSbwKxsH0hgXGPSibO7HNg",
            "name": "Orange l 03",
            "value": "FFEEDB"
        },
        {
            "id": "gSL3ql8tzIaRLpz1BD-xmQ",
            "name": "Magenta Variant l 02",
            "value": "FFF5FE"
        },
        {
            "id": "gSbwNxv9pyv9Q10iojXeUQ",
            "name": "Yellow l 08",
            "value": "F0CC70"
        },
        {
            "id": "gSbwKxsH0hgXGPSibO7HLA",
            "name": "Orange l 13",
            "value": "DA9E67"
        },
        {
            "id": "gSPC82sAEPjNcZv4qC3juw",
            "name": "Primary d 03",
            "value": "331D00"
        },
        {
            "id": "gSIsYZNQMZ9TEM0xlzrPzg",
            "name": "Magenta Variant l 10",
            "value": "FF9EF1"
        },
        {
            "id": "gSSOP7b1xuRkt3spN6tXxg",
            "name": "Red l 14",
            "value": "F28478"
        },
        {
            "id": "gSPC8GrWBPLzXXU9h5bV4w",
            "name": "Neutral Variant d 13",
            "value": "877A68"
        },
        {
            "id": "gSL3sV-F9mxPyu2UxOhF9A",
            "name": "Blue Variant d 13",
            "value": "6679C7"
        },
        {
            "id": "gSbwKhrzjFp33yHkdtmQRA",
            "name": "Green Variant l 12",
            "value": "63C563"
        },
        {
            "id": "gSCV7rxseDCVpfGLvpWUAQ",
            "name": "Green",
            "value": "007800"
        },
        {
            "id": "gSIsYpNbJtLHpQo55rZhPQ",
            "name": "Green Variant d 04",
            "value": "013201"
        },
        {
            "id": "gSPC82sAEPjNb5vi7cjA8Q",
            "name": "Cyan d 15",
            "value": "2F9D8F"
        },
        {
            "id": "gSPC9WsdJ7SvmRFhVpiQSw",
            "name": "Blue l 14",
            "value": "809AFF"
        },
        {
            "id": "gSVZtkW7O0LEaZ3IGRxNRQ",
            "name": "Transparent",
            "value": "0000"
        },
        {
            "id": "gSFhGgd0N-NfFd6CNvQlZQ",
            "name": "Magenta Variant d 06",
            "value": "700060"
        },
        {
            "id": "gSYk2Q7INoVf74LXBt1ovg",
            "name": "Primary d 10",
            "value": "8A4F00"
        },
        {
            "id": "gSVZjELkC-jOCn3V_LqxVQ",
            "name": "Magenta l 09",
            "value": "FFA8F5"
        },
        {
            "id": "gSPC-mtjPmk9Ew-rNv5Zcg",
            "name": "Yellow Variant l 06",
            "value": "FEDC86"
        },
        {
            "id": "gSbwJRqM949R-HPAZqTBeg",
            "name": "Neutral Variant d 10",
            "value": "695A44"
        },
        {
            "id": "gSbwKRrfJJpVSYSF3jWP3w",
            "name": "Orange Variant l 03",
            "value": "FFEEDB"
        },
        {
            "id": "gSVZjELkEeksqh1AtvwLSQ",
            "name": "Magenta Variant d 08",
            "value": "841574"
        },
        {
            "id": "gSSOQbcU_7-tNRNN2bJxOA",
            "name": "Orange d 15",
            "value": "C28451"
        },
        {
            "id": "gSPDAmvVVTw84a_21ysEgQ",
            "name": "Yellow d 02",
            "value": "241A00"
        },
        {
            "id": "gSL3pF7iPuZjk5rx28muLQ",
            "name": "Secondary d 13",
            "value": "917140"
        },
        {
            "id": "gSYk2w7uvdlffExzEsgSAQ",
            "name": "Error l 05",
            "value": "FFDFDB"
        },
        {
            "id": "gSIsb5PqcHeQsvizIMGJsA",
            "name": "Yellow d 04",
            "value": "382700"
        },
        {
            "id": "gSL3tF-rSDddfyghh9Sp1g",
            "name": "Red Variant d 04",
            "value": "570A00"
        },
        {
            "id": "gSFhGQdqAbgru95IcHmJLw",
            "name": "Cyan d 06",
            "value": "00423A"
        },
        {
            "id": "gSbwMBtuauOH8iY9tTGc6g",
            "name": "Blue Variant d 05",
            "value": "112A7E"
        },
        {
            "id": "gSL3yWCzsOexJc31nkl1-Q",
            "name": "Cyan",
            "value": "007364"
        },
        {
            "id": "gSPC-2tyL8wyt28xOumKhQ",
            "name": "Blue Variant d 15",
            "value": "798CE2"
        },
        {
            "id": "gSFhIAesHrxTstx7pbiV0w",
            "name": "Yellow Variant d 06",
            "value": "4D3900"
        },
        {
            "id": "gSPC9WsdG7QZPtlUYMqX3Q",
            "name": "Green Variant l 14",
            "value": "4EB34C"
        },
        {
            "id": "gSYk2w7uv9mBylfXDzRutw",
            "name": "Blue l 03",
            "value": "F0F3FF"
        },
        {
            "id": "gSL3ql8tzIaROZ1dQds_1w",
            "name": "Orange Variant l 10",
            "value": "F1B47E"
        },
        {
            "id": "gSPDA2vjWZOJidFrylALzQ",
            "name": "Neutral l 04",
            "value": "F5ECE1"
        },
        {
            "id": "gSL3q186VMtFwtqCpc8JvQ",
            "name": "Green Variant l 01",
            "value": "F"
        },
        {
            "id": "gSPDA2vjWpOWJxcHn9DjHw",
            "name": "Tertiary l 06",
            "value": "D0EA86"
        },
        {
            "id": "gSiG6_YakW4Lrr4oKdqNTg",
            "name": "Magenta Variant",
            "value": "9D208B"
        },
        {
            "id": "gSL3sF94mB5lmzW34G3Y6Q",
            "name": "Yellow Variant l 07",
            "value": "F5D47F"
        },
        {
            "id": "gSIsYJNEaGQMcsswo5VQPA",
            "name": "Primary d 04",
            "value": "3D2300"
        },
        {
            "id": "gSYk5A-YVpHgi-L04B95MQ",
            "name": "Red Variant l 09",
            "value": "FFB2A8"
        },
        {
            "id": "gSYk4A9MdP4-wpvHL2eFsg",
            "name": "Yellow Variant l 03",
            "value": "FFF1C2"
        },
        {
            "id": "gSYk-RElAvt3uhlHruckPA",
            "name": "Cyan Variant",
            "value": "047263"
        },
        {
            "id": "gSbwKhrzlls0WX3DvyE4ig",
            "name": "Error l 13",
            "value": "FF8270"
        },
        {
            "id": "gSL3ql8tzoan4wtRMQaRVw",
            "name": "Red d 04",
            "value": "570A00"
        },
        {
            "id": "gSVZm0PnqOLa7BBYWbg9BQ",
            "name": "Tertiary l 09",
            "value": "BDD279"
        }
    ],
    "styles": [
        {
            "id": "tJA663YkekuZKJkEHwsHIw",
            "name": "light / primary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOPrblQmnoKUdiirbCNg"
                    }
                ]
            }
        },
        {
            "id": "CBGwfzAuwE2XzP5elucdmQ",
            "name": "light / primary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2Q7INoVf74LXBt1ovg"
                    }
                ]
            }
        },
        {
            "id": "AUwGByrCMkO2hhWrt7PSpQ",
            "name": "light / surface / highest",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSSOO7a2fSHdJ-pZpyayYQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "bpWEaB9ucEOW2K_p3XE9rg",
            "name": "light / surface / medium",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "ZHaVxM9kbE6ZR-70wu1LIQ",
            "name": "light / surface / high",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "UBZpx2dVsE-6hAyQVkI5-w",
            "name": "light / surface / low",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjGg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "2ukull5oH0CYKgxg5kn60w",
            "name": "light / surface / lowest",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3E8h4cqZbyIawTw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjGg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Z28h219caEmuJo5vpzQVow",
            "name": "dark / surface / medium",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "FiMh-8c_JECUa6IUJCz-vA",
            "name": "dark / surface / high",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "uM_LUqqqnEKYGJNABFQ_7A",
            "name": "dark / surface / highest",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALxg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSIsXZMjzsxFfkqcMsvIWQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "umtJhoL-Hk-rpOHo_OGlrA",
            "name": "dark / surface / low",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qh5VWGbrhSR9lrw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGVw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "bxKVYY8YNE6_bp_TFfyJXA",
            "name": "dark / surface / lowest",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7iGg6TBq13UTqV9EAtg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGVw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "T6PRlk5yREaAgPKe17UpOQ",
            "name": "light / primary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOPrblQmnoKUdiirbCNg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "UrXKRPobKUCAf6shkiLJkg",
            "name": "light / primary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIsYJNEaGQMcsswrJWJcg"
                    }
                ]
            }
        },
        {
            "id": "wnQ8mhlsW0mhf8okLaASAg",
            "name": "light / primary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALvw"
                    }
                ]
            }
        },
        {
            "id": "vD4CJZpz40qY88-nib9zwQ",
            "name": "dark / primary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzA"
                    }
                ]
            }
        },
        {
            "id": "W4-xjyaZoUKH-sSRd7g2pg",
            "name": "dark / primary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_biRcI5A"
                    }
                ]
            }
        },
        {
            "id": "-2py-ngj80GCRRVDYGTP9Q",
            "name": "dark / primary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_biRcI5A"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "zYvj3TmuHEK34qfgQXigQQ",
            "name": "dark / primary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIsYJNEaGQMcsswspWvbA"
                    }
                ]
            }
        },
        {
            "id": "Kumqo1JuKE25-vQ9UmA56g",
            "name": "dark / primary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2Q7INoVf74LXDN29wg"
                    }
                ]
            }
        },
        {
            "id": "z_M_UebsWUC3_UtQTw9aug",
            "name": "light / secondary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "xrsXzikFHES23UR2TnDpXg",
            "name": "light / secondary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSSOPrblQmnoKUdiirbCNg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "yUcOlkkN_0SKiLufm-_NlQ",
            "name": "light / secondary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "cUxGGrEEUE29gzPy0U9wlg",
            "name": "light / secondary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2g"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "9BXNwaAjK0-Ov-VRHd31tQ",
            "name": "light / secondary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGZQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3qV8gcDi8yErJVnLKdQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "CoWwt3ugfki-mqYaBg4cJA",
            "name": "light / secondary-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7Og"
                    }
                ]
            }
        },
        {
            "id": "qPa24ChQU02-5vLTFFTcoQ",
            "name": "light / secondary-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pF7iPuZjk5rx6MoUhw"
                    }
                ]
            }
        },
        {
            "id": "BTqntRKp60eSSbSP47agBA",
            "name": "light / secondary-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pF7iPuZjk5rx6MoUhw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwIxpkWhK_DUc1tMsgAQ",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "NnQV81ZmSUOwGVtfFgl5UQ",
            "name": "light / secondary-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwIxpkWhK_DUc1xsw64Q"
                    }
                ]
            }
        },
        {
            "id": "dhmakFUKeUu4RHIglL5-vw",
            "name": "light / secondary-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOrA7UtQ"
                    }
                ]
            }
        },
        {
            "id": "jET3kR_skUS-oykvpKxNFg",
            "name": "light / secondary-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk1A5qh2EL74FrrQ6uSA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwIxpkWhK_DUc1tMsgAQ",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "VkODNYBi7UefB-87pkZ8Gg",
            "name": "light / secondary-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "dp5GmCD1h0q9XFiup2UIdQ",
            "name": "light / secondary-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk1A5qh2EL74FrrQ6uSA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "7SsVCOvIXkePNwlZjdMQ4Q",
            "name": "light / secondary-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOoA49bg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSIsW5MOFmc2TyBzY7TEoA",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "oFvkeSY0hkiu1jjuAxQlsw",
            "name": "light / secondary-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pF7iPuZjk5rx5soENA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOrA7UtQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "zUcC5u4l6kuNha4UXoeLZA",
            "name": "light / tertiary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALvw"
                    }
                ]
            }
        },
        {
            "id": "40qHt1Mrukq65l2K0jzEgg",
            "name": "light / tertiary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvWp9vWxnGREz3w"
                    }
                ]
            }
        },
        {
            "id": "Pwgp83VyrUqIM1n8rRu9fw",
            "name": "light / tertiary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvWp9vWxnGREz3w"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSbwOBwR-Op_gN_hmWWnWA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "pOdf5H6_rkW6V3Jcr0M8Ng",
            "name": "light / tertiary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3FMiJyS7563Kn8g"
                    }
                ]
            }
        },
        {
            "id": "n2vWjky5S0CFSIWCV5-9XA",
            "name": "light / tertiary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1T6o0uv4ZomTvzQ"
                    }
                ]
            }
        },
        {
            "id": "_bKWC5H5fEWSyS4XS9brNQ",
            "name": "light / tertiary-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7Og"
                    }
                ]
            }
        },
        {
            "id": "0gc3c2zjQE-uNREyz6kRHw",
            "name": "light / tertiary-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8cD2fAQw"
                    }
                ]
            }
        },
        {
            "id": "FIQhbGCUFkyTBjhDWZKCuA",
            "name": "light / tertiary-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8cD2fAQw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwOBwR-Op_gN_hmWWnWA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "wC6KEztrhke8rnfoEjvDJA",
            "name": "light / tertiary-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR-Op_gN_hq2bDCQ"
                    }
                ]
            }
        },
        {
            "id": "4Md5vIURKUOazgNfDUsS1w",
            "name": "light / tertiary-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYWbg9BQ"
                    }
                ]
            }
        },
        {
            "id": "JjDD3i4xS0i3HPRQPI0mDQ",
            "name": "light / tertiary-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "-MWezooXPkWlpuIcrG6oiA",
            "name": "light / tertiary-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3FMiJyS755XJS6g"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "I5cr8fH4dU6FOn9SmJugqg",
            "name": "light / tertiary-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3FMiJyS755XJS6g"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwOBwR-Op_gN_hmWWnWA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "VagwvFesikmX0QlSTwgg1w",
            "name": "light / tertiary-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8b_WczLg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYWbg9BQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "K6IG_p7HAkKiE1cEEhp5bA",
            "name": "light / tertiary-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYXbhvsw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSIscJP1T6o0uv4ZrGUvTA",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "5STo1jq7ZkCZbqhgiUc6YQ",
            "name": "light / error / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALvw"
                    }
                ]
            }
        },
        {
            "id": "oolgjTMhGkqIRXaWc-CNLA",
            "name": "light / error / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZjUL1XXEdYzsv3Ju5pw"
                    }
                ]
            }
        },
        {
            "id": "eEOyHF6hOEysrOBlF3L8_w",
            "name": "light / error / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZjUL1XXEdYzsv3Ju5pw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxeXw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "HAm7KII9Fk6fUDgN4L3KPg",
            "name": "light / error / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKhrzlls0WX3DvyE4gg"
                    }
                ]
            }
        },
        {
            "id": "v8H8l0n4YkqBRNwFwNmswA",
            "name": "light / error / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3q186Xsuzgt6qYmhaiA"
                    }
                ]
            }
        },
        {
            "id": "IU_3EdGvgk-yPcm9e_P2Rw",
            "name": "light / error-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7Og"
                    }
                ]
            }
        },
        {
            "id": "JWbW0LZJ60amh5jt5tqKgQ",
            "name": "light / error-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6Tfw"
                    }
                ]
            }
        },
        {
            "id": "2CNs0oEiBUiZJsgVy-Ektw",
            "name": "light / error-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6Tfw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxeXw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "sdPAOc3MF0CuEqWH0OoUyA",
            "name": "light / error-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxecQ"
                    }
                ]
            }
        },
        {
            "id": "QUwNyti0FUeErDQBoB_Vwg",
            "name": "light / error-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR_Q"
                    }
                ]
            }
        },
        {
            "id": "EVUw3g0D70Wjk4W-1LNxBA",
            "name": "light / error-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Au8C9kiBHEaNeX57MRhZUA",
            "name": "light / error-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKhrzlls0WX3DvyE4fA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxecQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "l5uksf5XCUOGFRUtLQqmXw",
            "name": "light / error-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKhrzlls0WX3DvyE4fA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxeXw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "f5q0fPseZ0e-tRqTZGXAew",
            "name": "light / error-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6TbQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR_Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "yPBtoqB0w0uuUHMzs03TbA",
            "name": "light / error-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgSAQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3q186Xsuzgt6qYmhakg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "CvaJMPVw2EmKh2XkZNwXKQ",
            "name": "light / neutral / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "pGii3b0yT06t0VbGyUK53A",
            "name": "light / neutral / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjGg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "0GnSvlAgRUuf24mihtV25A",
            "name": "light / neutral / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "C4KRz6dDwE6H_E6Wrc2R2w",
            "name": "light / neutral / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3E8h4cqZbyIawTw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGYQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "vkfsqoRPK0Kt5ro3-DxBDg",
            "name": "light / neutral / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7Og"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3pl77HW2kCdwCAj-sYQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "qwkYd9F3QUmYN7b8Q9WwrA",
            "name": "light / neutral-bright / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "gHogIJU5rEGyRNv-eLANMg",
            "name": "light / neutral-bright / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjGg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "BTjSDJhbOUi4sW1JH6akqQ",
            "name": "light / neutral-bright / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "1ckpVOcTs0WtBbhGmJVTCQ",
            "name": "light / neutral-bright / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGYQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "16vWMHAkVEm93mv4uCWUqg",
            "name": "light / neutral-bright / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR9-pslzv4RGktBQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3pl77HW2kCdwCAj-sYQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "4qy3dP91O02_jIk2nHzvuA",
            "name": "light / transparent / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "XRlWHqOhyEK3cVyf_4gqsg",
            "name": "light / transparent / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "M7fzZQEk5UC5OxpQO_KjpQ",
            "name": "light / transparent / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "xRNd458qsEe-i3Ku2Y26_w",
            "name": "light / transparent / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOO7a2fSHdJ-pZpyayYQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "nIwqMiBf4Eak1T3gCODASA",
            "name": "light / transparent / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV8A"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "oQFCGQ6JHkubfcjZNrZj9g",
            "name": "light / transparent-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Pwg5_754TEKwVbCajtcKCQ",
            "name": "light / transparent-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "oFcm-JE05UOucyzRYscjEw",
            "name": "light / transparent-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "2r1Q-9qFq0meYHM6G3ot0w",
            "name": "light / transparent-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2g"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "pPJ_cbrnckykWe6aKN2niQ",
            "name": "light / transparent-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGZQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "jPsLAtyKrkGEMYkH3plGCQ",
            "name": "light / invert-primary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALvw"
                    }
                ]
            }
        },
        {
            "id": "fca9CRq10EWJxSrBzkiZ3Q",
            "name": "light / invert-primary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdFNYXSg"
                    }
                ]
            }
        },
        {
            "id": "7bu-JDSCaUO9j2eaKkLK5A",
            "name": "light / invert-primary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdFNYXSg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdBtU64w",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "jHGIIjgsrk-fpmuxgnNVdw",
            "name": "light / invert-primary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_biRcI5A"
                    }
                ]
            }
        },
        {
            "id": "3BSfWWrEs0el4IZcmBFLgA",
            "name": "light / invert-primary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIsYJNEaGQMcsswspWvbA"
                    }
                ]
            }
        },
        {
            "id": "sd0wXbAiP0e5RUsf0Vzkyw",
            "name": "dark / error / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzA"
                    }
                ]
            }
        },
        {
            "id": "8o1Hez2qa0q_Ixz4bKOw7w",
            "name": "dark / error / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR_Q"
                    }
                ]
            }
        },
        {
            "id": "XFjBPrFTn0yuET750Tktzg",
            "name": "dark / error / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR_Q"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgSAQ",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "h51b_zyZdkyxq6NoonAO3Q",
            "name": "dark / error / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3q186Xsuzgt6qYmhakg"
                    }
                ]
            }
        },
        {
            "id": "meof7mzjS0mZb_3-Nuv5ow",
            "name": "dark / error / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwKhrzlls0WX3DvyE4jA"
                    }
                ]
            }
        },
        {
            "id": "slrgxuVde0-cz6UcJf3r4g",
            "name": "dark / error-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1TqorQ9jFeuCFQQ"
                    }
                ]
            }
        },
        {
            "id": "U-NCHUZ58EixNDeWuwb0hA",
            "name": "dark / error-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6Tbg"
                    }
                ]
            }
        },
        {
            "id": "P_doQbyXvkWV1Lzi5EZkRw",
            "name": "dark / error-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC9WsdJbSWvYpBZu6Tbg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgSAQ",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "s6oaTl3SoEC1_1ZSwMa2ow",
            "name": "dark / error-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR7w"
                    }
                ]
            }
        },
        {
            "id": "i49K3zvCq0OXP_Jg4QWARQ",
            "name": "dark / error-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZjUL1XXEdYzsv3Ju5pw"
                    }
                ]
            }
        },
        {
            "id": "u7mjf9tsjEmR4i9uN_X4YA",
            "name": "dark / error-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "aCoHIYAqWEO_tz2d5t7txg",
            "name": "dark / error-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOQLcFfVN5GNONx5OIdA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgR7w",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "m-UhWvI7c0yKCQLgnS0tZw",
            "name": "dark / error-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOQLcFfVN5GNONx5OIdA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk2w7uvdlffExzEsgSAQ",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "7zVhjGIHhE2dMLal2kZS6g",
            "name": "dark / error-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3q186Xsuzgt6qYmhagQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZjUL1XXEdYzsv3Ju5pw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "XS1AUZF7Qki3DlmBcyfgvA",
            "name": "dark / error-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7emcF3siqCxEsoBxeXw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKhrzlls0WX3DvyE4gg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "ybwulxcKcESZmVTox34AUw",
            "name": "dark / invert-primary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk2Q7INoVf74LXBt1ovg"
                    }
                ]
            }
        },
        {
            "id": "GGH0cnt-okOnnA0E50XLRQ",
            "name": "dark / invert-primary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOPrblQmnoKUdiirbCNg"
                    }
                ]
            }
        },
        {
            "id": "HNhS4B96z0iEVdq_fJeSaA",
            "name": "dark / invert-primary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_bhxbvIg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "PO3nit3-kE6BLh4B6Ao2rA",
            "name": "dark / invert-primary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_bhxbvIg"
                    }
                ]
            }
        },
        {
            "id": "pxNFS40eUkmkpBPTv4ZS6Q",
            "name": "dark / invert-primary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzA"
                    }
                ]
            }
        },
        {
            "id": "_EPsQNOowUOukl4p8jrZSQ",
            "name": "dark / neutral / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "lo4YWO6oS0mvosfldCMT6Q",
            "name": "dark / neutral / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qh5VWGbrhSR9lrw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGVw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "0tX3upkELEyLbnU7oM2PuA",
            "name": "dark / neutral / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qh5VWGbrhSR9lrw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "8oKq6vJdWUWPu5qysVumQQ",
            "name": "dark / neutral / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7iGg6TBq13UTqV9EAtg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZiEKefcMkJDl_fRHUcw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "VD28aJ28PUuAoJq-EJrf-w",
            "name": "dark / neutral / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1TqorQ9jFeuCFQQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwJRqM949R-HPAZqTBeg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "JwxcccIT2kKTdxfBFY7X5Q",
            "name": "dark / neutral-bright / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "NQC_JQNsyk-y3KZcd7bY0A",
            "name": "dark / neutral-bright / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALxg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1g6P_qKkHmtkg5WGVw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "dWsDtUMkckKGX0toj4aCuQ",
            "name": "dark / neutral-bright / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALxg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "5Y7XpZ1M8kezf9jATQ923g",
            "name": "dark / neutral-bright / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZiEKefcMkJDl_fRHUcw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Za44_mVrm0ePl_L-5akOXA",
            "name": "dark / neutral-bright / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwJRqM949R-HPAZqTBeg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "5It_oMyObESX2mrweIv5TQ",
            "name": "dark / secondary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "XcRI94a3806-1kFrXxu9SA",
            "name": "dark / secondary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_biRcI5A",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "8UYVYe_DWk-I6GPZIsVb_g",
            "name": "dark / secondary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "I46iGoLrZUqSB0zj3HDitQ",
            "name": "dark / secondary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pl77HW2kCdwCAj-sUg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "QeZy7VTZoEeuLyiBhloCUQ",
            "name": "dark / secondary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjDA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3qV8gcDi8yErJdXO-3g",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "6SAfc3Ug2Ey3qfnneQWl9A",
            "name": "dark / secondary-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1TqorQ9jFeuCFQQ"
                    }
                ]
            }
        },
        {
            "id": "rcjk4ARTpkSrx3k0qVvbeA",
            "name": "dark / secondary-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pF7iPuZjk5rx5cn8cQ"
                    }
                ]
            }
        },
        {
            "id": "JPearaRa3ke-iVaim3tw1w",
            "name": "dark / secondary-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pF7iPuZjk5rx5cn8cQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOoA49bg",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "KBhmcoWmo06RW3rFXXNm0A",
            "name": "dark / secondary-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOjg1abw"
                    }
                ]
            }
        },
        {
            "id": "zjx93us1HkOK0klN3bYz8Q",
            "name": "dark / secondary-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOObaXUkdbBDMG8Llg3Q"
                    }
                ]
            }
        },
        {
            "id": "PGyUrzDqF02qxufhqOXy1w",
            "name": "dark / secondary-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "ZPp_2C6vqE-BCOpOQqkZJw",
            "name": "dark / secondary-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC7mq6AESMmEmzPrSjDw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "svWaimQ48U2hHqO2lAMnLw",
            "name": "dark / secondary-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC7mq6AESMmEmzPrSjDw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZhkJ8LLeRiodOoA49bg",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "zif67tepaUmJQFqyEPpAwA",
            "name": "dark / secondary-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIsW5MOFmc2TyBzVLRmaw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSSOObaXUkdbBDMG8Llg3Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "ZIHsD8uZ_0Ga7EmdwLwn_A",
            "name": "dark / secondary-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwIxpkWhK_DUc1tMsgAQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk1A5qh2EL74Frow4gGg",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "EQ0BmYCv0UKiO04jwpWkkQ",
            "name": "dark / tertiary / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALzA"
                    }
                ]
            }
        },
        {
            "id": "FPgZOMMLokeSSrX9wvmy_A",
            "name": "dark / tertiary / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYWbg9BQ"
                    }
                ]
            }
        },
        {
            "id": "6RpQGisgsEGvblBaDmrbYQ",
            "name": "dark / tertiary / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYWbg9BQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                        "pos": 1,
                        "thickness": 4
                    },
                    {
                        "colorId": "gSVZm0PnqOLa7BBYXbhvsw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "_QZqS2l0ykC9Re88W49d0Q",
            "name": "dark / tertiary / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1T6o0uv4ZrGUvTA"
                    }
                ]
            }
        },
        {
            "id": "bzFta1c9vki0bVQ5-II_kw",
            "name": "dark / tertiary / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSYk6Q_3FMiJyS759XM2JA"
                    }
                ]
            }
        },
        {
            "id": "yjdjo100QkqKXbPSaH4_6g",
            "name": "dark / tertiary-lighted / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1TqorQ9jFeuCFQQ"
                    }
                ]
            }
        },
        {
            "id": "T8znc7d6dEquo0CY1GwO0A",
            "name": "dark / tertiary-lighted / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8b_Gcraw"
                    }
                ]
            }
        },
        {
            "id": "ZqZBtFVN40mlD7OX8Z7FXw",
            "name": "dark / tertiary-lighted / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3uV_qiJVhI-8b_Gcraw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYXbhvsw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "fs8VNxYEBE-yei6qXVno-Q",
            "name": "dark / tertiary-lighted / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYS7eMpA"
                    }
                ]
            }
        },
        {
            "id": "QMNbc2r_m0WgLcPCqvHsDw",
            "name": "dark / tertiary-lighted / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvWp9vWxnGREz3w"
                    }
                ]
            }
        },
        {
            "id": "r_esS5-Kd0aaFcrBFxnJ8w",
            "name": "dark / tertiary-lighted-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSSOTrfhvGpvjRLvvvy7RA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "qrR59GLtOkST_YoEIFLcKw",
            "name": "dark / tertiary-lighted-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWpOWJxcHidATkw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "xywfwW3e9kmE5xe-KaHdLg",
            "name": "dark / tertiary-lighted-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWpOWJxcHidATkw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZm0PnqOLa7BBYXbhvsw",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "jkVLeWK78kWS37yi0Ok3Ig",
            "name": "dark / tertiary-lighted-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIscJP1T6o0uv4ZnWTQFw"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSSOTrfhvWp9vWxnGREz3w",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "FdZWilphTECtoYMnzi5-7g",
            "name": "dark / tertiary-lighted-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSbwOBwR-Op_gN_hmWWnWA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSYk6Q_3FMiJyS7563Kn8g",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "0XSuKhRDkES9XMSCSb4bqw",
            "name": "dark / transparent / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "scHjV5bAGkedbeUlnOOm5A",
            "name": "dark / transparent / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "AMzq86jJz0unTlCJUQ86uw",
            "name": "dark / transparent / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "OsSIl8SZXUeRAWPYQJm9tA",
            "name": "dark / transparent / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSIsXZMjzsxFfkqcMsvIWQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "NQpQw_FdB0yB5LtMK9bL4w",
            "name": "dark / transparent / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPC8GrWBPLzXXU9h5bV2Q"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Ef19c7bzsUigTPlJV5tkCg",
            "name": "dark / transparent-mild / disabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "p4sk_53mRkaYbQaXpaIu-Q",
            "name": "dark / transparent-mild / enabled",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "Fo3p6hEahUqw8GH5T3tTrg",
            "name": "dark / transparent-mild / focused",
            "style": {
                "fills": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZi0LR_FTPtf_blRefTA",
                        "pos": 1,
                        "thickness": 2
                    }
                ]
            }
        },
        {
            "id": "jy4Q329W-0im3-AExRNreA",
            "name": "dark / transparent-mild / hovered",
            "style": {
                "fills": [
                    {
                        "colorId": "gSL3pl77HW2kCdwCAj-sUg"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "lF85ZnrQDku_Tznh60WiOQ",
            "name": "dark / transparent-mild / pressed",
            "style": {
                "fills": [
                    {
                        "colorId": "gSe7dWaXYFs7rxHKhNyjDA"
                    }
                ],
                "borders": [
                    {
                        "colorId": "gSVZtkW7O0LEaZ3IGRxNRQ",
                        "pos": 1
                    }
                ]
            }
        },
        {
            "id": "f_ScsGRiEUqE4wLtACRjPQ",
            "name": "light / surface / highest elevation 1",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "shadows": [
                    {
                        "radius": 1,
                        "offset": [
                            0,
                            1
                        ],
                        "color": "05000000",
                        "opacity": 0
                    },
                    {
                        "radius": 1,
                        "offset": [
                            0,
                            1
                        ],
                        "color": "14000000",
                        "opacity": 0
                    },
                    {
                        "radius": 1,
                        "color": "24000000",
                        "opacity": 0
                    }
                ]
            }
        },
        {
            "id": "l8MZZZvMX0y9vbOOW_ZOwQ",
            "name": "light / surface / highest elevation 2",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "shadows": [
                    {
                        "radius": 7,
                        "offset": [
                            0,
                            17
                        ],
                        "color": "03000000",
                        "opacity": 0
                    },
                    {
                        "radius": 6,
                        "offset": [
                            0,
                            9
                        ],
                        "color": "0A000000",
                        "opacity": 0
                    },
                    {
                        "radius": 4,
                        "offset": [
                            0,
                            4
                        ],
                        "color": "12000000",
                        "opacity": 0
                    },
                    {
                        "radius": 2,
                        "offset": [
                            0,
                            1
                        ],
                        "color": "14000000",
                        "opacity": 0
                    }
                ]
            }
        },
        {
            "id": "Ju0HAFkTwUuguQyAU86suQ",
            "name": "light / surface / highest elevation 3",
            "style": {
                "fills": [
                    {
                        "colorId": "gSPDA2vjWZOJidFrylALuw"
                    }
                ],
                "shadows": [
                    {
                        "radius": 14,
                        "offset": [
                            0,
                            35
                        ],
                        "color": "03000000",
                        "opacity": 0
                    },
                    {
                        "radius": 12,
                        "offset": [
                            0,
                            20
                        ],
                        "color": "0D000000",
                        "opacity": 0
                    },
                    {
                        "radius": 9,
                        "offset": [
                            0,
                            9
                        ],
                        "color": "17000000",
                        "opacity": 0
                    },
                    {
                        "radius": 5,
                        "offset": [
                            0,
                            2
                        ],
                        "color": "1A000000",
                        "opacity": 0
                    }
                ]
            }
        },
        {
            "id": "eGEm9cBBfUmMY4pjKB6Mhg",
            "name": "none",
            "style": {}
        },
        {
            "id": "ubk12AHT30WKqg_Ax1WRwA",
            "name": "light / transparent / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 44
            }
        },
        {
            "id": "fZjw-8mgi0yrNEcD_yy-hA",
            "name": "light / neutral / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 36
            }
        },
        {
            "id": "HQ_xMCNX4U-8eHZ9Ga-Dxw",
            "name": "light / error-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 32
            }
        },
        {
            "id": "dKTnD4nK20SUIue_7r48Vg",
            "name": "light / error / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "7YambhSYsU6tRDGlWMxGYg",
            "name": "light / secondary-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "9Bye7NxJK0SUVzF87BmdlA",
            "name": "light / primary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 40
            }
        },
        {
            "id": "GFajc2vHjU2nYryCmLNYyA",
            "name": "light / neutral / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 32
            }
        },
        {
            "id": "1Bi31HEzsEynnLGm0rM_wg",
            "name": "light / tertiary-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 20
            }
        },
        {
            "id": "E-U22X6XL0emRbqpETyh-A",
            "name": "light / surface / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "VUMLnPXOb0qVhA8mGBEoAw",
            "name": "light / disabled-on-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 44
            }
        },
        {
            "id": "BRGt79b8mkyYmGrAgkIJcQ",
            "name": "light / error / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 28
            }
        },
        {
            "id": "mrgzhX7750u8ssW0yKUrZA",
            "name": "light / error-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 24
            }
        },
        {
            "id": "Fqy-aAMkxEuF3lSbmVlDaQ",
            "name": "light / neutral / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "gRhdjva-y0aOwLE1Z2p5cg",
            "name": "light / primary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 20
            }
        },
        {
            "id": "MjPFp8JOzUyTNV0_jrjpQg",
            "name": "light / error / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 24
            }
        },
        {
            "id": "a7i1NsljDk-92Gb4DrUOSQ",
            "name": "light / error / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 24
            }
        },
        {
            "id": "TQuT5ozPT0yLVNaPKEwmew",
            "name": "light / invert-primary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 44
            }
        },
        {
            "id": "GzhVwlcsUU6Ej7JgoIob3g",
            "name": "light / neutral-bright / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "9W3_iu-nsE2EgjMeXaih4A",
            "name": "light / error-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 20
            }
        },
        {
            "id": "zre23SsZhEGnPew04JN5nA",
            "name": "light / disabled-on-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 20
            }
        },
        {
            "id": "yVnW2kl460yFErZOJl9vPQ",
            "name": "light / secondary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 32
            }
        },
        {
            "id": "w-3JI9xe7US0jEccPiNl5w",
            "name": "light / secondary-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 36
            }
        },
        {
            "id": "PWlEXVSbREKExDWcwyfNKg",
            "name": "light / tertiary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 40
            }
        },
        {
            "id": "dNmqPkWqSE-8AFGirAr8yg",
            "name": "light / transparent / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 28
            }
        },
        {
            "id": "zJ6BQuez_Um2_7dPKZouNw",
            "name": "dark / secondary-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 28
            }
        },
        {
            "id": "MICDdkBHokyEEHRC2sx9hA",
            "name": "light / disabled-on-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 24
            }
        },
        {
            "id": "iKw9IaJBVkemM0vL3PQJRw",
            "name": "light / error-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 20
            }
        },
        {
            "id": "YB4Zsjna4U6Mjj6Ws-kR-w",
            "name": "light / invert-primary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "enOZidQzukeHfF87SkE3nA",
            "name": "light / neutral-bright / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 44
            }
        },
        {
            "id": "XIblIwtaiUufxgG_QSMGxA",
            "name": "light / secondary-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "6nMo8IJPVkyFwxVvjy7U7w",
            "name": "light / secondary-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 24
            }
        },
        {
            "id": "ZY_v4-GpnUa3z2ly5GEOBA",
            "name": "light / tertiary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 20
            }
        },
        {
            "id": "yOa1gOVNEUSWQVT21-lAQA",
            "name": "light / disabled-on-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 44
            }
        },
        {
            "id": "gBKUoaaIlE-kFZtiDOY1Jg",
            "name": "light / error / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Jpv7I0pyx06ZULZYkAJC7Q",
            "name": "light / secondary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 24
            }
        },
        {
            "id": "CvjhELgh70WwDzwWnwZZfw",
            "name": "light / secondary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 24
            }
        },
        {
            "id": "E4FjjOysHECOq-R7dGYv2w",
            "name": "light / tertiary-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 44
            }
        },
        {
            "id": "IVl4Ttt0XUekMzQWqB2H6Q",
            "name": "light / transparent-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "7hDig8FSNkOAYsLOkX0OZA",
            "name": "light / error-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 36
            }
        },
        {
            "id": "Wb8zOwNopkKq_jSR0NheOg",
            "name": "light / neutral / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 40
            }
        },
        {
            "id": "NbQiciBU2U6pv7rds6MgsA",
            "name": "light / secondary-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 28
            }
        },
        {
            "id": "OINudRlruE-tPsXmYWAgvQ",
            "name": "light / primary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 40
            }
        },
        {
            "id": "13acS5WM3E6xlTOW_Z19mw",
            "name": "light / tertiary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 24
            }
        },
        {
            "id": "Sz9_vG0Hvkmm-2ruArJcRA",
            "name": "light / tertiary-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 20
            }
        },
        {
            "id": "FzzLX3DEQE-kCuuPKp1oMw",
            "name": "light / transparent-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "1C0N_HD8k02eOPvjusQAsg",
            "name": "light / disabled-on-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 20
            }
        },
        {
            "id": "fXuxjADEIkm-YkWkTvQFuw",
            "name": "light / error / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 44
            }
        },
        {
            "id": "ZR-MNhpOfECv2mWryJAm3g",
            "name": "light / error-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "tGmvpcVtsUSvPvSbcR0UfA",
            "name": "light / neutral / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 32
            }
        },
        {
            "id": "XlNJVJk7Qkma3WSKNk4Orw",
            "name": "light / secondary-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 24
            }
        },
        {
            "id": "Fs_RVZxwv0a5k_YStaSZAg",
            "name": "light / tertiary-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 28
            }
        },
        {
            "id": "L7RTzIG9GUmVhc24rM_1Cg",
            "name": "light / transparent / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "p0D9GC01TUaIo8VGtM28jg",
            "name": "light / error-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 20
            }
        },
        {
            "id": "as5l_vB1hkKvpEVjG8518g",
            "name": "light / invert-primary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 44
            }
        },
        {
            "id": "sLFEHi7HPE6kZvXgjKVs0g",
            "name": "light / secondary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 40
            }
        },
        {
            "id": "V-4KARsUNk2JprE7kOrQPw",
            "name": "light / surface / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 28
            }
        },
        {
            "id": "wLfLxFFUiUeqlqG45af3dQ",
            "name": "dark / secondary-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 20
            }
        },
        {
            "id": "N791wXrmO0qMaCPikPQl5A",
            "name": "light / disabled-on-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 20
            }
        },
        {
            "id": "Rk2iS3ArgkmEXP25us1PCg",
            "name": "light / neutral-bright / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 32
            }
        },
        {
            "id": "w6YfDDi4Bk2TQQu08HBDXQ",
            "name": "light / primary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 24
            }
        },
        {
            "id": "54ZuV6pdwE20eMJJKgLzyQ",
            "name": "light / primary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 44
            }
        },
        {
            "id": "F6yZwoC_oEy45a5Newl46g",
            "name": "light / primary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "hOSMZHRJc0ONGvt0FOZ2hQ",
            "name": "light / secondary-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 32
            }
        },
        {
            "id": "fPeFfX7J30OXJ1Y8p6K3vw",
            "name": "light / secondary-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 36
            }
        },
        {
            "id": "2czP3-HRjkCULkyqI043jw",
            "name": "light / primary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 32
            }
        },
        {
            "id": "EjG2yk1RgkaWs1wJ_lEcfQ",
            "name": "light / secondary-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 40
            }
        },
        {
            "id": "jpI4ovWqT0ui3eIEztvORg",
            "name": "light / surface / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 36
            }
        },
        {
            "id": "Q5DugoK74E-AhPhq9IbGJg",
            "name": "light / surface / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 40
            }
        },
        {
            "id": "ILrTHOuyPEOOCgY9Ns0Y6g",
            "name": "light / tertiary-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 40
            }
        },
        {
            "id": "hh2JhAJGSEKcB80aboLvBg",
            "name": "light / tertiary-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 28
            }
        },
        {
            "id": "LKqbN5I9Fk6REEUmIPVD8A",
            "name": "light / surface / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 28
            }
        },
        {
            "id": "Vzrv6ucx-0iIQDtqXgwa2A",
            "name": "light / tertiary-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 24
            }
        },
        {
            "id": "sePwgmwLrUuOIv_xNqogJQ",
            "name": "light / transparent / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 28
            }
        },
        {
            "id": "6l2JZrIvIUOI6VzMqjIWhw",
            "name": "light / transparent / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "fkQQfIXy0EytNarQKlm_fg",
            "name": "light / disabled-on-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 28
            }
        },
        {
            "id": "jLqPGkNlM0igRVPg8_1tFw",
            "name": "light / disabled-on-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 24
            }
        },
        {
            "id": "z3jkppTnpU2Bl-Prww7Gvw",
            "name": "light / transparent / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "VV1uA0bsvku20gqyGrutoA",
            "name": "light / disabled-on-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 20
            }
        },
        {
            "id": "s68vzhNII0eGcsCs-3Zv2g",
            "name": "light / error / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 24
            }
        },
        {
            "id": "J6m5Q73l-UOiP1BwGJc3zA",
            "name": "light / error / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 20
            }
        },
        {
            "id": "dyRR1vLo9ki8ApMrtfl0NQ",
            "name": "light / error-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 20
            }
        },
        {
            "id": "PKU5tE-ph0W_em2KzCN5ew",
            "name": "light / error-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 20
            }
        },
        {
            "id": "yn2QPYqbekC07EsFx7NZcA",
            "name": "light / error / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 20
            }
        },
        {
            "id": "TA17JjdI40OhqqynSgiG2g",
            "name": "light / error-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 44
            }
        },
        {
            "id": "gJO6VGReOESfh7XLTDSAWA",
            "name": "light / neutral / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "696z4vqSo0GnOeKr0Vi39Q",
            "name": "light / neutral / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 44
            }
        },
        {
            "id": "8Qncwvcf1UaibxAobsTXeg",
            "name": "light / primary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 36
            }
        },
        {
            "id": "TnbjnZlYzkO0oYlyU8NMVA",
            "name": "light / secondary-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 28
            }
        },
        {
            "id": "UaPnhcFAakOVVjJCet67FQ",
            "name": "light / neutral / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "rqt6cLa8vEmwtXSH_A6gVw",
            "name": "light / surface / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "pn2K8GagCkyheyPQ-qDoTg",
            "name": "light / tertiary-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 20
            }
        },
        {
            "id": "ZNwRysvIbUa7_vn2Dj_XHA",
            "name": "light / transparent / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "w8aJNiL-30SPkfuK9FMQHA",
            "name": "light / error / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 44
            }
        },
        {
            "id": "dDG2oR5JUEqf28FrPgrKYw",
            "name": "light / error-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "DWt5zePApky4rTXx0hw7CQ",
            "name": "light / disabled-on-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 20
            }
        },
        {
            "id": "KN0XnNUJt0SP5PtCdTj-dg",
            "name": "light / disabled-on-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 24
            }
        },
        {
            "id": "7FMNKBYCGkG-CtoFilCO5Q",
            "name": "light / disabled-on-bright / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 24
            }
        },
        {
            "id": "T8Y7WuNGKEKnvTIvO8uNeA",
            "name": "light / disabled-on-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 24
            }
        },
        {
            "id": "DMnlxSEiqkSVUFBY8eVdlw",
            "name": "light / disabled-on-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 24
            }
        },
        {
            "id": "Kc9YgijVAk23SWr3yQPvVw",
            "name": "light / disabled-on-bright / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 40
            }
        },
        {
            "id": "fr6-_9u5xEefTM5LRIo0Kg",
            "name": "light / disabled-on-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 24
            }
        },
        {
            "id": "1rDietJYiky3TJcBoW3-ig",
            "name": "light / disabled-on-bright / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 36
            }
        },
        {
            "id": "4loqukbAhkey8Ap2LMWOfw",
            "name": "light / disabled-on-bright / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 32
            }
        },
        {
            "id": "7FE3vtEyakKrlxMpsxnaxw",
            "name": "light / disabled-on-bright / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "ok5mhUDDjUSTUBj0l4PyXA",
            "name": "light / disabled-on-bright / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 20
            }
        },
        {
            "id": "Rbdb_qLN_EeW0Vz-Crmkow",
            "name": "light / disabled-on-bright / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 20
            }
        },
        {
            "id": "e04mlLaOHU2s2_F0l93eyg",
            "name": "light / disabled-on-bright / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 44
            }
        },
        {
            "id": "uTvdYEJt-kCHFZuCqDLYew",
            "name": "light / disabled-on-bright / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 24
            }
        },
        {
            "id": "iSacAlyi5UqrVAA_w7tsUw",
            "name": "light / disabled-on-bright / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 28
            }
        },
        {
            "id": "YwKAZpHY50O0qCITOlNi-w",
            "name": "light / disabled-on-bright / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 24
            }
        },
        {
            "id": "IJjilES_8kWX457ZbDkl6g",
            "name": "light / secondary-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 40
            }
        },
        {
            "id": "c3Oz01At0ECxLF4CaaGvCg",
            "name": "light / tertiary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 28
            }
        },
        {
            "id": "bNNf4fpLBE6esSTitNtS-g",
            "name": "light / secondary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 36
            }
        },
        {
            "id": "c2QguTIDdkOZQSh2hOBKsw",
            "name": "light / tertiary-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 24
            }
        },
        {
            "id": "F0uCRShzBES3jgW-Hfkxwg",
            "name": "light / transparent-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "gxcOBgGbcUan8j-uJVl1PQ",
            "name": "light / secondary-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "IAQDdG2huUiRfOfrW2FmHA",
            "name": "light / tertiary-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 36
            }
        },
        {
            "id": "td8_yUj3UkqOblF_OxrgYA",
            "name": "light / transparent / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 40
            }
        },
        {
            "id": "ftbaEkHkbUWdAS5cTR_5dw",
            "name": "light / surface / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 32
            }
        },
        {
            "id": "JxMy1PBHqkejw3bwMgdEuw",
            "name": "dark / secondary-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 40
            }
        },
        {
            "id": "kWk9wzcJBkqARG2gHiUtQA",
            "name": "light / tertiary-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 20
            }
        },
        {
            "id": "hK_Jsy9nkUSk2j3dj9_mGQ",
            "name": "light / secondary-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 20
            }
        },
        {
            "id": "ISMcqAIrmEKUuq0V_G6_7A",
            "name": "light / transparent / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "BJeuhi4LuEyynSI_6rVP4A",
            "name": "light / tertiary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 44
            }
        },
        {
            "id": "rfcB0u6oeUK8cGmhZhH3qg",
            "name": "dark / secondary-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 20
            }
        },
        {
            "id": "xmKLq3qjgkyM4dgV9nswZw",
            "name": "light / tertiary-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "L6pEWG6I4UuCfhhVLsh7DA",
            "name": "light / disabled-on-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 44
            }
        },
        {
            "id": "QxsYIG5DXUWdKs6LRowknA",
            "name": "light / transparent-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 32
            }
        },
        {
            "id": "oQhoscOe5EuucEFerVSJQQ",
            "name": "light / error-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "y0r3iDfhnkC9VERqa9ed1Q",
            "name": "light / disabled-on-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 32
            }
        },
        {
            "id": "KJHRUdfanUiJH1ppEocvkA",
            "name": "light / invert-primary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 32
            }
        },
        {
            "id": "f2DO2rw3ukKY-VD7DBGyuw",
            "name": "light / error / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 36
            }
        },
        {
            "id": "Djbnu1Mi1EyJSHA93vJhCw",
            "name": "light / neutral-bright / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 36
            }
        },
        {
            "id": "Ex9_VjZJFEiYkLhcWkUiPQ",
            "name": "light / error-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 40
            }
        },
        {
            "id": "vAZ71Gz5KUCquUbc8-41Wg",
            "name": "light / neutral-bright / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "beHxPrqIdkKMoNByeqOqdA",
            "name": "light / neutral-bright / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 28
            }
        },
        {
            "id": "2wa-yb2FWEOXkc6JiNoj_w",
            "name": "light / secondary-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 24
            }
        },
        {
            "id": "BgDmPO3rB0OCXWL49kIoFA",
            "name": "light / neutral-bright / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "3y1QRmP_5Ey_nZKYvLI3dA",
            "name": "light / surface / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "pgDK-I2hL0idEtDiHjBDGw",
            "name": "light / secondary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 20
            }
        },
        {
            "id": "NcboOD0IiUOCTeWkQzKx2Q",
            "name": "light / tertiary-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 20
            }
        },
        {
            "id": "3Uu2taj49kOKKV86VEVsAA",
            "name": "light / secondary-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 44
            }
        },
        {
            "id": "YKCB74DYc06p-fqsjtg7Rg",
            "name": "light / transparent / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 44
            }
        },
        {
            "id": "E5xXiujF20CEpXW7LJuWVQ",
            "name": "light / tertiary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "svR9ArWz6U2Ya8VJSVrwiw",
            "name": "dark / secondary-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 44
            }
        },
        {
            "id": "HGI6JZkVZE6FX2C8UTXYSQ",
            "name": "light / tertiary-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 32
            }
        },
        {
            "id": "vMdrA46CEkaIDdP0kG4LuQ",
            "name": "light / disabled-on-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "3MItSZrcVEqyTkK8pfeZKA",
            "name": "light / transparent-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 36
            }
        },
        {
            "id": "gOHHC5YZn0iQiW6T5Q2Rvg",
            "name": "light / error-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 32
            }
        },
        {
            "id": "4K6M2nTQeEeD9MgQaYKfQQ",
            "name": "light / disabled-on-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 36
            }
        },
        {
            "id": "j_z_4NuKl0-CE-SMOJfWaA",
            "name": "light / invert-primary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 36
            }
        },
        {
            "id": "lkg6FavO4kGBM62Tr5Rcuw",
            "name": "light / error / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 40
            }
        },
        {
            "id": "fn5eNP2fX0yEDeaV-wVsnQ",
            "name": "light / neutral-bright / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 40
            }
        },
        {
            "id": "BI-6qhp8qk-ZdiZdHYas8g",
            "name": "light / invert-primary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 28
            }
        },
        {
            "id": "GF3wmefebUeqU_rkPO7cQw",
            "name": "light / neutral / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "qITBohnu9kicksRZvTwKNw",
            "name": "light / neutral-bright / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "FF043zGJ20WpTuSbliwZqQ",
            "name": "light / secondary-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 20
            }
        },
        {
            "id": "rHqFTsVv8U-o3hLNsrUqwA",
            "name": "light / neutral / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "9XI4hWlgfECOM9nk-jHUAA",
            "name": "light / surface / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "YxsQoByIkUyexsC63x2OPw",
            "name": "light / secondary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 44
            }
        },
        {
            "id": "QPx6qBUGr0Sg0pq_abD2BQ",
            "name": "light / secondary-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "gyNCczeSQkaC0gEujWDp-w",
            "name": "light / tertiary-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 44
            }
        },
        {
            "id": "WdC5GJ-mkUGA0fqyq-QP9Q",
            "name": "light / tertiary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 32
            }
        },
        {
            "id": "3G5H5Ncj102H-Tyw41YRIw",
            "name": "light / transparent / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "FqPQ4bOzI0KjpEgWDK5WLQ",
            "name": "light / tertiary-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 36
            }
        },
        {
            "id": "tgwdRAEYc0a8cOTFfwg4PQ",
            "name": "dark / secondary-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "HGn14Ib86UOAyVD3wpfmwA",
            "name": "light / transparent-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 40
            }
        },
        {
            "id": "ApLijkLUSUWgvXFNTyTPvw",
            "name": "light / disabled-on-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 32
            }
        },
        {
            "id": "VUdlABzXnkqcDI50WU9mmA",
            "name": "light / disabled-on-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 40
            }
        },
        {
            "id": "RhMwYcz_x02n0ndzo71cSQ",
            "name": "light / error-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 36
            }
        },
        {
            "id": "oCPVfHEj_UW0Er9qmMFdZg",
            "name": "light / error-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 28
            }
        },
        {
            "id": "wWY6lndg9kuRmZc-PP1S-Q",
            "name": "light / invert-primary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 40
            }
        },
        {
            "id": "-HT7IjBH6Eug1n0ihC2dvw",
            "name": "light / invert-primary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 24
            }
        },
        {
            "id": "bh7-jglwuEqZJVW9ajid0g",
            "name": "light / primary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 28
            }
        },
        {
            "id": "QbFQBYhCT0OtRT2AZBPlYg",
            "name": "light / neutral-bright / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "9M-YnWSVPUy45ed4tfIMxw",
            "name": "light / invert-primary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "T6-MnUTe7kCyrtLFRSL8hQ",
            "name": "light / invert-primary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 24
            }
        },
        {
            "id": "tlrbM1gBqUaLcEzm3VvLKQ",
            "name": "light / secondary-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 20
            }
        },
        {
            "id": "Jf7Ao8dMrE270zziYQ28Rw",
            "name": "light / secondary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "dxcDdOwVCE2MfKm71MWJTA",
            "name": "light / surface / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 44
            }
        },
        {
            "id": "CYt3mnQLH0-vtpnC3yaFrg",
            "name": "light / secondary-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 32
            }
        },
        {
            "id": "OeTT7hndY0SHgb_wCb7ofg",
            "name": "light / tertiary-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "YiSuoEeSCE2FT6VDHQEmhw",
            "name": "light / tertiary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 36
            }
        },
        {
            "id": "blariEdFx0KjaFYfKSIl6g",
            "name": "light / transparent / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 32
            }
        },
        {
            "id": "Thp2BTwe5UWsfQ9JIWJuAw",
            "name": "light / tertiary-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 40
            }
        },
        {
            "id": "DM8AvOfP30G_FDS1i4QrFw",
            "name": "dark / secondary-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 32
            }
        },
        {
            "id": "A8ggDracQkeiBeIPZDWHMg",
            "name": "light / transparent-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 28
            }
        },
        {
            "id": "BZ0OC5pG50S6RKKZtXA6Pg",
            "name": "light / disabled-on-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 36
            }
        },
        {
            "id": "fpfId5beUUCMd2tXQSrSiw",
            "name": "light / disabled-on-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 28
            }
        },
        {
            "id": "pN7xZhTmBUOic6Z0b8FERg",
            "name": "light / error-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 40
            }
        },
        {
            "id": "hsbkr-ou6EOso4d12alicg",
            "name": "light / error-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 24
            }
        },
        {
            "id": "eSbiUBMFRkuzfiDWmKaF7A",
            "name": "light / neutral / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 28
            }
        },
        {
            "id": "uER2yrmPkkKihd0AAF9zwA",
            "name": "light / invert-primary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 20
            }
        },
        {
            "id": "1bSwj2ZOOEGGkmCQt8CQ2Q",
            "name": "light / primary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 24
            }
        },
        {
            "id": "E0fcjioZlEqGyqwV75gaOw",
            "name": "light / neutral-bright / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "mQ7ThvbDlEKuXMlF6JH_SA",
            "name": "light / error-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 24
            }
        },
        {
            "id": "s4PYtkBnR0SdMzVcvtj54A",
            "name": "light / error-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 24
            }
        },
        {
            "id": "tdP9zaYZBkyFq2QELjHHVw",
            "name": "light / secondary-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 44
            }
        },
        {
            "id": "0ViOs6wdGE2pJWbkFzhIww",
            "name": "light / secondary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 32
            }
        },
        {
            "id": "W0z7zXq35EWSNpCLnCu8lQ",
            "name": "light / surface / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "62DS_sIISkKh2u0SpcN4kw",
            "name": "light / secondary-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 36
            }
        },
        {
            "id": "YxFtueoZUkGSLrFMsmar2g",
            "name": "light / tertiary-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 32
            }
        },
        {
            "id": "BzELLMXtwEWjlBQlsKdKxw",
            "name": "light / tertiary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 40
            }
        },
        {
            "id": "q3X2pW9zgE6e0fI9-tEkXw",
            "name": "light / transparent / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 36
            }
        },
        {
            "id": "SaVDXR-JG0ysaazwl6Iurw",
            "name": "light / tertiary-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 28
            }
        },
        {
            "id": "VcwF624u20iwqgqTZ7tJzw",
            "name": "dark / secondary-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 36
            }
        },
        {
            "id": "B2WM4ljdm0Gb5ORpEEA2Zg",
            "name": "light / transparent-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "jeSM_YXnPE2qVrAD36NM8Q",
            "name": "light / disabled-on-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 40
            }
        },
        {
            "id": "K_47nJnbuEOjzj2DdU8NnQ",
            "name": "light / disabled-on-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 24
            }
        },
        {
            "id": "ZW7Su5dhrEKfqJOj6bW-5w",
            "name": "light / error-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 28
            }
        },
        {
            "id": "0_cCTX-_rEOeuZEBDFZQIQ",
            "name": "light / error-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 20
            }
        },
        {
            "id": "nfTw-R8FGUuu1cio0ds_xQ",
            "name": "light / neutral / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "FJ9XCcucG0OSe6YBBQItgg",
            "name": "light / invert-primary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 20
            }
        },
        {
            "id": "lWGW-7I4P0SxRq1Q21ah-g",
            "name": "light / primary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 20
            }
        },
        {
            "id": "l-poORO24E2SytCdap4k9g",
            "name": "light / neutral-bright / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 44
            }
        },
        {
            "id": "_4qMuJoaIEqmLr_pm6tyYw",
            "name": "light / error-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 24
            }
        },
        {
            "id": "jNB9F9Jej0Grp1MAzLW8OA",
            "name": "light / error-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 24
            }
        },
        {
            "id": "0xGMbBNm9U-SmvL7a9bSTA",
            "name": "light / secondary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 36
            }
        },
        {
            "id": "LOdahZfBW06TApacteePGA",
            "name": "light / secondary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 28
            }
        },
        {
            "id": "sdKEDCpw8EGLGVYgy-1nVw",
            "name": "light / secondary-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 40
            }
        },
        {
            "id": "35BiudWSskmPsMBjtsU_kw",
            "name": "light / tertiary-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 20
            }
        },
        {
            "id": "maiu-FtCR0OEycETo1z8jQ",
            "name": "dark / secondary-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 24
            }
        },
        {
            "id": "69XJcfPIiky4318dTtXZZA",
            "name": "light / transparent-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 44
            }
        },
        {
            "id": "5Xaa8AHd0kCZ_1C9a4r1sw",
            "name": "light / disabled-on-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 20
            }
        },
        {
            "id": "7WL4X5brzkSbXFYJpO-DDg",
            "name": "light / error-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 32
            }
        },
        {
            "id": "ZBia6cdbSkWdeRG1HdMyQQ",
            "name": "light / neutral-bright / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "c9raExUtVkGk42seUke68g",
            "name": "light / neutral / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 36
            }
        },
        {
            "id": "kk_cCl6S1EaixR0eVv6cjA",
            "name": "light / secondary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 24
            }
        },
        {
            "id": "1MKbuzuvaEOOE1bB2NQBEw",
            "name": "light / secondary-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 20
            }
        },
        {
            "id": "RcBToqkm00ygsLLrR6D_KQ",
            "name": "light / tertiary-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 24
            }
        },
        {
            "id": "FCdwTo8nokKfnp944pcZig",
            "name": "light / tertiary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 20
            }
        },
        {
            "id": "jnusNeGcJUi0BvgtH7tLEQ",
            "name": "light / transparent / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "cKDrFaU6nEuc9xbl440lbQ",
            "name": "light / disabled-on-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "k8IthY0ufkSYPuSVzt1T2g",
            "name": "light / error-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 44
            }
        },
        {
            "id": "v23FORuQCUaPFSxNNN8_Gw",
            "name": "light / error / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 32
            }
        },
        {
            "id": "NWTA1QBekkmBKoDngFhbBA",
            "name": "light / invert-primary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "SsGHlWcpWEGhyzTYNnQHPQ",
            "name": "light / primary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC7mq6AESMmEmzRLTbyQ",
                "lineHeight": 24
            }
        },
        {
            "id": "EczmHqSK_kOZRjd4PVP5xA",
            "name": "light / secondary-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 28
            }
        },
        {
            "id": "5IfWCBSHik6nvXz9PeGHvg",
            "name": "light / secondary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 20
            }
        },
        {
            "id": "Eo4gEDVpwE-Pmz65CC2TaA",
            "name": "light / surface / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "67mnqybjWUyrQqNmmGbGpQ",
            "name": "light / secondary-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSVZhkJ8LLeRiodOjg1abw",
                "lineHeight": 24
            }
        },
        {
            "id": "Gz-tZqFMA0i_luvobvzh2g",
            "name": "light / secondary-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "gTTJXxCFIUWYBKjGtcFSyQ",
            "name": "light / neutral / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "e38xzJNoTE2fD5wD9cnFjQ",
            "name": "light / neutral-bright / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "Y7yqZZ3WVES72aw0MdJc0A",
            "name": "light / error-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 44
            }
        },
        {
            "id": "-meuE22Ah0uOMIYSfYGzAQ",
            "name": "light / invert-primary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "t_pbqoFunUmNWdtq03X2SA",
            "name": "light / error / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 20
            }
        },
        {
            "id": "KGlKuR821EWKjoL7YuGnRw",
            "name": "light / error-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 24
            }
        },
        {
            "id": "IUFykl6Th0usdSP7cYF_jQ",
            "name": "light / disabled-on-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 20
            }
        },
        {
            "id": "YsuLln4s0EKj877HcocrNA",
            "name": "light / disabled-on-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 28
            }
        },
        {
            "id": "lv5XLR77BkOnmkHhmVJMyw",
            "name": "light / transparent-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 20
            }
        },
        {
            "id": "8UvhHttPrE6CE5926dbq9A",
            "name": "dark / secondary-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 24
            }
        },
        {
            "id": "-QgAkEk-EES1GIWQsLSavw",
            "name": "light / tertiary-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 24
            }
        },
        {
            "id": "akwwtMZ9tkWcYkLPahZLjA",
            "name": "light / tertiary-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 40
            }
        },
        {
            "id": "P0kDjMATokWACxoUsEGiQQ",
            "name": "light / tertiary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 28
            }
        },
        {
            "id": "BnFnFLsk0USrd3kYTYKgSA",
            "name": "light / tertiary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 36
            }
        },
        {
            "id": "QGf6O8Km50KUs5iZF_oFNQ",
            "name": "light / secondary-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 40
            }
        },
        {
            "id": "OsvL96Q74U28R1PIwtGQ_w",
            "name": "light / secondary-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 32
            }
        },
        {
            "id": "mo5X7HyB3ESbLr-t1UAVBA",
            "name": "light / primary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 36
            }
        },
        {
            "id": "Zs0V_8pv_EixP9TTv0XAgA",
            "name": "light / secondary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "kvbsidy3LkSF20hDZPPc1w",
            "name": "light / surface / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "GB0BgSHbhUa8is_Txhdvsw",
            "name": "light / surface / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "VY47aUIplEG7ukdKsaT85Q",
            "name": "light / neutral / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 44
            }
        },
        {
            "id": "QJY7auoqwEuxvHjzJYxyWA",
            "name": "light / neutral-bright / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "n8FzBpNKeEq_gmG6lnTYVA",
            "name": "light / error-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 20
            }
        },
        {
            "id": "q61pRGV0hUCPwlauzGBJOg",
            "name": "light / invert-primary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "NGktGAj9A0eqhEUgH4EIpQ",
            "name": "light / error / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 20
            }
        },
        {
            "id": "WUHxTYjSBUiv6WvVH8LeMg",
            "name": "light / error-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 28
            }
        },
        {
            "id": "X3rpPkKcf0m4HvTs8Sk4KA",
            "name": "light / disabled-on-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 24
            }
        },
        {
            "id": "PPDlI5MnUki8ay-OV8pPZw",
            "name": "light / disabled-on-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 40
            }
        },
        {
            "id": "Wfi0MACG4kiUuIYr4qvrcg",
            "name": "light / transparent-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "w1X-_LKshEqan-bzZwmSIw",
            "name": "light / transparent-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 40
            }
        },
        {
            "id": "_7qqT_euL0yEGa6qe2ylnQ",
            "name": "light / tertiary-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 28
            }
        },
        {
            "id": "4LVu6vXLe0q6t1-BzGuJoA",
            "name": "light / tertiary-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 36
            }
        },
        {
            "id": "Fy1FmwaXxUOy3hTN5RT38g",
            "name": "light / surface / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 40
            }
        },
        {
            "id": "BiItAQfeykq021BwjMkChg",
            "name": "light / tertiary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 32
            }
        },
        {
            "id": "EoMOuaRO8kysvu5nbyP7DA",
            "name": "light / secondary-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 36
            }
        },
        {
            "id": "MBjS1OJybEWP18cEDEWtBw",
            "name": "light / secondary-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "V6ERW1eCs0GUIWdnIC9bLQ",
            "name": "light / primary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 32
            }
        },
        {
            "id": "eKMrNYqRmE2y7NzW5Sdo6g",
            "name": "light / secondary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 44
            }
        },
        {
            "id": "jmKd1iMXWUqN4hRwRlcMsQ",
            "name": "light / tertiary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHn9DjHw",
                "lineHeight": 24
            }
        },
        {
            "id": "5n8zUkzyFkGJLjyWEqKoUw",
            "name": "light / tertiary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 24
            }
        },
        {
            "id": "n3enS2dsBEmpIEBh0qTh9A",
            "name": "light / neutral / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "kB7nW5p9lEqYKtUNeAuYyQ",
            "name": "light / neutral-bright / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "dcMG1Ugoz06r0eQREr6O0g",
            "name": "light / error-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 20
            }
        },
        {
            "id": "W3WCXufkHUSca3KdPa0g5g",
            "name": "light / invert-primary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 28
            }
        },
        {
            "id": "OPp4JSJx70mF1RkUMqSvZw",
            "name": "light / error / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 24
            }
        },
        {
            "id": "Zj5mrfafcUyD0pRaMgnnfQ",
            "name": "light / error-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 40
            }
        },
        {
            "id": "5yJjhKTJI0ujddFdxhN8vQ",
            "name": "light / disabled-on-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 28
            }
        },
        {
            "id": "EMieSG9hwU2vvZA60nZ1yQ",
            "name": "light / disabled-on-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 36
            }
        },
        {
            "id": "zZMYXHYb-kWExBAwVA19Og",
            "name": "light / transparent-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 28
            }
        },
        {
            "id": "1eDUUeUcaUuWgxDY4hzSrw",
            "name": "light / transparent-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 36
            }
        },
        {
            "id": "ZGAWIq52YUSI-oDXHCpZAg",
            "name": "light / tertiary-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 40
            }
        },
        {
            "id": "clhSYNU5Ik2YWS5s-LTzTA",
            "name": "light / tertiary-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 32
            }
        },
        {
            "id": "sQkwqOvMGE6csHiQBTFA3w",
            "name": "light / surface / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 36
            }
        },
        {
            "id": "7W7ibuq-FU2ZluCtGy9EZw",
            "name": "light / tertiary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "bZH_s1UYBUqfNoK3kuYCzQ",
            "name": "light / secondary-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 32
            }
        },
        {
            "id": "tYOf3_xdLEizjUq7yC_a1Q",
            "name": "light / secondary-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 44
            }
        },
        {
            "id": "tNqHaB6wXkejxppJIiYD7g",
            "name": "light / primary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "AT4LHGpCzU-1YyvRMRdRUQ",
            "name": "light / secondary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 20
            }
        },
        {
            "id": "N310KuRWgkKoz5xU_lTEVQ",
            "name": "light / tertiary-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 24
            }
        },
        {
            "id": "6eS-31b6jE2kx7Y7RpF0OA",
            "name": "light / tertiary-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 24
            }
        },
        {
            "id": "-62-2g26jEe4BENlnBlgSw",
            "name": "light / neutral / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "xpRUIt98yESrvG2-fJUU5A",
            "name": "light / neutral-bright / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 28
            }
        },
        {
            "id": "HyMBxmrNE0KGPGANVkbz9A",
            "name": "light / error-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 24
            }
        },
        {
            "id": "mCR7fhD7vEegYc4Lp_kGkQ",
            "name": "light / invert-primary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 40
            }
        },
        {
            "id": "5MzcWZpHdkymEAdOFdXElw",
            "name": "light / error / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC9WsdJbSWvYpBZu6Tew",
                "lineHeight": 28
            }
        },
        {
            "id": "XytdjbCl-0-n8IWUyowpbw",
            "name": "light / error-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 36
            }
        },
        {
            "id": "y-6N1e19kUGELmtZlNghiA",
            "name": "light / disabled-on-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 40
            }
        },
        {
            "id": "vwOIYyrogUG73OEEmfV-Kg",
            "name": "light / disabled-on-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 32
            }
        },
        {
            "id": "SSAhJHWyEECSy-57tyjO9A",
            "name": "light / transparent / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 40
            }
        },
        {
            "id": "P9x8V7lEBUm6Cn_YbMvajw",
            "name": "light / transparent-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 32
            }
        },
        {
            "id": "6sZs44bY3E-MJIjxmVXOWA",
            "name": "light / tertiary-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 36
            }
        },
        {
            "id": "-dYclESU_0uuiuiCoxVZLg",
            "name": "light / tertiary-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "hIjWwyUmy0KHpgykCLsOwg",
            "name": "light / surface / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 32
            }
        },
        {
            "id": "Z1PLjcKrC0SivK7XV7zOGg",
            "name": "light / tertiary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 44
            }
        },
        {
            "id": "D33eip8SjUOxetV91ioipg",
            "name": "light / secondary-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Lb89K9QDHkCI1oZ-S2fBgA",
            "name": "light / secondary-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "uk7CjjiYPUGlVflDaOHddQ",
            "name": "light / primary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 44
            }
        },
        {
            "id": "I36Wqq3DT0mNua65JDafIA",
            "name": "light / secondary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 20
            }
        },
        {
            "id": "ylj2S3CsFEqBxE523Bithg",
            "name": "light / tertiary-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSVZm0PnqOLa7BBYS7eMpA",
                "lineHeight": 24
            }
        },
        {
            "id": "YYDNzCT1BkixcS2kVlmBKQ",
            "name": "light / tertiary-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 24
            }
        },
        {
            "id": "ThGPs_eKC0OGHLuAGrXt1Q",
            "name": "light / neutral / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "n83s6JVGgEmauHrtvJWagw",
            "name": "light / neutral-bright / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 40
            }
        },
        {
            "id": "bqthT8aGHkGFQAoIiKYzTA",
            "name": "light / error-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOQLcFfVN5GNONx5OIdA",
                "lineHeight": 28
            }
        },
        {
            "id": "WvVIsO0Ark2CP4DGmIv--Q",
            "name": "light / invert-primary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 36
            }
        },
        {
            "id": "kBBWMfVSvUeJ-3irXnmZhA",
            "name": "light / error / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 40
            }
        },
        {
            "id": "TqdFF0F2CEGR7e_lnpdmXw",
            "name": "light / error-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 32
            }
        },
        {
            "id": "hn2g1rmtuEC0Ys8FvY87wg",
            "name": "light / disabled-on-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 36
            }
        },
        {
            "id": "K3sJ2_DmaE2oOP9M9c4y2A",
            "name": "light / disabled-on-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "quiDP2ppVkeyeV-njroA1Q",
            "name": "light / transparent / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 36
            }
        },
        {
            "id": "qUvskFFw_UmXghwpyQalFw",
            "name": "light / transparent-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "11MOdzvirEallt8fuKjQ8A",
            "name": "light / tertiary-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 32
            }
        },
        {
            "id": "xTsGC2VV2kiBP3ccGmURKg",
            "name": "light / tertiary-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 44
            }
        },
        {
            "id": "2Za2W82_WU66tnU7WD_8zQ",
            "name": "light / surface / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Da1-LMQ61Ueak_OODrEOmQ",
            "name": "light / tertiary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 20
            }
        },
        {
            "id": "IjIbzAp9lEG9TcXCOBYo-A",
            "name": "light / secondary-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 44
            }
        },
        {
            "id": "QJeVQ4pl-U6lvTAs-G3eyA",
            "name": "light / secondary-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "G0gvrQcrlkubEQsp_tCPLw",
            "name": "light / primary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 20
            }
        },
        {
            "id": "QRDiUKf9GE69SiETzO_c2A",
            "name": "light / secondary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 24
            }
        },
        {
            "id": "9dT1cIU9SkqqGN8Oi6GMMw",
            "name": "light / transparent / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "z8d0Ha8rQ0mjCC5H4LRPWg",
            "name": "light / transparent / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "l5tOlcxv5EKz4yapc9Y81A",
            "name": "light / neutral / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 28
            }
        },
        {
            "id": "9qkFLpTEo0OQSca0EFnwXA",
            "name": "light / neutral-bright / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 36
            }
        },
        {
            "id": "wg_9E5-HIUGlM_s_gH2Y7g",
            "name": "light / error-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 40
            }
        },
        {
            "id": "51KQtHgbOEGtzvtZNRnYmA",
            "name": "light / invert-primary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 32
            }
        },
        {
            "id": "J9P_wkx-GEaHJ1yqG_ps5Q",
            "name": "light / error / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 36
            }
        },
        {
            "id": "bigh2oe9302sIg1SE6pC3g",
            "name": "light / error-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "3dgUJpYZJEeqxbNx5OEDnQ",
            "name": "light / disabled-on-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 32
            }
        },
        {
            "id": "OE9AsWs9GEGhd-oP7-Qpdg",
            "name": "light / disabled-on-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 44
            }
        },
        {
            "id": "SVOekUYhMUmt6Dr2_pTE6A",
            "name": "light / transparent / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 32
            }
        },
        {
            "id": "NpivFHbZwU-igsW6lv33Rw",
            "name": "light / transparent-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 44
            }
        },
        {
            "id": "uK49gBQmEEamw7xSunDTRw",
            "name": "light / tertiary-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "E09DCCjv4UeNg9_P_C_dVg",
            "name": "light / tertiary-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 20
            }
        },
        {
            "id": "ZIHQ6WGt1k65ZN5d8BI5EA",
            "name": "light / surface / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 44
            }
        },
        {
            "id": "RDNL3qPCa0mfuhrungZFQw",
            "name": "light / tertiary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 20
            }
        },
        {
            "id": "5J10aLEwUU-SrgNYOpP4Mg",
            "name": "light / secondary-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "noiwU-wf7kWpOOf1KDS0iA",
            "name": "light / secondary-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 24
            }
        },
        {
            "id": "ZFobI_-RK0-E8xyHuIT_dQ",
            "name": "light / primary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 20
            }
        },
        {
            "id": "RGd_n4f8HEmvGVqaNdcMwQ",
            "name": "light / secondary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSFhGQdqAbgrvd5UvJXfDA",
                "lineHeight": 28
            }
        },
        {
            "id": "Iceuli4di0Sdo7d8UkHwxg",
            "name": "light / transparent-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 24
            }
        },
        {
            "id": "BKWL-2Lqx0Kq8BKW9ln2vw",
            "name": "light / transparent-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 24
            }
        },
        {
            "id": "s0OT7DWwJkuPXCtRzmRN2g",
            "name": "light / neutral / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 40
            }
        },
        {
            "id": "uOoD4bKH-0CxF_9HT1q06w",
            "name": "light / neutral-bright / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3pl77HW2kCdwCAj-sUw",
                "lineHeight": 32
            }
        },
        {
            "id": "p5K8gcDEJUOJrxjM3MuXUQ",
            "name": "light / error-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 36
            }
        },
        {
            "id": "gY8UhdXZNUuP3FEPj6aQag",
            "name": "light / invert-primary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSVZi0LR_FTPtf_bhxbvIg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "ifC-Q6wEFEOnEsgl2vgdAw",
            "name": "light / error / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOQLcFfVN5GNONx5OIig",
                "lineHeight": 32
            }
        },
        {
            "id": "g5B6MQXTrE-3jFlvaMpNQw",
            "name": "light / error-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2w7uvdlffExzEsgR7w",
                "lineHeight": 44
            }
        },
        {
            "id": "3Qg4caMUGU-JMqm7496U8g",
            "name": "light / disabled-on-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "QY33oly1tkK5oi20jdwLdQ",
            "name": "light / disabled-on-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 20
            }
        },
        {
            "id": "xKXRlOHpMkCm0-3Zwj-YlQ",
            "name": "light / transparent / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "kAlaHHolXUuIQnAFR9g7qQ",
            "name": "light / transparent-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "oQ84iNDu4k603E3o-PV7ug",
            "name": "light / tertiary-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 44
            }
        },
        {
            "id": "XQLnQ0zvOUS4dkBp-Gh2dw",
            "name": "light / tertiary-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPDA2vjWpOWJxcHidATkw",
                "lineHeight": 20
            }
        },
        {
            "id": "envpSgOsjUOZ6_CvGetj6A",
            "name": "light / surface / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOTrfhvGpvjRLvvvy7RA",
                "lineHeight": 20
            }
        },
        {
            "id": "qXojuBXPC06gTsqRgoZTgA",
            "name": "light / tertiary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3uV_qiJVhI-8cC2ehTg",
                "lineHeight": 24
            }
        },
        {
            "id": "C8LTU8VThUGcEBWWdtIrcQ",
            "name": "light / secondary-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 20
            }
        },
        {
            "id": "T0JB5zZ28EexDBigBzY_RQ",
            "name": "light / secondary-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC82sAEPjNcZv4qC3juw",
                "lineHeight": 28
            }
        },
        {
            "id": "032sOqX160CYRIelmnGW4Q",
            "name": "light / primary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 24
            }
        },
        {
            "id": "agFyfueV80yTEr7S8LyfPg",
            "name": "light / secondary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSIsYJNEaGQMcsswrJWJcg",
                "lineHeight": 40
            }
        },
        {
            "id": "Xht0RBkDc0-D8QsWmrfscA",
            "name": "light / primary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3qV8gcDi8yErJaXNgMQ",
                "lineHeight": 28
            }
        },
        {
            "id": "FV6tkkuUi0KspighsH0IsA",
            "name": "dark / secondary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 40
            }
        },
        {
            "id": "9w269YDwqEm7PyrYxTVMRw",
            "name": "dark / secondary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 36
            }
        },
        {
            "id": "3zr_N0UYS0-_bnZyWtv62g",
            "name": "dark / secondary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 32
            }
        },
        {
            "id": "fvJ0bJ94WE-Uv7sU7qW9iA",
            "name": "dark / primary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 32
            }
        },
        {
            "id": "BGN0xqRkhkCRH7qZ5wBmGQ",
            "name": "dark / primary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "cYiAxxdJ5kKSmGB2PUM5Tg",
            "name": "dark / primary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 44
            }
        },
        {
            "id": "AsUWaAvqxU2L3WTHkQBqKQ",
            "name": "dark / neutral-bright / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 44
            }
        },
        {
            "id": "4f3uL3DmTUS7YroSC7SiJA",
            "name": "dark / neutral-bright / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "MXc7KRFGqkSmuNN2XIQ14A",
            "name": "dark / neutral-bright / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "22HxH6Grxk6XqRLltKBEdw",
            "name": "dark / secondary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "QtGfD8iIR0KZy_oWgDM0QQ",
            "name": "dark / neutral / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "W3XZnD86iECo08CvTiQOfg",
            "name": "dark / neutral / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "Se-XanH9hUq6l9z-F2kKnw",
            "name": "dark / neutral / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 28
            }
        },
        {
            "id": "YdUvrcW4VkOZqlaKhr4rMg",
            "name": "dark / invert-primary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 28
            }
        },
        {
            "id": "Xl-uYYehWkezupyoKkjEAA",
            "name": "dark / invert-primary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "wxJkFMq3xk--RbSDP7iauA",
            "name": "dark / invert-primary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 40
            }
        },
        {
            "id": "rUmI9mKLJEOpIQSgRh3qdQ",
            "name": "dark / error-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 40
            }
        },
        {
            "id": "3z2D21GWTE-In3n5wTrH4w",
            "name": "dark / error-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 36
            }
        },
        {
            "id": "tuo3BaV7tU68VxY6p0bAXw",
            "name": "dark / error-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 32
            }
        },
        {
            "id": "r0uu_L66j0ash6h0AsiKJQ",
            "name": "dark / secondary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 44
            }
        },
        {
            "id": "tgvVt9CfykCZLq6JHUtx2w",
            "name": "dark / error-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 32
            }
        },
        {
            "id": "1o9xe9yDtEubsw_30E4vPg",
            "name": "dark / error-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "YEdx6JmRQUqxbJ29A6C66g",
            "name": "dark / error-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 44
            }
        },
        {
            "id": "RElsdeW3kkK2HCzZWDW2Kw",
            "name": "dark / error / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 44
            }
        },
        {
            "id": "HEPTDUhdbUu6K0oboUI76w",
            "name": "dark / error / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 20
            }
        },
        {
            "id": "qW8Rh3Gfv0uAfb7kF6gYbg",
            "name": "dark / error / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 20
            }
        },
        {
            "id": "PhzSYbMVbUy7G6cMloxKIA",
            "name": "dark / disabled-on-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 20
            }
        },
        {
            "id": "3TakJMCwtkORTOBBTUYdHQ",
            "name": "dark / disabled-on-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 24
            }
        },
        {
            "id": "VjYdTMVmyUWJtQ7mZz4ung",
            "name": "dark / disabled-on-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 28
            }
        },
        {
            "id": "mlcZsMGEnky6PrHPbhvKkw",
            "name": "dark / disabled-on-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 28
            }
        },
        {
            "id": "s7w7i47ajEWpHm4RUHRhqA",
            "name": "dark / disabled-on-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 24
            }
        },
        {
            "id": "2wgR_9XyEkOa7Wx3Z-a2oQ",
            "name": "dark / disabled-on-bright / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 40
            }
        },
        {
            "id": "Xw7G9S6m-06iD3cORZ1GMg",
            "name": "dark / secondary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 20
            }
        },
        {
            "id": "NoDposTufEmfvafnU2MQLg",
            "name": "dark / primary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 20
            }
        },
        {
            "id": "V4ZB9l6LC0isFNN3c7C4kg",
            "name": "dark / primary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 20
            }
        },
        {
            "id": "h2t4o67fK0i3SdkRrq6bpg",
            "name": "dark / primary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 24
            }
        },
        {
            "id": "6XClM98z50eskzPXCpweCw",
            "name": "dark / neutral-bright / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "S7_I_PtT6UqnN8rFO5tuXw",
            "name": "dark / neutral-bright / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 28
            }
        },
        {
            "id": "vREWE_ZeREaamAqG30yyiA",
            "name": "dark / neutral-bright / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "8fOJIV6eB0KbfqKyy_W5Dw",
            "name": "dark / neutral / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "vqaJ9LY9hUeHwjEI4BkT-g",
            "name": "dark / neutral / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 40
            }
        },
        {
            "id": "7JZjQbMbvkebVezOmz0LAA",
            "name": "dark / neutral / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 36
            }
        },
        {
            "id": "RVw67ndm10-Ee8pcqtwUbA",
            "name": "dark / invert-primary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 36
            }
        },
        {
            "id": "Y3vF2b616ESTR2tc9MQgQw",
            "name": "dark / invert-primary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 32
            }
        },
        {
            "id": "BrcWaT-q6Ey8q6BCUNX8cA",
            "name": "dark / invert-primary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Vd5DC-aKPEyws0WqzuXf0Q",
            "name": "dark / error-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "wtZTl-6VFkGWbwQI5A1JVg",
            "name": "dark / error-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 44
            }
        },
        {
            "id": "ab1WOJ1SeEOH0uQIK24QeA",
            "name": "dark / error-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 20
            }
        },
        {
            "id": "LgBi-WkWREKUpQK4zwLgEw",
            "name": "dark / error-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 20
            }
        },
        {
            "id": "WLK01eELw0KLfdsnxP5D8Q",
            "name": "dark / error-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 20
            }
        },
        {
            "id": "a0c8SEtQGEqZmDW5xTWPVg",
            "name": "dark / error-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 24
            }
        },
        {
            "id": "dLyQcWolqE-reykls9NO2g",
            "name": "dark / error / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 24
            }
        },
        {
            "id": "xiAR4egEqEKbGww8FZYGWw",
            "name": "dark / error / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 28
            }
        },
        {
            "id": "5zZW9wstv0qJ3HR3Q2biRg",
            "name": "dark / error / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 24
            }
        },
        {
            "id": "p9KdLRf9qUCUABq351ZD_g",
            "name": "dark / disabled-on-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 24
            }
        },
        {
            "id": "ZuEVbsZwikyiMVhKcRtmXQ",
            "name": "dark / disabled-on-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 40
            }
        },
        {
            "id": "Gw3WU5D010mF1JH405ZG5A",
            "name": "dark / disabled-on-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 36
            }
        },
        {
            "id": "S3OlowOLq0ONW_UuNIXshg",
            "name": "dark / disabled-on-bright / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 36
            }
        },
        {
            "id": "kcVIRVAsj0CcTrTxGnssaw",
            "name": "dark / disabled-on-bright / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 32
            }
        },
        {
            "id": "pM-A4x6mAU6y2_EhaZ4sDg",
            "name": "dark / disabled-on-bright / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "o5NKLJ1BDku3hc48KTZlhw",
            "name": "dark / secondary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 20
            }
        },
        {
            "id": "njdMUKlu5k60Dt5Nq4Z_mw",
            "name": "dark / secondary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 24
            }
        },
        {
            "id": "risiUESQy0--EvatnkCNag",
            "name": "dark / secondary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 28
            }
        },
        {
            "id": "yq4JNpqZ9EK3UYhJt38jnQ",
            "name": "dark / secondary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC82sAEPjNcZv4wi7Ynw",
                "lineHeight": 24
            }
        },
        {
            "id": "dmL45kZfMUeHYyLo0T5pJA",
            "name": "dark / secondary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 40
            }
        },
        {
            "id": "b080KSgNTki3-ShFTsxUmw",
            "name": "dark / secondary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 36
            }
        },
        {
            "id": "VSm_x9UMmE6puBGg0OoeVg",
            "name": "dark / secondary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 32
            }
        },
        {
            "id": "tkxwyVJY0kWwPT3mA5sHSA",
            "name": "dark / secondary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "xyEq8RwSPUegduoQPl_jIg",
            "name": "dark / secondary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 44
            }
        },
        {
            "id": "GTw08t6BVUugnfeWrcNOrQ",
            "name": "dark / secondary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 20
            }
        },
        {
            "id": "_JXPR4LmhUCLVHzmudMJUg",
            "name": "dark / secondary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 20
            }
        },
        {
            "id": "c1isbZmkMEe2hxxZntYO5g",
            "name": "dark / secondary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 24
            }
        },
        {
            "id": "n2rzNWMuAEySDzwzGKDfTA",
            "name": "dark / secondary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 28
            }
        },
        {
            "id": "g3W_21967EahXyJg_C5uWw",
            "name": "dark / secondary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LXDN29wg",
                "lineHeight": 24
            }
        },
        {
            "id": "4bnRr0lyqUi5oQRFWj0BUA",
            "name": "dark / primary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 40
            }
        },
        {
            "id": "bCARD3TfeEmGiS_1CzfVrQ",
            "name": "dark / primary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 36
            }
        },
        {
            "id": "uO9W_GIsGkWa2jOXhgGcTQ",
            "name": "dark / primary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 28
            }
        },
        {
            "id": "vo_bA-bZqka41naiGEnZPA",
            "name": "dark / primary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3qV8gcDi8yErJXHL5zg",
                "lineHeight": 24
            }
        },
        {
            "id": "lkips2oPMkm0duzIcHwjow",
            "name": "dark / primary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 40
            }
        },
        {
            "id": "fSKElFZLDkqds3uBSUg20Q",
            "name": "dark / primary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 36
            }
        },
        {
            "id": "yRQR4QiY-0ibxqxxe1_G8g",
            "name": "dark / primary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 32
            }
        },
        {
            "id": "mFZE4LXiEE65vq00Dadjpg",
            "name": "dark / primary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "sTBMcrBiOkSuF-EjqQBLtw",
            "name": "dark / primary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 44
            }
        },
        {
            "id": "bhVtWsLJ3Uu0Cv9pP14ONg",
            "name": "dark / primary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 20
            }
        },
        {
            "id": "2wmmo-Sg4kGY3zQNrE2_Jw",
            "name": "dark / primary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 20
            }
        },
        {
            "id": "ZhIraUCLm0WZcFrXKY-lrA",
            "name": "dark / primary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 24
            }
        },
        {
            "id": "fKEq4oGO10aF_tWQQaLdVg",
            "name": "dark / primary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 28
            }
        },
        {
            "id": "Ckoqu9Z860uR_3iDWYqg0g",
            "name": "dark / primary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSFhFAc7jwR0Kx2TdyVOKw",
                "lineHeight": 24
            }
        },
        {
            "id": "q5qVOOmJA0KZOV3WYGEJhA",
            "name": "dark / neutral-bright / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 40
            }
        },
        {
            "id": "-PQ_dVBEHESbysFyMPEGiw",
            "name": "dark / neutral-bright / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 36
            }
        },
        {
            "id": "n7WsMqDhSkmB8YiHc3AmJg",
            "name": "dark / neutral-bright / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 32
            }
        },
        {
            "id": "trBBrWXECEmuhDbpKlzoXQ",
            "name": "dark / neutral-bright / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "5RBd2QFUOkq-ApEDk1JCiQ",
            "name": "dark / neutral-bright / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 40
            }
        },
        {
            "id": "e5WcQ5iHDk6TvQEKI48qwg",
            "name": "dark / neutral-bright / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 36
            }
        },
        {
            "id": "ybYLO0nSW0mKa7JosXYOOA",
            "name": "dark / neutral-bright / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 32
            }
        },
        {
            "id": "9ifFxVvLWE-B01jWDg_4Hw",
            "name": "dark / neutral-bright / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "KmF1IJRrrUG9uYbx-OKeWA",
            "name": "dark / neutral-bright / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 44
            }
        },
        {
            "id": "Ni7EoAd920-MT_CRGG4Aug",
            "name": "dark / neutral-bright / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "UzNF2PAcjUOrAbYztGOmsw",
            "name": "dark / neutral-bright / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "ZhkM59oyM0K-MuQtK-1erg",
            "name": "dark / neutral-bright / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "CoO0prDMskSOA20TODgOKA",
            "name": "dark / neutral-bright / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 28
            }
        },
        {
            "id": "CAunctnPvkCbiE5FDEX8zA",
            "name": "dark / neutral-bright / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "hEnj2DdYEUmEFsv6WELbxw",
            "name": "dark / neutral / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 40
            }
        },
        {
            "id": "ynxJ0_-zYUSjuWNnKvU8AA",
            "name": "dark / neutral / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 36
            }
        },
        {
            "id": "3_noqtjn4Um7f9HtlNm1tw",
            "name": "dark / neutral / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 32
            }
        },
        {
            "id": "O0B3vSgfikyjmAXtaHKgwA",
            "name": "dark / neutral / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "QOokGTF04k66elbFhI269g",
            "name": "dark / neutral / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 44
            }
        },
        {
            "id": "AVWr6J6vg0-17hDDiMsFvw",
            "name": "dark / neutral / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "Bq4hkbJB-keYXhRPFuIEsw",
            "name": "dark / neutral / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 32
            }
        },
        {
            "id": "r6KWcfiy60-zoUz16q_f_w",
            "name": "dark / neutral / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "JvBkthYH80q_MEH97-IzRA",
            "name": "dark / neutral / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 44
            }
        },
        {
            "id": "MCl8NqEKy0GlOn9oIKUzkQ",
            "name": "dark / neutral / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "JlTa3qEkWUOMUQ0dZvQQHA",
            "name": "dark / neutral / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "VwccNQ9cZEGiQ2fRf6rivw",
            "name": "dark / neutral / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "6MbxCljksk6duXyvLDO3PA",
            "name": "dark / neutral / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 28
            }
        },
        {
            "id": "sTlSHF6xXEqmSqWVo6LEdw",
            "name": "dark / neutral / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "YoSnPq3reUel6v7rv__1CQ",
            "name": "dark / invert-primary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 40
            }
        },
        {
            "id": "uUwoNC6oOUWIIqQwLT4bjw",
            "name": "dark / invert-primary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 36
            }
        },
        {
            "id": "ku4jNykuykq8LnC-M9JSMA",
            "name": "dark / invert-primary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 32
            }
        },
        {
            "id": "4AEo24qB3EWWu3x27EDu6g",
            "name": "dark / invert-primary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "dxRrUFY0GkS0MEyz7fMKJg",
            "name": "dark / invert-primary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 44
            }
        },
        {
            "id": "FO3Nkfo6oEyuVDrg8P_UxQ",
            "name": "dark / invert-primary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "49ZRRnDOdESBKChsAxHdmQ",
            "name": "dark / invert-primary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "tYEIb7ed5kuu38JUCq__Mg",
            "name": "dark / invert-primary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "XvVRYt7p-Uqfih7s5r4t1w",
            "name": "dark / invert-primary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 44
            }
        },
        {
            "id": "sikUHHijXU2dbrArtG-nPA",
            "name": "dark / invert-primary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 20
            }
        },
        {
            "id": "RVYe_LZ0nkqbJl3N9fTA3Q",
            "name": "dark / invert-primary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 20
            }
        },
        {
            "id": "wppu62gUZUC7dUMDvpETqg",
            "name": "dark / invert-primary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 24
            }
        },
        {
            "id": "OdbPeI6RmEec31cXzr8oXA",
            "name": "dark / invert-primary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 28
            }
        },
        {
            "id": "hwCmENSerkeMWBfJc25TDA",
            "name": "dark / invert-primary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwKBrJ6MqNw3jdFNYXSg",
                "lineHeight": 24
            }
        },
        {
            "id": "2L2jISi7R0q4LzGE-dQ65A",
            "name": "dark / error-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 40
            }
        },
        {
            "id": "g_qgFfAn5Em9_oU7gm1o1g",
            "name": "dark / error-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 36
            }
        },
        {
            "id": "RilAmiSJjUSbJBlwFrmbHg",
            "name": "dark / error-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 32
            }
        },
        {
            "id": "XUdwE8nKSk-4xLiBogVcSA",
            "name": "dark / error-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "we0pLxL80E-bVf-PWkb2iA",
            "name": "dark / error-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 44
            }
        },
        {
            "id": "J0bFkhB3hUeIr8rKOIHjeA",
            "name": "dark / error-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 20
            }
        },
        {
            "id": "AmFxCq-jmE-B0D4jJ8jzig",
            "name": "dark / error-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 20
            }
        },
        {
            "id": "eVorveALN0mIMpD12cPpnw",
            "name": "dark / error-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 24
            }
        },
        {
            "id": "58InZRmTDEyB5GMY4fB9cg",
            "name": "dark / error-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 28
            }
        },
        {
            "id": "JBiFuR_LgEaxkkQvz9bPlA",
            "name": "dark / error-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 24
            }
        },
        {
            "id": "rFoh7vwPakK6yCThf8bNnQ",
            "name": "dark / error-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 20
            }
        },
        {
            "id": "QN57ay4Efk6ZUFz2bAD_vw",
            "name": "dark / error-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 24
            }
        },
        {
            "id": "vubr6qrew0mZcEsN_Jukrw",
            "name": "dark / error-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 28
            }
        },
        {
            "id": "N_r9D2R6lEasE2_mqnhRlw",
            "name": "dark / error-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 24
            }
        },
        {
            "id": "tx9-2VSk-0yFDFrxvXt5Pw",
            "name": "dark / error-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 40
            }
        },
        {
            "id": "IPz7pf72XEKpa8Mnxr03oA",
            "name": "dark / error-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 36
            }
        },
        {
            "id": "1RpGHKfYaEieGHRSUGT0Mw",
            "name": "dark / error-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 32
            }
        },
        {
            "id": "wLqeIZWD3kO17ftzXqyuAw",
            "name": "dark / error-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "iojZlr8MbkyAqwwWR4uSOQ",
            "name": "dark / error-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 44
            }
        },
        {
            "id": "6fOpuh78q0KkkakdJRl4Hg",
            "name": "dark / error-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 20
            }
        },
        {
            "id": "1uVD38nb8U2VdLL_Svs-Jw",
            "name": "dark / error-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 20
            }
        },
        {
            "id": "QJHoOY2YP0OH-NYGGBB6XQ",
            "name": "dark / error-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 24
            }
        },
        {
            "id": "lgWZouRw70S5QXZSh2vUWg",
            "name": "dark / error-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 28
            }
        },
        {
            "id": "v1kKa2-CTEWC8Ul02YScYw",
            "name": "dark / error-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwKhrzlls0WX3DvyE4fA",
                "lineHeight": 24
            }
        },
        {
            "id": "6mqHQSeUK0OWBjIhHvsKnw",
            "name": "dark / error-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 40
            }
        },
        {
            "id": "v3NASU673EWIwgiK0XSwnw",
            "name": "dark / error-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 36
            }
        },
        {
            "id": "yB1PxCbjm0aG3VbohXIlLA",
            "name": "dark / error-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 28
            }
        },
        {
            "id": "AEOpI57sMUmvYdhu7LhN-Q",
            "name": "dark / error-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSe7emcF3siqCxEsoBxecQ",
                "lineHeight": 24
            }
        },
        {
            "id": "lj9_7IW3FEin_NzJ2FVylw",
            "name": "dark / error / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 40
            }
        },
        {
            "id": "n2MqbpxxIkahmeobRXuPuQ",
            "name": "dark / error / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 36
            }
        },
        {
            "id": "LVHLmCdyt0GsyT1HIzTJKw",
            "name": "dark / error / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 32
            }
        },
        {
            "id": "kwr4l_Bi9UeLOFwwN96QBA",
            "name": "dark / error / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "KNNYZzMsBkCUsd2bZCtrUQ",
            "name": "dark / error / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 44
            }
        },
        {
            "id": "U8TY9piYk0ytvXvH4dXOdQ",
            "name": "dark / error / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 20
            }
        },
        {
            "id": "DrDqcr1fpU-VBu4LC18UrQ",
            "name": "dark / error / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 20
            }
        },
        {
            "id": "UjHk3eUn1U-2RwS0PgH9CQ",
            "name": "dark / error / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 24
            }
        },
        {
            "id": "La7a52OM3EGimIzHq9wcnQ",
            "name": "dark / error / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 28
            }
        },
        {
            "id": "ovEqdDifKECjquDVgqftFg",
            "name": "dark / error / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSPC9WsdJbSWvYpBZu6TaA",
                "lineHeight": 24
            }
        },
        {
            "id": "qoWVs4FBok-KF6ctwSfK3g",
            "name": "dark / error / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 40
            }
        },
        {
            "id": "6pTmxlKAkUuiVtYWG-uKMQ",
            "name": "dark / error / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 36
            }
        },
        {
            "id": "rLuTq07cYU6eW7KUgc6LRg",
            "name": "dark / error / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 32
            }
        },
        {
            "id": "g2SdVF9oUk65YfHqq3YB6g",
            "name": "dark / error / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSIsYpNbMNMlpnplT7reAA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "SOLifCTQXkeWb8AIeNTzBw",
            "name": "dark / disabled-on-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 40
            }
        },
        {
            "id": "aWo5engMEUmoUSjXknCEdw",
            "name": "dark / disabled-on-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 36
            }
        },
        {
            "id": "fj0CrAE9Y0OvVIFAyP0OBg",
            "name": "dark / disabled-on-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 32
            }
        },
        {
            "id": "BTLczWNS10iJRkFJT1didg",
            "name": "dark / disabled-on-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "JvgGZVW3WU6cGkjmh6ZdcQ",
            "name": "dark / disabled-on-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 44
            }
        },
        {
            "id": "fqKK5ES0ME-Zeid8WfcPaA",
            "name": "dark / disabled-on-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 20
            }
        },
        {
            "id": "w-XvH5yfbUiutP67ytJDeQ",
            "name": "dark / disabled-on-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 20
            }
        },
        {
            "id": "P3q-sxAe9E69DSBlZoM2CQ",
            "name": "dark / disabled-on-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 24
            }
        },
        {
            "id": "9jbVhfyEsEOnqnoEIc0Hkg",
            "name": "dark / disabled-on-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 28
            }
        },
        {
            "id": "12moehCcykKP_-1YF9MhYQ",
            "name": "dark / disabled-on-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 24
            }
        },
        {
            "id": "wkzyqJpxNUGwX5pET-6fWw",
            "name": "dark / disabled-on-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 40
            }
        },
        {
            "id": "o9N2fvHZ8U2J8vn9Fmz4ZQ",
            "name": "dark / disabled-on-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 36
            }
        },
        {
            "id": "vcgEbNEG9ECk6p8pbUCPIw",
            "name": "dark / disabled-on-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 32
            }
        },
        {
            "id": "2g2xJ2qHp02Qa0gnT9HPPQ",
            "name": "dark / disabled-on-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "w2ncwYlpPUCLitDxB7WJkA",
            "name": "dark / disabled-on-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 44
            }
        },
        {
            "id": "vTRxeltDEESEo4ShV36l2Q",
            "name": "dark / disabled-on-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSPC8GrWBPLzXXU9h5bV4w",
                "lineHeight": 20
            }
        },
        {
            "id": "T2jXIdWbsUCrP5qKP3HIEw",
            "name": "dark / disabled-on-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 32
            }
        },
        {
            "id": "pO24NRLCU0C4W1J-y73-LQ",
            "name": "dark / disabled-on-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "qWUe86sop0-DyLYczyUDSw",
            "name": "dark / disabled-on-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 44
            }
        },
        {
            "id": "_-aBTef7jUq7eDZGHTj-2w",
            "name": "dark / disabled-on-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 20
            }
        },
        {
            "id": "s9DOYK1HLUSZtONfTjOJiQ",
            "name": "dark / disabled-on-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 20
            }
        },
        {
            "id": "cz6WHrk-X0-UO1mLSzijfw",
            "name": "dark / disabled-on-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 24
            }
        },
        {
            "id": "aZK1qbeqNUSoCkBhqmsQIw",
            "name": "dark / disabled-on-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 28
            }
        },
        {
            "id": "rXFpKyR3KEqghXAS7U7BxQ",
            "name": "dark / disabled-on-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWQ",
                "lineHeight": 24
            }
        },
        {
            "id": "aks_oJkHVUOQGcx-FYUCYw",
            "name": "dark / disabled-on-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 40
            }
        },
        {
            "id": "jHcN-P6pCUmh9a8veIf0EQ",
            "name": "dark / disabled-on-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 36
            }
        },
        {
            "id": "FIjAxlHNCUyk-MK0GYHpNQ",
            "name": "dark / disabled-on-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 32
            }
        },
        {
            "id": "7koHyKrwkEWeAy-C5sEI2Q",
            "name": "dark / disabled-on-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "gnxGeN0JMkWCPLT9ip_sxw",
            "name": "dark / disabled-on-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 44
            }
        },
        {
            "id": "GoQ1LCPYa0SyEb7wYnGaZw",
            "name": "dark / disabled-on-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 20
            }
        },
        {
            "id": "mqteRqQfUUSTH2D4Fj-T9w",
            "name": "dark / disabled-on-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 20
            }
        },
        {
            "id": "xeX12uODsE26ATyGr_NgzQ",
            "name": "dark / disabled-on-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBfg",
                "lineHeight": 24
            }
        },
        {
            "id": "-_-pbEcpmUKHn45V4rSltw",
            "name": "dark / disabled-on-bright / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 44
            }
        },
        {
            "id": "9_jLGDzS_k-qf0HhqdbwNQ",
            "name": "dark / disabled-on-bright / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 20
            }
        },
        {
            "id": "wHr0VGW2I0Gm--0SZxW28w",
            "name": "dark / disabled-on-bright / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 20
            }
        },
        {
            "id": "rkh7Os2Lx0ecfJcVTQtYbQ",
            "name": "dark / disabled-on-bright / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 24
            }
        },
        {
            "id": "fZ18tdSIWEahY3RJx5IVPw",
            "name": "dark / disabled-on-bright / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 28
            }
        },
        {
            "id": "pSMTXz-iZUuSNhZKVKG5Ug",
            "name": "dark / disabled-on-bright / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBgg",
                "lineHeight": 24
            }
        },
        {
            "id": "HEkevT4tJEug0ChbCfGl2Q",
            "name": "dark / disabled-on-bright / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 40
            }
        },
        {
            "id": "RG3YjVZ1ZU-IDGVQ36NvPA",
            "name": "dark / disabled-on-bright / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 36
            }
        },
        {
            "id": "WW5wtB6vo0yzRx5sehCZ_g",
            "name": "dark / disabled-on-bright / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 32
            }
        },
        {
            "id": "aUVrqkN0ckKAtavzve5plA",
            "name": "dark / disabled-on-bright / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "CfxralvGg0uhPAXUlIV4-Q",
            "name": "dark / disabled-on-bright / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 44
            }
        },
        {
            "id": "U2ZsbibvukWkbm_SVonKUg",
            "name": "dark / disabled-on-bright / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 20
            }
        },
        {
            "id": "8yWnmTXsDUKayduP-XcMPw",
            "name": "dark / disabled-on-bright / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 20
            }
        },
        {
            "id": "jTMOIg0jM0a4e7f2mlf1gg",
            "name": "dark / disabled-on-bright / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 24
            }
        },
        {
            "id": "JlDXWrBBIEiDg5LxCPeHEA",
            "name": "dark / disabled-on-bright / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 28
            }
        },
        {
            "id": "yU7oQ0a7dEK_m7CuFz3Erg",
            "name": "dark / disabled-on-bright / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayUw",
                "lineHeight": 24
            }
        },
        {
            "id": "8-aMPGuyrEO5GMN91ZDOcw",
            "name": "light / disabled-on-bright / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 28
            }
        },
        {
            "id": "KfLSuCYeMkSHBrsW1kd3oA",
            "name": "light / disabled-on-bright / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 24
            }
        },
        {
            "id": "KHRQUZY3j0C7AA_y56Invg",
            "name": "light / disabled-on-bright / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 20
            }
        },
        {
            "id": "JB-x8e-QikyGSdxBXx_XxQ",
            "name": "light / disabled-on-bright / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 20
            }
        },
        {
            "id": "CaPQ2dSzZUOAnLzFU_hZpg",
            "name": "light / disabled-on-bright / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 44
            }
        },
        {
            "id": "B_2Fm7_BjkGcoxjrrSZ7kQ",
            "name": "light / disabled-on-bright / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Zq17zJurxUuq9A6LVp3Lkg",
            "name": "light / disabled-on-bright / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 32
            }
        },
        {
            "id": "vGmNAYF7A0KIYkmt20ZSpQ",
            "name": "light / disabled-on-bright / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 36
            }
        },
        {
            "id": "bOk9OylH6EmVc5SpHfARHQ",
            "name": "light / disabled-on-bright / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSSOO7a2fSHdJ-pZpyayWg",
                "lineHeight": 40
            }
        },
        {
            "id": "5TBGVYvddUON5-MrKi41Zw",
            "name": "dark / secondary-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "SW-rHPBTW0qfD1oiwiJp_A",
            "name": "dark / secondary-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 28
            }
        },
        {
            "id": "8EIcYJGH306yBeKfKY53rw",
            "name": "dark / secondary-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "HXIPgTKLY02MR0o0N0pB6Q",
            "name": "dark / secondary-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "nMJG5F4CAUeebYr6ndMmHQ",
            "name": "dark / secondary-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "gzzbhum_U06fRTPo1I87Kw",
            "name": "dark / secondary-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 44
            }
        },
        {
            "id": "0u2wk2D0eUypco4eIQzkXQ",
            "name": "dark / secondary-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "l-lbTACjPEyaq8FL45k06A",
            "name": "dark / secondary-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 32
            }
        },
        {
            "id": "EmopIB1aWU6QX9wvMlZnmA",
            "name": "dark / secondary-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 36
            }
        },
        {
            "id": "e6WYQ_jRsUmMK0zf7kHWhw",
            "name": "dark / secondary-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 40
            }
        },
        {
            "id": "NLlE77-QbUiZIabt7dwpwg",
            "name": "dark / secondary-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 24
            }
        },
        {
            "id": "Mr1FUlanqUOBfa2wQMMFEQ",
            "name": "dark / secondary-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 28
            }
        },
        {
            "id": "ceZZMXrXBESTy3JsL9bzhg",
            "name": "dark / secondary-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 24
            }
        },
        {
            "id": "UoHU4ZfTxUCWoefqsS-KoA",
            "name": "dark / secondary-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 20
            }
        },
        {
            "id": "yp87G62Mw0egjnIacI_Zdg",
            "name": "dark / secondary-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 20
            }
        },
        {
            "id": "orXO1Iyx206mUHMC2DC0lQ",
            "name": "dark / secondary-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 44
            }
        },
        {
            "id": "T0tlPTts_Uq3p5hPOQC9Qg",
            "name": "dark / secondary-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "xvILS8lZzkikcbG__0GHng",
            "name": "dark / secondary-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 32
            }
        },
        {
            "id": "B563kp-iqUuAIB6LMv4Qcg",
            "name": "dark / secondary-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 36
            }
        },
        {
            "id": "RYFZiqyQ5EKvLLwZvj1Sng",
            "name": "dark / secondary-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwIxpkWhK_DUc1xsw64Q",
                "lineHeight": 40
            }
        },
        {
            "id": "_pF48oxbPUuOB_D_IST2HQ",
            "name": "dark / secondary-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "kt5gvWfOyEesdgBCpX7t1w",
            "name": "dark / secondary-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 28
            }
        },
        {
            "id": "l8kPQbBXJk-SOE5aJ0bmlQ",
            "name": "dark / secondary-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 24
            }
        },
        {
            "id": "RgXMx2B9eU2P_KuTVxLyog",
            "name": "dark / secondary-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "J_9qJ57bA0uIGjOz2Wkd8Q",
            "name": "dark / secondary-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 20
            }
        },
        {
            "id": "EwlK3-dmaEOZ1vMlQF1rdw",
            "name": "dark / secondary-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 44
            }
        },
        {
            "id": "2p4dy5gIok235YrncX0WOw",
            "name": "dark / secondary-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "WNwC0NNShU2U3S7X-uoM5g",
            "name": "dark / secondary-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 32
            }
        },
        {
            "id": "IluSupa500Gj7dIN9GyoBw",
            "name": "dark / secondary-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 36
            }
        },
        {
            "id": "I4vQxPu-x0CJxzQktr5A-A",
            "name": "dark / secondary-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk2Q7INoVf74LW_NzakQ",
                "lineHeight": 40
            }
        },
        {
            "id": "uud_q6LRlk61JikfR470zw",
            "name": "dark / surface / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "4rJKlQwrL0iLumfZ8ZsA0w",
            "name": "dark / surface / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 28
            }
        },
        {
            "id": "x6xC8IL7c06ihWhxiUTu2A",
            "name": "dark / surface / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "J3YMus8RckWY1mygGbqKUQ",
            "name": "dark / surface / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "eIpT1pZPSUyXDgouYLq5BQ",
            "name": "dark / surface / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "whzLXTBdBkiySBxKhYfMRw",
            "name": "dark / surface / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 44
            }
        },
        {
            "id": "Qh1oul0vG0ym3fH5GE0AMA",
            "name": "dark / surface / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Xg_FgC2b5Uq2FmORr19MhQ",
            "name": "dark / surface / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 32
            }
        },
        {
            "id": "OMeJ3EDaEUa3xUPZ2ukznw",
            "name": "dark / surface / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 36
            }
        },
        {
            "id": "-SwM3qE4E02anVvIaLDEUQ",
            "name": "dark / surface / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 40
            }
        },
        {
            "id": "NWP7vB-48km2RuAG_ZD6YQ",
            "name": "dark / surface / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "RLSSEGXuSkKmYXvbiGqv_g",
            "name": "dark / surface / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 28
            }
        },
        {
            "id": "mA1cRNze3EerFSaXXiYtzQ",
            "name": "dark / surface / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "vRIiwmuY80KcwsdhOLWZ_Q",
            "name": "dark / surface / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "9-ZTq17J_EaBBEarzp5BLw",
            "name": "dark / surface / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "oDnLNcaESUio9lM-S8-R5Q",
            "name": "dark / surface / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 44
            }
        },
        {
            "id": "xoJ_PG1SNUGGquaEgRLvgA",
            "name": "dark / surface / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "G5i_u9W34kWL69Hem9pMHw",
            "name": "dark / surface / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 32
            }
        },
        {
            "id": "bFeVPpeZq0aU6kJI3UTd2A",
            "name": "dark / surface / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 36
            }
        },
        {
            "id": "A1w07RulLk-dwIePlaHTBA",
            "name": "dark / surface / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 40
            }
        },
        {
            "id": "IXEfE9eVXUyu4Kr65K7fJA",
            "name": "dark / tertiary / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 24
            }
        },
        {
            "id": "8iVQ3oDglUGk5OvO3MHz1Q",
            "name": "dark / tertiary / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 28
            }
        },
        {
            "id": "peKktw4VUESncY9Hv-lsWQ",
            "name": "dark / tertiary / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 24
            }
        },
        {
            "id": "urrmFYEoOki7D1p-YjY-RQ",
            "name": "dark / tertiary / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 20
            }
        },
        {
            "id": "WcCDRbOTckqZNVP6xuLd3Q",
            "name": "dark / tertiary / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 20
            }
        },
        {
            "id": "6qFFHjeSrEOB58wCawOuNg",
            "name": "dark / tertiary / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 44
            }
        },
        {
            "id": "mx5DBJKaskG2Tha_wSffJg",
            "name": "dark / tertiary / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "uNrlmy0g00W2OZupGIEv5g",
            "name": "dark / tertiary / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 32
            }
        },
        {
            "id": "G5z8kfh2BEaLcRT_azz6iw",
            "name": "dark / tertiary / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 36
            }
        },
        {
            "id": "xC51Yt8YK0O9KL-n2TQI7w",
            "name": "dark / tertiary / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSFhKQgBuA9_-peiIrCgmw",
                "lineHeight": 40
            }
        },
        {
            "id": "Cw3Nya7Bkk2CiDlHHHL5SA",
            "name": "dark / tertiary / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 24
            }
        },
        {
            "id": "Hfb6Z8GHREWNG689P-0G5g",
            "name": "dark / tertiary / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 28
            }
        },
        {
            "id": "qhpAxwqHyUSCkgkb8e8L5A",
            "name": "dark / tertiary / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 24
            }
        },
        {
            "id": "S4qI-eAu1E6uqkzmxRn4Mw",
            "name": "dark / tertiary / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 20
            }
        },
        {
            "id": "rQtdpHUugkasALrfN_bboA",
            "name": "dark / tertiary / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 20
            }
        },
        {
            "id": "4vLQNqPmSkacz0slnoWoEQ",
            "name": "dark / tertiary / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 44
            }
        },
        {
            "id": "3myFHMdaRE-oDvMZHzXnfQ",
            "name": "dark / tertiary / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "x7bnotuSQECsusPeBTNOnA",
            "name": "dark / tertiary / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 32
            }
        },
        {
            "id": "PaOkGDK4yUmnDRSVAhEBBg",
            "name": "dark / tertiary / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 36
            }
        },
        {
            "id": "yD7COefnQUSe8u8CdfYyaw",
            "name": "dark / tertiary / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSL3uV_qiJVhI-8b9mb8Eg",
                "lineHeight": 40
            }
        },
        {
            "id": "ocZXCI2ImEefA8guzQe5Sw",
            "name": "dark / tertiary-lighted / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 24
            }
        },
        {
            "id": "WT463JIFR0KK_nvUPAgN6g",
            "name": "dark / tertiary-lighted / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 28
            }
        },
        {
            "id": "kS2S9vk2x0GgKLajtTtREg",
            "name": "dark / tertiary-lighted / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 24
            }
        },
        {
            "id": "wmFssNQKSUuEcao_-ZZPBg",
            "name": "dark / tertiary-lighted / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 20
            }
        },
        {
            "id": "POpCbc1SZ0mW4e0rWNspGg",
            "name": "dark / tertiary-lighted / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 20
            }
        },
        {
            "id": "gxPWa0edZ0SaDOAGv7J-pg",
            "name": "dark / tertiary-lighted / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 44
            }
        },
        {
            "id": "0eZsxowgkEmKCkAhaA1K5w",
            "name": "dark / tertiary-lighted / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "0HlQrKivXkmlHmjohr1YpQ",
            "name": "dark / tertiary-lighted / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 32
            }
        },
        {
            "id": "2H6mHhzcjkOxoMqh2fq4Rw",
            "name": "dark / tertiary-lighted / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 36
            }
        },
        {
            "id": "UopnrJymkUmAeR2XQQzfcw",
            "name": "dark / tertiary-lighted / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 40
            }
        },
        {
            "id": "D1GYrlsvF0GmlKn67xsRHA",
            "name": "dark / tertiary-lighted / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 24
            }
        },
        {
            "id": "hN6bMhEO4ESPZ8jr7_RSgQ",
            "name": "dark / tertiary-lighted / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 28
            }
        },
        {
            "id": "gJ7bNYoJE0ay62UtafYkQw",
            "name": "dark / tertiary-lighted / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 24
            }
        },
        {
            "id": "kKKNWQPjUUu9PdR4mT77Jg",
            "name": "dark / tertiary-lighted / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 20
            }
        },
        {
            "id": "ufT292Chr0OQKyuBT5z4bg",
            "name": "dark / tertiary-lighted / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 20
            }
        },
        {
            "id": "RS2YUWIPCU6TqCplvJZBIg",
            "name": "dark / tertiary-lighted / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 44
            }
        },
        {
            "id": "hyZ38GjRRki-faI9-X0aUw",
            "name": "dark / tertiary-lighted / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "nj6dJBbbI0ygPSPxQMmrhQ",
            "name": "dark / tertiary-lighted / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 32
            }
        },
        {
            "id": "oWt_kx4z6UO-OYOGqU9I-A",
            "name": "dark / tertiary-lighted / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 36
            }
        },
        {
            "id": "BLP_fJNILUymCGJp7auDVA",
            "name": "dark / tertiary-lighted / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 40
            }
        },
        {
            "id": "g_hInYJuJ0SnH8x8yYEOuw",
            "name": "dark / tertiary-lighted-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 24
            }
        },
        {
            "id": "moTOJRWaKkW_mT3XOXvXdA",
            "name": "dark / tertiary-lighted-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 28
            }
        },
        {
            "id": "rmztIa483EKN_iIp88Kg1g",
            "name": "dark / tertiary-lighted-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 24
            }
        },
        {
            "id": "F_2qUGOBlUK4A_TzYi_pcg",
            "name": "dark / tertiary-lighted-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 20
            }
        },
        {
            "id": "E7d5Ubk0hkeSxR3Hto7LfA",
            "name": "dark / tertiary-lighted-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 20
            }
        },
        {
            "id": "n2G9VvdYbk-jcz5IHEqQ0g",
            "name": "dark / tertiary-lighted-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 44
            }
        },
        {
            "id": "2RzhfMum0kCBD4g1lucyfw",
            "name": "dark / tertiary-lighted-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "TRk5he16cEOdst2HbFEEOw",
            "name": "dark / tertiary-lighted-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 32
            }
        },
        {
            "id": "I2eSTEnoPUOKJHQVY40ojw",
            "name": "dark / tertiary-lighted-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 36
            }
        },
        {
            "id": "putBb9rgU06UL9ZS9uBZUA",
            "name": "dark / tertiary-lighted-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR-Op_gN_hq2bDCQ",
                "lineHeight": 40
            }
        },
        {
            "id": "oLc1mPBT9U642glXbwuESw",
            "name": "dark / tertiary-lighted-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 24
            }
        },
        {
            "id": "jwz-5D0qxUmbYy94KSpVhQ",
            "name": "dark / tertiary-lighted-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 28
            }
        },
        {
            "id": "xw3s6Ge31kWp4k8hlH_PtQ",
            "name": "dark / tertiary-lighted-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 24
            }
        },
        {
            "id": "Z5SsDtUmAkmk-FTcTiB00A",
            "name": "dark / tertiary-lighted-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 20
            }
        },
        {
            "id": "HL0L2k9WMUCnWqSuO0gXuA",
            "name": "dark / tertiary-lighted-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 20
            }
        },
        {
            "id": "xPYa4GfTqEGcBUQ_0US63w",
            "name": "dark / tertiary-lighted-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 44
            }
        },
        {
            "id": "0tG8Ae4OR0yGr8f_GAnkcw",
            "name": "dark / tertiary-lighted-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "UZiRoeqa_0GZg77riWaRsw",
            "name": "dark / tertiary-lighted-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 32
            }
        },
        {
            "id": "OvBLag1k1EiOm8Qr_gakXA",
            "name": "dark / tertiary-lighted-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 36
            }
        },
        {
            "id": "xfQaLt0RWUiC_D59em3Frw",
            "name": "dark / tertiary-lighted-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSYk6Q_3FMiJyS755XJS6g",
                "lineHeight": 40
            }
        },
        {
            "id": "zE1I51wJE0W6SU2H4rukGQ",
            "name": "dark / transparent / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "32wAj2Qy4kmrgcKyuFK5Dg",
            "name": "dark / transparent / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 28
            }
        },
        {
            "id": "QFd11nB8DEOWMxlMKBpI4A",
            "name": "dark / transparent / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "jIJcmFiXZUGfWEt1ML9pZg",
            "name": "dark / transparent / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "lCHC1LCPKkWVe7VYHDJXtA",
            "name": "dark / transparent / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "oOt42M4_ikig8zIworwpdA",
            "name": "dark / transparent / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 44
            }
        },
        {
            "id": "JdiuCOqmBkiCqAFun34ZrA",
            "name": "dark / transparent / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "2iDi230tCUyKiakFUTPOow",
            "name": "dark / transparent / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 32
            }
        },
        {
            "id": "QMwXK1IXE0ClA78JHE5F8Q",
            "name": "dark / transparent / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 36
            }
        },
        {
            "id": "mG47knCOOUG662IHJ2Lmog",
            "name": "dark / transparent / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 40
            }
        },
        {
            "id": "ZKqlRxCYwkWBIyED6VkD4g",
            "name": "dark / transparent / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "wf36PirQ806uGs8Nj-dPNA",
            "name": "dark / transparent / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 28
            }
        },
        {
            "id": "EXt2xDRoXk2j_X4fOxrTvQ",
            "name": "dark / transparent / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "pYztzuhrVUCzWNOIqPvzRQ",
            "name": "dark / transparent / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "qJASA-N5ukmo0wePdsOVcQ",
            "name": "dark / transparent / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "dWDKkMEroE-XEpiGLNCjPQ",
            "name": "dark / transparent / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 44
            }
        },
        {
            "id": "LAo1r8vOVk6L6nvIQi1OLQ",
            "name": "dark / transparent / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "RAd9LnnF3EW7WaQEXIT1SA",
            "name": "dark / transparent / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 32
            }
        },
        {
            "id": "VkAmpJkiGkC-N0K2MRo8tQ",
            "name": "dark / transparent / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 36
            }
        },
        {
            "id": "bIXOUyuzok2Q-G6esdn3Kg",
            "name": "dark / transparent / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 40
            }
        },
        {
            "id": "gDuZlbLTxEidJVD0_DotLw",
            "name": "dark / transparent-mild / variant / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "eU4SK_q_I06padifnZe8_A",
            "name": "dark / transparent-mild / variant / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 28
            }
        },
        {
            "id": "9iBY9Z9Tp0io0qJUSCPwFQ",
            "name": "dark / transparent-mild / variant / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 24
            }
        },
        {
            "id": "Dn4Dv0NVykqN5-d9hHLj8g",
            "name": "dark / transparent-mild / variant / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "Btfjfl5HPUaJ8P-izgdA1g",
            "name": "dark / transparent-mild / variant / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 20
            }
        },
        {
            "id": "Bvh4p2h-pU6uswssC0tNAw",
            "name": "dark / transparent-mild / variant / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 44
            }
        },
        {
            "id": "ezZ7WGlW8UaR2e-wYt3qXw",
            "name": "dark / transparent-mild / variant / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "aSqswoYIDk6Ymv0CcL1gkQ",
            "name": "dark / transparent-mild / variant / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 32
            }
        },
        {
            "id": "z6LM1gRbSUKAUZsbyKhwBg",
            "name": "dark / transparent-mild / variant / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 36
            }
        },
        {
            "id": "11iquh-gvEiVMKHoZnpb6w",
            "name": "dark / transparent-mild / variant / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwJRqM949R-HPAZqTBiA",
                "lineHeight": 40
            }
        },
        {
            "id": "DD_u5ph_CE6HwPkcAxOliw",
            "name": "dark / transparent-mild / body",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "fjC9RGwT7kymetguGFOPWw",
            "name": "dark / transparent-mild / body-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 22,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 28
            }
        },
        {
            "id": "9IRDcIke80WNMeXQwzhgGw",
            "name": "dark / transparent-mild / body-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 16,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 24
            }
        },
        {
            "id": "8QY7QQ590Em_zhxzYmprHQ",
            "name": "dark / transparent-mild / caption",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "Y2ijIvO_2kST0_wdc6xvNQ",
            "name": "dark / transparent-mild / caption-strong",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 14,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 20
            }
        },
        {
            "id": "rSjxE40y0EOprY3pcETa6A",
            "name": "dark / transparent-mild / display",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 35,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 44
            }
        },
        {
            "id": "gWajl3XPOkanQ3yL6f2aCg",
            "name": "dark / transparent-mild / label",
            "style": {},
            "textStyle": {
                "font": "Inter-Medium",
                "size": 11,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 16,
                "kerning": 0.5
            }
        },
        {
            "id": "Rwkhz5kEuk-JRrKI0NWXzA",
            "name": "dark / transparent-mild / subtitle",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 24,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 32
            }
        },
        {
            "id": "wxSCLKBX_k2MweaK1-8EzA",
            "name": "dark / transparent-mild / title",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 28,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 36
            }
        },
        {
            "id": "SHCYVupjAkiQjJRdKlrGcw",
            "name": "dark / transparent-mild / title-large",
            "style": {},
            "textStyle": {
                "font": "Inter-Regular",
                "size": 32,
                "colorId": "gSbwOBwR9-pslzv4RGktBQ",
                "lineHeight": 40
            }
        }
    ],
    "pages": [
        "AAtBLWFmJUaIADX69n3nHQ",
        "PMfqn7TuQEW2AlPYW39TCQ",
        "6l2qv46thU6ObltMNCYe8Q",
        "UFMXmKvPbE2WCtQ3xon-Cw",
        "acZIxBXlWkaztY-5ZGC2Hw"
    ]
}