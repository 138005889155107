import { convertColor } from './convertColor.js';
import { colors } from '../app.js';

export function findColor(id, theme = 'light') {
    // Поиск с использованием метода find
    let color = colors.find(color => color.id === id);

    // Если тема 'dark', меняем логические условия
    if (theme === 'dark' && color) {
        const targetChar = color.name.includes(' l ') ? ' l ' : ' d ';
        const replacementChar = targetChar === ' l ' ? ' d ' : ' l ';
        const modifiedName = color.name.replace(targetChar, replacementChar);

        // Попробуем найти цвет с измененным именем
        const alternateColor = colors.find(c => c.name === modifiedName);

        if (alternateColor) {
            color = alternateColor;
        }
    }
    
    // Возвращаем значение value или null, если не найдено
    return color ? convertColor(color.value) : null;
}