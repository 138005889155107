/* export function getPageScroll(page) {
    return localStorage.getItem(page) || 0;
}; */
export function getPageScroll(page) {
    let data = localStorage.getItem(page);
    if (data) {
        requestAnimationFrame(() => {
            window.scrollTo({
                top: data
            });
        });
    };
};