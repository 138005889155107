import {routing} from './routing.js';
import {setPageScroll} from './setPageScroll.js';
import {getPageScroll} from './getPageScroll.js';
import {scrollToElement} from './scrollToElement.js';

export function changeURL(url, transition, scroll_to) {

    const currentDate = new Date().toISOString();

    let currentPage = sessionStorage.getItem('navigation') ? JSON.parse(sessionStorage.getItem('navigation')) : null;
    let newPage;

    window.addEventListener('popstate', function(event) {
        if (event.state) {
            newPage = event.state;
        }
    });

    if (url === 'back') {
        url = document.referrer;
    }
    if (document.documentElement.clientWidth < '800') {
        //let newScreen = document.createElement('app-page'); // for iOS style
        switch (transition) {
            // Material v3 style
            case 'slidein':
                document.getElementById("app").style.cssText = `opacity: 0; margin-left: ${document.documentElement.clientWidth / 3}px;`;
                history.pushState({ date: currentDate, url: url, animation: transition }, null, url);
                sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                routing();
                if (scroll_to) {
                    setTimeout(() => {
                        scrollToElement(scroll_to);
                    }, 100);
                } else {
                    //window.scrollTo(0, 0);
                    requestAnimationFrame(() => {
                        window.scroll({ top: -1, left: 0 });
                    });
                }
                setTimeout(function () {
                    document.getElementById("app").style.cssText = "opacity: 1; margin-left: 0px; transition: opacity 400ms linear, margin 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);";
                }, 10);
                setTimeout(function () {
                    document.getElementById("app").removeAttribute("style");
                }, 410);
                break;
            case 'slideout':
                history.back();
                setTimeout(function () {
                    if (!newPage) {
                        document.getElementById("app").style.cssText = `opacity: 0; margin-left: ${document.documentElement.clientWidth / 3}px; transition: opacity 200ms linear, margin 200ms cubic-bezier(0.895, 0.030, 0.685, 0.220);`;
                        setTimeout(function () {
                            document.getElementById("app").style.cssText = `opacity: 0; margin-left: -${document.documentElement.clientWidth / 8}px;`;
                            history.replaceState({ date: window.history.state.date, url: url, animation: 'default' }, null, url);
                            sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                            routing();
                        }, 200);
                        setTimeout(function () {
                            document.getElementById("app").style.cssText = `opacity: 1; margin-left: 0px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000);`;
                        }, 250);
                        setTimeout(function () {
                            document.getElementById("app").removeAttribute("style");
                        }, 550);
                    }
                }, 10);
                break;
            case 'slideup':
                document.getElementById("app").style.cssText = `opacity: 0; margin-top: ${document.documentElement.clientHeight / 9}px;`;
                history.pushState({ date: currentDate, url: url, animation: transition }, null, url);
                sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                routing();
                if (scroll_to) {
                    setTimeout(() => {
                        scrollToElement(scroll_to);
                    }, 100);
                } else {
                    //window.scrollTo(0, 0);
                    requestAnimationFrame(() => {
                        window.scroll({ top: -1, left: 0 });
                    });
                }
                setTimeout(function () {
                    document.getElementById("app").style.cssText = "opacity: 1; margin-top: 0px; transition: opacity 400ms linear, margin 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);";
                }, 10);
                setTimeout(function () {
                    document.getElementById("app").removeAttribute("style");
                }, 410);
                break;
            case 'slidedown':
                history.back();
                setTimeout(function () {
                    if (!newPage) {
                        document.getElementById("app").style.cssText = `opacity: 0; margin-top: ${document.documentElement.clientHeight / 9}px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.895, 0.030, 0.685, 0.220);`;
                        setTimeout(function () {
                            document.getElementById("app").style.cssText = `opacity: 0; margin-top: -${document.documentElement.clientHeight / 18}px;`;
                            history.replaceState({ date: window.history.state.date, url: url, animation: 'default' }, null, url);
                            sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                            routing();
                        }, 300);
                        setTimeout(function () {
                            document.getElementById("app").style.cssText = `opacity: 1; margin-top: 0px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000);`;
                        }, 350);
                        setTimeout(function () {
                            document.getElementById("app").removeAttribute("style");
                        }, 650);
                    }
                }, 10);
                break;
            case 'keepScroll':
                if (window.history.state) {
                    history.replaceState({ date: window.history.state.date, url: url, animation: transition }, null, url);
                    sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                } else {
                    history.replaceState({ date: currentDate, url: url, animation: transition }, null, url);
                    sessionStorage.setItem('navigation', JSON.stringify(currentDate));
                }
                routing();
                break;
            case 'default':
            default:
                history.pushState({ date: currentDate, url: url, animation: transition }, null, url);
                sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                routing();
                if (scroll_to) {
                    setTimeout(() => {
                        scrollToElement(scroll_to);
                    }, 100);
                } else {
                    //window.scrollTo(0, 0);
                    requestAnimationFrame(() => {
                        window.scroll({ top: -1, left: 0 });
                    });
                }
        }
    } else {
        switch (transition) {
            case 'keepScroll':
                if (window.history.state) {
                    history.replaceState({ date: window.history.state.date, url: url, animation: transition }, null, url);
                    sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                } else {
                    history.replaceState({ date: currentDate, url: url, animation: transition }, null, url);
                    sessionStorage.setItem('navigation', JSON.stringify(currentDate));
                }
                routing();
                break;
            case 'slideout':
            case 'slidedown':
                history.back();
                setTimeout(function () {
                    if (!newPage) {
                        history.replaceState({ date: window.history.state.date, url: url, animation: 'default' }, null, url);
                        sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                        routing();
                    }
                }, 10);
                break;
            case 'slidein':
            case 'slideup':
            case 'default':
            default:
                history.pushState({ date: currentDate, url: url, animation: transition }, null, url);
                sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                routing();
                if (scroll_to) {
                    setTimeout(() => {
                        scrollToElement(scroll_to);
                    }, 100);
                } else {
                    //window.scrollTo(0, 0);
                    requestAnimationFrame(() => {
                        window.scroll({ top: -1, left: 0 });
                    });
                }
        }
    };
}