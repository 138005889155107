import { querySelectorDeep } from './querySelectorDeep.js';
import { querySelectorAllDeep } from './querySelectorAllDeep.js';
import { config } from '../config.js';
import { obtainToken } from '../app.js';
import { responseHandleForm } from './responseHandleForm.js';
import { toggleSpinner } from './toggleSpinner.js';
import { getJWT } from './getJWT.js';
import { deleteJWT } from './deleteJWT.js';
import { data, loadData, addToStorage } from './getData.js';
import { setTimeStampQuery } from './getAccount.js';
import { Client, Databases, Query, Account, Teams, ID, Permission, Role, Messaging } from "appwrite";

function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}
function validatePassword(password) {
    return password.length >= 8;
}
function validatePhoneNumber(phoneNumber) {
    // Удаляем пробелы, дефисы и скобки
    const cleanedPhoneNumber = phoneNumber.replace(/[\s\-()]/g, '');

    // Проверяем номер по маске
    const regex = /^\+\d{11,15}$/;

    return regex.test(cleanedPhoneNumber);
}

/* function executeDynamicValues(obj) {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string') {
            try {
                obj[key] = (new Function(`return ${value}`))();
            } catch {
                obj[key] = value;
            }
        } else if (typeof value === 'object' && value !== null) {
            executeDynamicValues(value);
        }
    }
    return obj;
} */
function executeDynamicValues(obj) {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string') {
            obj[key] = value;
        } else if (typeof value === 'object' && value !== null || Array.isArray(value)) {
            obj[key] = executeNestedObjectAsString(value);
        }
    }
    return obj;
}

function executeNestedObjectAsString(nestedObj) {
    let resultObj = executeDynamicValues(nestedObj);
    return JSON.stringify(resultObj); // Преобразуем обработанный объект в строку JSON
}

function deepUpdate(original, update) {
    for (const key in update) {
        if (update.hasOwnProperty(key)) {
            let originalValue = original[key];
            let updateValue = update[key];

            // Если строка в формате JSON, то парсим её
            try {
                if (typeof originalValue === 'string' && originalValue.startsWith('{') && originalValue.endsWith('}')) {
                    originalValue = JSON.parse(originalValue);
                }
            } catch (e) { }

            try {
                if (typeof updateValue === 'string' && updateValue.startsWith('{') && updateValue.endsWith('}')) {
                    updateValue = JSON.parse(updateValue);
                }
            } catch (e) { }

            // Если оба значения являются объектами, делаем рекурсивный вызов
            if (typeof originalValue === 'object' && originalValue !== null && typeof updateValue === 'object' && updateValue !== null) {
                deepUpdate(originalValue, updateValue);
            } else if (originalValue !== updateValue) {
                // Заменяем значение правого объекта, если они отличаются
                original[key] = update[key];
            }
        }
    }
    return original;
}

function setToStorage($databaseId, $collectionId, $id, $createdAt, $updatedAt, fields) {
    fields = JSON.parse(fields);

    let doc;
    // Поиск существующего документа
    loadData('local');
    let storedData = data.local || [];
    let filteredData = storedData.filter(doc => doc.$collectionId === $collectionId && doc !== null);

    if (filteredData.length < 1) {
        loadData('session');
        storedData = data.session || [];
        filteredData = storedData.filter(doc => doc.$collectionId === $collectionId && doc !== null);
    }

    if (filteredData.length > 0) {
        let foundItem = filteredData.find(item => item.$id === $id);

        if (foundItem) {
            doc = deepUpdate(foundItem, fields);
            doc.$updatedAt = $updatedAt;
        }
    }

    //Создание нового документа
    if (!doc) { // Если doc еще не установлен
        if (fields.public === undefined) {
            fields.public = true;
        }
        doc = {
            "$databaseId": $databaseId,
            "$collectionId": $collectionId,
            "$id": $id,
            "$createdAt": $createdAt,
            "$updatedAt": $updatedAt,
            ...fields
        };
    }

    addToStorage('local', doc);
    addToStorage('session', doc);
}

function generateUniqueId() {
    const timePart = Date.now().toString(32);
    const randomPartLength = 20 - timePart.length;

    // Функция для генерации случайных символов
    function getRandomString(length) {
        let result = '';
        while (result.length < length) {
            result += Math.random().toString(32).substr(2);
        }
        return result.substring(0, length);
    }

    const randomPart = getRandomString(randomPartLength);

    return timePart + randomPart;
}

/* export async function postData(form, response, error) {
    const endpoint = window.config.endpoint;
    const project = window.config.project;
    const database = window.config.database;

    const client = new Client()
        .setEndpoint(endpoint)
        .setProject(project)
    ;
    const databases = new Databases(client);

    if (form.type === 'createMagicURLToken') {
        if (validateEmail(form.email)) {
            const account = new Account(client);

            const token = await account.createMagicURLToken(ID.unique(), form.email);
        }

    } else if (form.type === 'createDocument') {

    }
} */


// Функция для сбора данных формы в JSON
export async function postData(formID, form, collection, documentID, customFields, permissions, success, error) {
    const endpoint = config.endpoint;
    const project = config.project;
    const database = config.database;

    const client = new Client()
        .setEndpoint(endpoint)
        .setProject(project)
        ;
    const databases = new Databases(client);

    const data = { prefs: {} };
    let hasError = false;

    if (formID) {
        formID = querySelectorDeep(document.body, `#${formID}`);
        const fields = querySelectorAllDeep('component-input-text, component-input-image, component-textarea, component-input-checkbox, component-select, component-switch', formID);

        fields.forEach(field => {
            if (field.id) {
                let props = field.getAttribute("props");
                if (props) {
                    props = JSON.parse(decodeURIComponent(atob(props)));
                }
                let value = props?.value ? props.value : null;
                let prefs = props?.prefs || false;
                let required = props?.required ? props.required : false;

                // Проверка на обязательность поля
                if (!value && required) {
                    field.setAttribute('error', 'Поле не заполнено');
                    hasError = true;
                }

                // Валидация email
                if (value && field.id === 'email') {
                    if (!validateEmail(value)) {
                        field.setAttribute('error', 'Неверный формат email');
                        hasError = true;
                    }
                }

                // Валидация password
                if (value && field.id === 'newPassword') {
                    if (!validatePassword(value)) {
                        field.setAttribute('error', 'Пароль должен быть не менее 8 символов');
                        hasError = true;
                    }
                }

                // Валидация номера телефона
                if (value && field.id === 'phone') {
                    if (!validatePhoneNumber(value)) {
                        field.setAttribute('error', 'Неверный формат номера телефона');
                        hasError = true;
                    }
                }

                if (value && field.id === 'image') {
                    Object.keys(value).forEach(key => {
                        if (prefs) {
                            data.prefs[key] = value[key];
                        } else {
                            data[key] = value[key];
                        }
                    });
                } else {
                    // Заполнение data и data.prefs
                    if (prefs) {
                        data.prefs[field.id] = value;
                    } else {
                        data[field.id] = value;
                    }
                }
            }
        });
    }
    if (Object.keys(data.prefs || {}).length === 0) {
        delete data.prefs;
    }
    if (customFields) {
        customFields = JSON.parse(customFields);
        Object.assign(data, executeDynamicValues(customFields));
    }
    if (data?.table && typeof data.table === 'number') {
        data.table = String(data.table);
    }
    if (data?.cart && window.navigatorOnline) {
        data.cart = JSON.parse(data.cart);

        let query = [];
        let ids = data.cart.map(row => row.id);

        query.push(Query.equal('$id', ids));
        query.push(Query.limit(data.cart.length));

        try {
            const result = await databases.listDocuments(
                database,
                'goods',
                query
            );

            data.cart.forEach(cartItem => {
                // Найдем соответствующий документ в результатах по $id
                const document = result.documents.find(doc => doc.$id === cartItem.id);

                if (document) {
                    // Распарсим строку цен для получения массива цен
                    const prices = JSON.parse(document.price);

                    // Найдем нужную цену по price_id
                    const price = prices.find(p => p.id === cartItem.price_id);

                    if (price) {
                        // Заменим цену в cart данной записи
                        cartItem.price = price.price;
                    }
                }
            });
        } catch (e) { }
        data.cart = JSON.stringify(data.cart);
    }

    if (hasError) {
        return { error: 'Заполните все обязательные поля' };
    } else {
        if (form === 'createMagicURLToken') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                let link = `${window.location.href}?magicURL=true`;
                await account.createMagicURLToken(
                    ID.unique(), // userId
                    data.email, // email
                    link, // url (optional)
                    false // phrase (optional)
                );

                //console.log(result);
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ id: 'sendMagicURL', type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                return { response: 'success' };
            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                }
                toggleSpinner(false);
                return { error: e };
            }
        }
        if (form === 'createEmailPasswordSession') {
            const account = new Account(client);
            const teams = new Teams(client);
            const storageKey = 'account';

            toggleSpinner(true);
            try {
                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                await account.deleteSession('current');
            } catch (e) { }
            try {
                await account.createEmailPasswordSession(
                    data.email, // email
                    data.password // password
                );

                // Получение профиля пользователя
                const userAccount = await account.get();
                // Получение списка команд пользователя
                const userTeams = await teams.list();
                // Определение роли пользователя
                let role = 'guest';
                // Проверка наличия email
                if (userAccount.email) {
                    role = 'user';
                }
                // Проверка наличия команд и ролей
                if (userTeams.teams && userTeams.teams.length > 0) {
                    const roles = new Set(userTeams.teams.map(team => team.name));

                    if (roles.has('admin')) {
                        role = 'admin';
                    } else if (roles.has('managers')) {
                        role = 'manager';
                    }
                }
                // Добавление роли в userAccount
                userAccount.role = role;

                setTimeStampQuery(storageKey);
                // Сохранение данных в localStorage
                localStorage.setItem(storageKey, JSON.stringify(userAccount));

                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };
            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e, duration: 0 });
                }
                toggleSpinner(false);
                return { error: e };
            }
        }
        if (form === 'editAccount') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                if (data.fname) {
                    await account.updateName(data.fname);
                }

                const prefs = await account.getPrefs();

                if (Object.keys(data.prefs).length > 0) {
                    const updatedPrefs = { ...prefs };

                    // Автоматическое обновление из data.prefs
                    Object.keys(data.prefs).forEach(key => {
                        updatedPrefs[key] = data.prefs[key];
                    });

                    await account.updatePrefs(updatedPrefs);
                }

                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };

            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'deleteAccount') {
            toggleSpinner(true);
            try {
                // Ожидание получения jwt_token
                let jwt_token = await getJWT();

                if (!jwt_token) {
                    toggleSpinner(false);
                    window.snackbarManager.addItem({ type: 'error', message: 'Вы не авторизованы. Повторите вход и попробуйте снова.', duration: 0 });
                    return 'Вы не авторизованы. Повторите вход и попробуйте снова.';
                }

                let formData = new FormData();
                formData.append('jwt_token', jwt_token);

                let accountData = {};
                const cachedData = localStorage.getItem('account');
                if (cachedData) {
                    accountData = JSON.parse(cachedData);
                    formData.append('user_id', accountData.$id);
                }

                // Отправка запроса
                let response = await fetch(`${config.host}/deleteAccount.php`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    // Отладка: если ответ не OK, выводим статус и текст ошибки
                    console.error(`Ошибка: ${response.status}, ${response.statusText}`);
                    throw new Error('Произошла ошибка при удалении аккаунта.');
                }

                let result = await response.json();

                if (result.success) {
                    sessionStorage.removeItem(btoa(JSON.stringify('account')));
                    localStorage.removeItem('account');
                    deleteJWT();
                    if (success && success.message) {
                        let message = success.message;
                        let duration = success.duration || 0;
                        window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                    }
                    toggleSpinner(false);
                    responseHandleForm(success);
                    return { response: 'success' };
                } else {
                    // Отладка: если result.success не сработал, выводим результат целиком
                    console.error('Ошибка в result.success:', result.error);
                    if (error && error.message) {
                        let message = error.message;
                        let duration = error.duration || 0;
                        window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                    } else {
                        window.snackbarManager.addItem({ type: 'error', message: result.error || 'Произошла ошибка.', duration: 0 });
                    }
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: result.error || 'Произошла ошибка' };
                }
            } catch (e) {
                console.error(e);
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'updateEmail') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                await account.updateEmail(
                    data.email, // email
                    data.password // password
                );

                let verifURL = `${window.location.protocol}//${window.location.host}/account/view/?verified=true`;
                await account.createVerification(
                    verifURL // url
                );

                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };

            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'updatePassword') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                if (data.password) {
                    await account.updatePassword(
                        data.newPassword, // password
                        data.password // oldPassword (optional)
                    );
                } else {
                    await account.updatePassword(
                        data.newPassword // password
                    );
                }

                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };

            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                    console.log(e);
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'createRecovery') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                const cachedData = localStorage.getItem('account');
                const accountData = cachedData ? JSON.parse(cachedData) : {};

                let link = `${window.location.protocol}//${window.location.host}/recovery/edit/?recovery=true`;
                if (data.email) {
                    await account.createRecovery(
                        data.email, // email
                        link // url
                    );
                } else if (accountData.email) {
                    await account.createRecovery(
                        accountData.email, // email
                        link // url
                    );
                } else {
                    toggleSpinner(false);
                    return { error: 'Не указан email' };
                }

                //console.log(result);
                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };
            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'updateRecovery') {
            const account = new Account(client);
            toggleSpinner(true);
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const recovery = urlParams.get('recovery');
                const secret = urlParams.get('secret');
                const userId = urlParams.get('userId');
                if (userId && secret && recovery) {
                    await account.updateRecovery(
                        userId, // userId
                        secret, // secret
                        data.newPassword // password
                    );
                } else if (!userId && !secret && !recovery) {
                    toggleSpinner(false);
                    if (error && error.message) {
                        let message = error.message;
                        let duration = error.duration || 0;
                        window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                        responseHandleForm(error);
                    } else {
                        window.snackbarManager.addItem({ type: 'error', message: 'Некорректная ссылка для сброса пароля', duration: 0 });
                    }
                    return { error: 'Не указан email' };
                }

                sessionStorage.removeItem(btoa(JSON.stringify('account')));
                if (success && success.message) {
                    let message = success.message;
                    let duration = success.duration || 0;
                    window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                }
                toggleSpinner(false);
                responseHandleForm(success);
                return { response: 'success' };

            } catch (e) {
                if (error && error.message) {
                    let message = error.message;
                    let duration = error.duration || 0;
                    window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                } else {
                    window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                    console.log(e);
                }
                toggleSpinner(false);
                responseHandleForm(error);
                return { error: e };
            }
        }
        if (form === 'link') {
            if (success && success.message) {
                let message = success.message;
                let duration = success.duration || 0;
                window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
            }
            toggleSpinner(false);
            responseHandleForm(success);
            return { response: 'success' };
        }
        if (form === 'create') {
            if (window.navigatorOnline) {
                toggleSpinner(true);

                const account = new Account(client);

                let anonymus = true;
                let verified = false;
                try {
                    const response = await account.get();
                    anonymus = !response.email && !response.phone;
                    verified = response.emailVerification || response.phoneVerification; // USER_ID пользователя
                    //console.log(response);
                    //console.log("USER_ID:", userID);
                } catch (error) {
                    //console.error("Ошибка при получении USER_ID:", error);
                }

                documentID = documentID || ID.unique();

                /* // Подготовка данных для отправки
                const requestData = { ...data };

                // Исключаем data.prefs, если он пуст
                if (Object.keys(requestData.prefs || {}).length === 0) {
                    delete requestData.prefs;
                } */

                /* 'permissions': { 'read': { 'users': ['data.$id'] } }
                permissions = {
                    read: {
                        users: ['user1', 'user2'],
                        teams: ['team1'],
                        any: true
                    },
                    update: {
                        users: ['user3'],
                        labels: ['label1']
                    },
                    delete: {
                        teams: ['team2'],
                        users: ['user4', 'user5']
                    }
                }; */

                let permissionsArray = [];

                for (const [action, targets] of Object.entries(permissions)) {
                    if (targets.any) {
                        if (action === 'read') {
                            permissionsArray.push(Permission.read(Role.any()));
                        }
                        if (action === 'update') {
                            permissionsArray.push(Permission.update(Role.any()));
                        }
                        if (action === 'delete') {
                            permissionsArray.push(Permission.delete(Role.any()));
                        }
                    }

                    if (targets.users) {
                        targets.users.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    anonymus ?
                                        permissionsArray.push(Permission.read(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.read(Role.user(id))) :
                                            permissionsArray.push(Permission.read(Role.user(id, 'unverified')));
                                }
                                if (action === 'update') {
                                    anonymus ?
                                        permissionsArray.push(Permission.update(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.update(Role.user(id))) :
                                            permissionsArray.push(Permission.update(Role.user(id, 'unverified')));
                                }
                                if (action === 'delete') {
                                    anonymus ?
                                        permissionsArray.push(Permission.delete(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.delete(Role.user(id))) :
                                            permissionsArray.push(Permission.delete(Role.user(id, 'unverified')));
                                }
                            }
                        });
                    }

                    if (targets.teams) {
                        targets.teams.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    permissionsArray.push(Permission.read(Role.team(id)));
                                }
                                if (action === 'update') {
                                    permissionsArray.push(Permission.update(Role.team(id)));
                                }
                                if (action === 'delete') {
                                    permissionsArray.push(Permission.delete(Role.team(id)));
                                }
                            }
                        });
                    }

                    if (targets.labels) {
                        targets.labels.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    permissionsArray.push(Permission.read(Role.label(id)));
                                }
                                if (action === 'update') {
                                    permissionsArray.push(Permission.update(Role.label(id)));
                                }
                                if (action === 'delete') {
                                    permissionsArray.push(Permission.delete(Role.label(id)));
                                }
                            }
                        });
                    }
                }

                try {
                    const result = await databases.createDocument(
                        database,       // databaseId
                        collection,     // collectionId
                        documentID,     // documentId
                        { ...data },    // данные, которые будут отправлены
                        permissionsArray // permissions, если они заданы
                    );
                    if (success && success.message) {
                        let message = success.message;
                        let duration = success.duration || 0;
                        window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                    }
                    toggleSpinner(false);
                    setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                    if (success && success.push) {
                        let pushData = {
                            title: success.push.title || '',
                            body: success.push.body || '',
                            topics: success.push.topics || [],
                            users: success.push.users || [],
                            targets: success.push.targets || [],
                            data: {
                                url: `${config.host}${success.push.url}`.replace(/\$id/g, documentID)
                            }
                        };
                        
                        // Отправка запроса
                        try {
                            let response = await fetch(`${config.host}/push.php`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(pushData)
                            });
                        
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                        
                            let result = await response.json();
                            console.log('Response from server:', result);
                        } catch (error) {
                            console.error('There was a problem with the fetch operation:', error);
                        }
                    }

                    responseHandleForm(success);
                    return { response: 'success' };
                } catch (e) {
                    if (error && error.message) {
                        let message = error.message;
                        let duration = error.duration || 0;
                        window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                    } else {
                        window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                        console.log(e);
                    }
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: e };
                }
            } else {
                const tasks = JSON.parse(localStorage.getItem('tasks')) || [];

                if (!documentID) {
                    documentID = generateUniqueId();
                }

                setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                window.snackbarManager.addItem({ type: 'info', message: 'Данные сохранены и будут переданы на сервер, когда восстановится интернет', duration: 0 });
                responseHandleForm(success);

                success.js = null;
                success.link = null;

                tasks.push({ form, collection, documentID, data, permissions, success, error });
                localStorage.setItem('tasks', JSON.stringify(tasks));

                return { response: 'success' };
            }
        }
        if (form === 'update') {
            if (window.navigatorOnline) {
                toggleSpinner(true);

                const account = new Account(client);

                let anonymus = true;
                let verified = false;
                try {
                    const response = await account.get();
                    anonymus = !response.email && !response.phone;
                    verified = response.emailVerification || response.phoneVerification; // USER_ID пользователя
                    //console.log(response);
                    //console.log("USER_ID:", userID);
                } catch (error) {
                    //console.error("Ошибка при получении USER_ID:", error);
                }

                if (!documentID) {
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: 'Не указан id документа' };
                }

                /* // Подготовка данных для отправки
                const requestData = { ...data };

                // Исключаем data.prefs, если он пуст
                if (Object.keys(requestData.prefs || {}).length === 0) {
                    delete requestData.prefs;
                } */

                /* 'permissions': { 'read': { 'users': ['data.$id'] } }
                permissions = {
                    read: {
                        users: ['user1', 'user2'],
                        teams: ['team1'],
                        any: true
                    },
                    update: {
                        users: ['user3'],
                        labels: ['label1']
                    },
                    delete: {
                        teams: ['team2'],
                        users: ['user4', 'user5']
                    }
                }; */

                let permissionsArray = [];

                for (const [action, targets] of Object.entries(permissions)) {
                    if (targets.any) {
                        if (action === 'read') {
                            permissionsArray.push(Permission.read(Role.any()));
                        }
                        if (action === 'update') {
                            permissionsArray.push(Permission.update(Role.any()));
                        }
                        if (action === 'delete') {
                            permissionsArray.push(Permission.delete(Role.any()));
                        }
                    }

                    if (targets.users) {
                        targets.users.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    anonymus ?
                                        permissionsArray.push(Permission.read(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.read(Role.user(id))) :
                                            permissionsArray.push(Permission.read(Role.user(id, 'unverified')));
                                }
                                if (action === 'update') {
                                    anonymus ?
                                        permissionsArray.push(Permission.update(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.update(Role.user(id))) :
                                            permissionsArray.push(Permission.update(Role.user(id, 'unverified')));
                                }
                                if (action === 'delete') {
                                    anonymus ?
                                        permissionsArray.push(Permission.delete(Role.any())) :
                                        verified ?
                                            permissionsArray.push(Permission.delete(Role.user(id))) :
                                            permissionsArray.push(Permission.delete(Role.user(id, 'unverified')));
                                }
                            }
                        });
                    }

                    if (targets.teams) {
                        targets.teams.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    permissionsArray.push(Permission.read(Role.team(id)));
                                }
                                if (action === 'update') {
                                    permissionsArray.push(Permission.update(Role.team(id)));
                                }
                                if (action === 'delete') {
                                    permissionsArray.push(Permission.delete(Role.team(id)));
                                }
                            }
                        });
                    }

                    if (targets.labels) {
                        targets.labels.forEach(id => {
                            if (id !== null) {
                                if (action === 'read') {
                                    permissionsArray.push(Permission.read(Role.label(id)));
                                }
                                if (action === 'update') {
                                    permissionsArray.push(Permission.update(Role.label(id)));
                                }
                                if (action === 'delete') {
                                    permissionsArray.push(Permission.delete(Role.label(id)));
                                }
                            }
                        });
                    }
                }

                try {
                    // Проверяем, есть ли permissionsArray перед вызовом updateDocument
                    if (permissionsArray.length > 0) {
                        const result = await databases.updateDocument(
                            database,       // databaseId
                            collection,     // collectionId
                            documentID,     // documentId
                            { ...data },    // данные, которые будут отправлены
                            permissionsArray // permissions, если они заданы
                        );
                    } else {
                        const result = await databases.updateDocument(
                            database,       // databaseId
                            collection,     // collectionId
                            documentID,     // documentId
                            { ...data }    // обновление данных без изменения permissions
                        );
                    }
                    if (success && success.message) {
                        let message = success.message;
                        let duration = success.duration || 0;
                        window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                    }
                    toggleSpinner(false);
                    setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                    if (success && success.push) {
                        let pushData = {
                            title: success.push.title || '',
                            body: success.push.body || '',
                            topics: success.push.topics || [],
                            users: success.push.users || [],
                            targets: success.push.targets || [],
                            data: {
                                url: `${config.host}${success.push.url}`.replace(/\$id/g, documentID)
                            }
                        };
                        
                        // Отправка запроса
                        try {
                            let response = await fetch(`${config.host}/push.php`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(pushData)
                            });
                        
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                        
                            let result = await response.json();
                            console.log('Response from server:', result);
                        } catch (error) {
                            console.error('There was a problem with the fetch operation:', error);
                        }
                    }

                    responseHandleForm(success);
                    return { response: 'success' };
                } catch (e) {
                    if (error && error.message) {
                        let message = error.message;
                        let duration = error.duration || 0;
                        window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                    } else {
                        window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                        console.log(e);
                    }
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: e };
                }
            } else {
                const tasks = JSON.parse(localStorage.getItem('tasks')) || [];

                if (!documentID) {
                    responseHandleForm(error);
                    return { error: 'Не указан id документа' };
                }

                setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                window.snackbarManager.addItem({ type: 'info', message: 'Данные сохранены и будут переданы на сервер, когда восстановится интернет', duration: 0 });
                responseHandleForm(success);

                success.js = null;
                success.link = null;

                tasks.push({ form, collection, documentID, data, permissions, success, error });
                localStorage.setItem('tasks', JSON.stringify(tasks));

                return { response: 'success' };
            }
        }
        if (form === 'delete') {
            if (window.navigatorOnline) {
                toggleSpinner(true);

                if (!documentID) {
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: 'Не указан id документа' };
                }

                try {
                    const result = await databases.deleteDocument(
                        database, // databaseId
                        collection, // collectionId
                        documentID // documentId
                    );
               
                    if (success && success.message) {
                        let message = success.message;
                        let duration = success.duration || 0;
                        window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
                    }
                    toggleSpinner(false);
                    setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                    if (success && success.push) {
                        let pushData = {
                            title: success.push.title || '',
                            body: success.push.body || '',
                            topics: success.push.topics || [],
                            users: success.push.users || [],
                            targets: success.push.targets || [],
                            data: {
                                url: `${config.host}${success.push.url}`.replace(/\$id/g, documentID)
                            }
                        };
                        
                        // Отправка запроса
                        try {
                            let response = await fetch(`${config.host}/push.php`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(pushData)
                            });
                        
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                        
                            let result = await response.json();
                            console.log('Response from server:', result);
                        } catch (error) {
                            console.error('There was a problem with the fetch operation:', error);
                        }
                    }

                    responseHandleForm(success);
                    return { response: 'success' };
                } catch (e) {
                    if (error && error.message) {
                        let message = error.message;
                        let duration = error.duration || 0;
                        window.snackbarManager.addItem({ type: 'error', message: message, duration: duration });
                    } else {
                        window.snackbarManager.addItem({ type: 'error', message: e.message, duration: 0 });
                        console.log(e);
                    }
                    toggleSpinner(false);
                    responseHandleForm(error);
                    return { error: e };
                }
            } else {
                const tasks = JSON.parse(localStorage.getItem('tasks')) || [];

                if (!documentID) {
                    responseHandleForm(error);
                    return { error: 'Не указан id документа' };
                }

                setToStorage(database, collection, documentID, new Date().toISOString(), new Date().toISOString(), JSON.stringify(data));

                window.snackbarManager.addItem({ type: 'info', message: 'Данные сохранены и будут переданы на сервер, когда восстановится интернет', duration: 0 });
                responseHandleForm(success);

                success.js = null;
                success.link = null;

                tasks.push({ form, collection, documentID, data, permissions, success, error });
                localStorage.setItem('tasks', JSON.stringify(tasks));

                return { response: 'success' };
            }
        }
        if (form === 'subscribe-to-push') {
            // Получаем токен от Firebase
            const token = await obtainToken();

            if (!token) {
                for (let [topicId, value] of Object.entries(data)) {
                    let field = querySelectorDeep(document.body, `component-switch#${topicId}`);                    
                    if (value === true) {
                        if (field) {
                            let props = field.getAttribute("props");
                            if (props) {
                                props = JSON.parse(decodeURIComponent(atob(props)));
                            }
                            props.selected = false;
                            field.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                        }
                    } else if (value === null) {
                        if (field) {
                            let props = field.getAttribute("props");
                            if (props) {
                                props = JSON.parse(decodeURIComponent(atob(props)));
                            }
                            props.selected = true;
                            field.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                        }
                    }
                }
                return { error: 'Не получен токен от провайдера' };
            }

            // Получаем данные аккаунта
            const account = new Account(client);
            const userAccount = await account.get();

            // Ищем нужную запись в targets, чтобы определить записан ли токен Firebase в аккаунт
            let targetId;
            try {
                if (Array.isArray(userAccount.targets)) {
                    const target = userAccount.targets.find(t => t.identifier === token);
                    if (target) {
                        targetId = target.$id;
                    }
                }
            } catch (e) {
                console.error('Error parsing userAccount or finding target:', e);
            }

            if (!targetId) {
                // Добавляем token Firebase в аккаунт
                const result = await account.createPushTarget(
                    ID.unique(), // targetId
                    token, // identifier
                    'fcm' // providerId (optional)
                );
                targetId = result.$id;
            }

            const messaging = new Messaging(client);

            let pushSubscriptions = localStorage.getItem('pushSubscriptions') ? JSON.parse(localStorage.getItem('pushSubscriptions')) : {};
            let userSubscriptions = pushSubscriptions[userAccount.$id] || {};

            if (targetId) {
                /* try {
                    const result = await account.updatePushTarget(
                        targetId, // targetId
                        token // identifier
                    );
                    console.log(result);
                } catch (error) {
                    console.error(error);
                } */
                try {
                    for (let [topicId, value] of Object.entries(data)) {
                        if (value === true) {
                            const subscriberId = ID.unique();
                            // Подписываем на тему
                            const result = await messaging.createSubscriber(topicId, subscriberId, targetId);

                            // Обновляем объект pushSubscriptions
                            userSubscriptions[topicId] = subscriberId;

                            //console.log(`Successfully subscribed to ${topicId}:`, result);
                        } else if (value === null) {
                            // Отписываемся от темы
                            const result = await messaging.deleteSubscriber(topicId, userSubscriptions[topicId]);

                            // Удаляем из pushSubscriptions
                            //pushSubscriptions[topicId] = false;
                            delete userSubscriptions[topicId];

                            //console.log(`Successfully unsubscribed from ${topicId}:`, result);
                        }

                        pushSubscriptions[userAccount.$id] = userSubscriptions;
                        // Сохраняем обновленный pushSubscriptions обратно в localStorage
                        localStorage.setItem('pushSubscriptions', JSON.stringify(pushSubscriptions));
                    }
                    return { response: 'success' };
                } catch (error) {
                    console.error('Error updating subscriptions: ', error);
                    return { error: 'Ошибка обновления подписки' };
                }
            }

            return { response: 'success' };
        }
        if (form === 'test') {
            if (success && success.message) {
                let message = success.message;
                let duration = success.duration || 0;
                window.snackbarManager.addItem({ type: 'info', message: message, duration: duration });
            }
            toggleSpinner(false);
            responseHandleForm(success);
            return { response: 'success' };
        }
    }
}