import { parserCSS } from './parserCSS.js';
import { getIcon } from './getIcon.js';
import { convertColor } from './convertColor.js';
import { findColor } from './findColor.js';
import { design } from '../app.js';

export function createCSS(tree, rootContainerOrientation, prefix, theme = 'light') {
    let props;
    if (tree.name) {
        try {
            props = JSON.parse(tree.name.replace(/'/g, "\""));
        } catch (e) {
            props = false;
        }
    }
    let className = props ? props.name : tree?.name ? tree.name.replace(/ /g, "_") : tree.id;

    switch(tree._t) {
        case 'INSTANCE':
            if (getIcon(tree.componentId)) {
                return `
                    ${prefix ? `${prefix} > ` : ''}svg path {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                    }
                `;
            } else {
                let componentName = '';
                design.forEach(component => {
                    if (component._t == "COMPONENT" && tree.componentId == component.componentId ) {
                        componentName = component.name.split('/')[0];
                    }
                });
                if (componentName) {
                    return `
                        ${prefix ? `${prefix} > ` : ''}.component-${componentName} {
                            ${parserCSS(tree, rootContainerOrientation, theme)}
                            ${props && props?.style ? props.style : ''}
                            ${tree?.link || props?.onclick || props?.setProps ? 'cursor: pointer; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-user-select: none; user-select: none;' : ''}
                        }
                        ${props && props?.globStyle ? props.globStyle : ''}
                    `;
                }
            }
            break;
        case 'FRAME':
            return `
                ${prefix ? `${prefix} > ` : ''}.frame-${className} {
                    ${parserCSS(tree, rootContainerOrientation, theme)}
                    ${props && props?.style ? props.style : ''}
                    ${tree?.link || props?.onclick || props?.setProps ? 'cursor: pointer; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-user-select: none; user-select: none;' : ''}
                }
                ${props && props?.globStyle ? props.globStyle : ''}
                ${tree.blur && tree.blur.type === 3 ? `
                    ${prefix ? `${prefix} > ` : ''}.frame-${className}::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        backdrop-filter: blur(${tree.blur.radius}px);
                        -webkit-backdrop-filter: blur(${tree.blur.radius}px);
                        ${tree?.fills ? '' : 'background: rgba(255, 255, 255, 0.1);'}
                        z-index: 0;
                    }
                ` : ''}
                ${Array.isArray(tree.layers) ? `
                    ${tree.layers.map(layer => `${createCSS(layer, `${tree?.container && tree.container?.orientation ? `${tree.container.orientation == 1 ? 'column' : tree.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, prefix ? `${prefix} > .frame-${className}` : '', theme)}`).join('')}
                ` : ''}
            `;
            break;
        case 'TEXT':
            if (tree.name == 'input') {
                return `
                    ${prefix ? `${prefix} > ` : ''}.input {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                        -webkit-appearance:none;  
                        -webkit-border-radius:0px;
                        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                        -webkit-user-select: none;
                        user-select: none;
                        margin: 0;  
                        padding: 0;
                        background: none !important;
                        border: none;
                        outline: none;
                        ${props && props?.style ? props.style : ''}
                    }
                    ${props && props?.globStyle ? props.globStyle : ''}
                    ${prefix ? `${prefix} > ` : ''}.input:-webkit-autofill {
                        -webkit-text-fill-color: ${tree.textStyle?.color ? convertColor(tree.textStyle.color) : tree.textStyle?.colorId ?  findColor(tree.textStyle.colorId, theme) : '#000000'};
                    }
                    ${prefix ? `${prefix} > ` : ''}.input:-webkit-autofill,
                    ${prefix ? `${prefix} > ` : ''}.input:-webkit-autofill:hover,
                    ${prefix ? `${prefix} > ` : ''}.input:-webkit-autofill:focus,
                    ${prefix ? `${prefix} > ` : ''}.input:-webkit-autofill:active {
                        transition: background-color 5000s ease-in-out 5000s;
                    }
                `;
            } else if (tree.name == 'input-placeholder') {
                return `
                ${prefix ? `${prefix} > ` : ''}.input::placeholder {
                    ${parserCSS(tree, rootContainerOrientation, theme)}
                    ${props && props?.style ? props.style : ''}
                }
                ${props && props?.globStyle ? props.globStyle : ''}
            `;
            } else if (tree.name == 'textarea') {
                return `
                    ${prefix ? `${prefix} > ` : ''}.textarea {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                        -webkit-appearance:none;  
                        -webkit-border-radius:0px;
                        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                        -webkit-user-select: none;
                        user-select: none;
                        resize: none;
                        margin: 0;  
                        padding: 0;
                        background: none !important;
                        border: none;
                        outline: none;
                        ${props && props?.style ? props.style : ''}
                    }
                    ${props && props?.globStyle ? props.globStyle : ''}
                    ${prefix ? `${prefix} > ` : ''}.textarea:-webkit-autofill {
                        -webkit-text-fill-color: ${tree.textStyle?.color ? convertColor(tree.textStyle.color) : tree.textStyle?.colorId ?  findColor(tree.textStyle.colorId, theme) : '#000000'};
                    }
                    ${prefix ? `${prefix} > ` : ''}.textarea:-webkit-autofill,
                    ${prefix ? `${prefix} > ` : ''}.textarea:-webkit-autofill:hover,
                    ${prefix ? `${prefix} > ` : ''}.textarea:-webkit-autofill:focus,
                    ${prefix ? `${prefix} > ` : ''}.textarea:-webkit-autofill:active {
                        transition: background-color 5000s ease-in-out 5000s;
                    }
                `;
            } else if (tree.name == 'textarea-placeholder') {
                return `
                ${prefix ? `${prefix} > ` : ''}.textarea::placeholder {
                    ${parserCSS(tree, rootContainerOrientation, theme)}
                    ${props && props?.style ? props.style : ''}
                }
                ${props && props?.globStyle ? props.globStyle : ''}
            `;
            } else if (tree?.name && tree.name) {
                return `
                    ${prefix ? `${prefix} > ` : ''}.text-${className} {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                        ${props && props?.style ? props.style : ''}
                        ${tree?.link || props?.onclick || props?.setProps ? 'cursor: pointer; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-user-select: none; user-select: none;' : ''}
                    }
                    ${props && props?.globStyle ? props.globStyle : ''}
                `;
            }
            break;
        case 'RECT':
            if (props && props.type === 'img') {
                return `
                    ${prefix ? `${prefix} > ` : ''}.img-${className} {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                        ${props && props?.style ? props.style : ''}
                    }
                    ${props && props?.globStyle ? props.globStyle : ''}
                `;
            } else if (props && props.type === 'qr-scanner') {
                return `
                    ${prefix ? `${prefix} > ` : ''}.frame-${className} {
                        ${parserCSS(tree, rootContainerOrientation, theme)}
                        ${props && props?.style ? props.style : ''}
                    }
                    ${props && props?.globStyle ? props.globStyle : ''}
                `;
            } else {
                return null;
            }
            break;
        default: return null;
    }
}