import {config} from '../config.js';
import {getJWT} from './getJWT.js';
import {toggleSpinner} from './toggleSpinner.js';

export async function uploadImage(file) {
    toggleSpinner(true);

    let jwt_token = await getJWT();

    let formData = new FormData();
    formData.append('image', file);

    if (jwt_token) {
        formData.append('jwt_token', jwt_token);
    } else {
        toggleSpinner(false);
        window.snackbarManager.addItem({ type: 'error', message: 'Вы не авторизованы. Повторите вход и попробуйте снова.', duration: 0 });
        return 'Вы не авторизованы. Повторите вход и попробуйте снова.';
    }

    let response = await fetch(`${config.host}/upload.php`, {
        method: 'POST',
        body: formData
    });

    let result = await response.json();

    if (result.success) {
        toggleSpinner(false);
        //console.log(result.success);
        return result.success;
    } else {
        toggleSpinner(false);
        window.snackbarManager.addItem({ type: 'error', message: result.error, duration: 0 });
        return result.error;
    };
}