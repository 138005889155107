import {config} from '../config.js';
import { Client, Account } from "appwrite";

const endpoint = config.endpoint;
const project = config.project;

const client = new Client()
    .setEndpoint(endpoint)
    .setProject(project)
;

const account = new Account(client);

function setCookie(name, value, minutes) {
    const date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/;secure";
}

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export async function getJWT() {
    const token = getCookie('jwt_token');

    if (token) return token;

    try {
        const result = await account.createJWT();
        const jwt = result.jwt;
        setCookie('jwt_token', jwt, 14); // Expires in 14 minutes
        return jwt;
    } catch (error) {
        console.error('Error fetching JWT:', error);
        return false;
    }
}