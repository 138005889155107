import {querySelectorAllDeep} from './querySelectorAllDeep.js';
// Функция для сбора данных формы в JSON
export async function collectFormData(form) {
    const fields = querySelectorAllDeep('component-input-text, component-textarea, component-input-checkbox, component-select', form);
    const data = {};
    let hasError = false;

    fields.forEach(field => {
        if (field.id) {
            let props = field.getAttribute("props");
            if (props) {
                props = JSON.parse(decodeURIComponent(atob(props)));
            }
            let value = props?.value ? props.value : null;
            let required = props?.required ? props.required : false;
            if (!value && required) {
                field.setAttribute('error', 'Поле не заполнено');
                hasError = true;
            }
            data[field.id] = value;
        }
    });

    if (hasError) {
        return { error: 'Заполните все обязательные поля' };
    } else {
        return { response: JSON.stringify(data) };
    }
}