/* export function querySelectorDeep(root, selector) {
    // Проверяем, есть ли у текущего элемента shadowRoot
    if (root.shadowRoot) {
        root = root.shadowRoot;
    }

    // Ищем элемент в текущем дереве
    let element = root.querySelector(selector);

    // Если элемент найден, возвращаем его
    if (element) {
        return element;
    }

    // Если элемент не найден, ищем в shadowRoot дочерних элементов
    for (let i = 0; i < root.children.length; i++) {
        element = querySelectorDeep(root.children[i], selector);
            if (element) {
            return element;
        }
    }

    // Если элемент не найден ни в одном из деревьев, возвращаем null
    return null;
} */
export function querySelectorDeep(root, selector) {
    if (!root || !(root instanceof Node)) {
        return null; // Защита от элементов, не являющихся узлами
    }

    // Проверяем, есть ли у текущего элемента shadowRoot
    if (root.shadowRoot) {
        const shadowElement = querySelectorDeep(root.shadowRoot, selector);
        if (shadowElement) {
            return shadowElement;
        }
    }

    // Ищем элемент в текущем дереве
    const element = root.querySelector(selector);
    if (element) {
        return element;
    }

    // Если элемент не найден, ищем в shadowRoot дочерних элементов
    const children = root.children; // Используем children для поиска только элементов
    for (let i = 0; i < children.length; i++) {
        const childElement = querySelectorDeep(children[i], selector);
        if (childElement) {
            return childElement;
        }
    }

    // Если элемент не найден ни в одном из деревьев, возвращаем null
    return null;
}