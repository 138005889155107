export function convertColor(color) {
    if (color.length === 4) {
        var a = parseInt(color.charAt(0), 16) / 15;
        var r = parseInt(color.charAt(1), 16) * 17;
        var g = parseInt(color.charAt(2), 16) * 17;
        var b = parseInt(color.charAt(3), 16) * 17;
        color = 'rgba(' + r + ',' + g + ',' + b + ',' + a.toFixed(2) + ')';
    } else if (color.length === 2) {
        color = color + color + color;
    } else if (color.length === 1) {
        color = color + color + color + color + color + color;
    } else if (color.length > 6) {
        var argb = parseInt(color, 16);
        var a = ((argb >> 24) & 0xFF) / 255;
        var r = (argb >> 16) & 0xFF;
        var g = (argb >> 8) & 0xFF;
        var b = argb & 0xFF;
        color = 'rgba(' + r + ',' + g + ',' + b + ',' + a.toFixed(2) + ')';
    }
    if (!color.startsWith('#') && color.length <= 7) {
        color = '#' + color;
    }
    return color;
}