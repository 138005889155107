import {querySelectorDeep} from './querySelectorDeep.js';

function getAbsoluteCoords(elem) {
    let top = 0, left = 0;
    while (elem) {
        top += elem.offsetTop || 0;
        left += elem.offsetLeft || 0;
        elem = elem.offsetParent;
    }

    return { top, left };
}

export function scrollToElement(selector, attempt = 0, behavior = 'smooth') {
    const targetElement = querySelectorDeep(document.getElementById('app').shadowRoot, selector);
    if (!targetElement && attempt < 3) {
        setTimeout(() => {
            scrollToElement(selector, attempt + 1);
        }, 50);
    } else if (!targetElement && attempt >= 3) {
        console.warn('Target element not found for the given selector:', selector);
        return;
    }

    let coords = getAbsoluteCoords(targetElement);
    const scrollY = Math.round(window.scrollY);

    if (coords.top !== scrollY) {
        window.scrollTo({
            top: coords.top,
            //left: coords.left,
            behavior: behavior
        });
    } else if (coords.top === scrollY) {
        requestAnimationFrame(() => {
            window.scrollTo({
                top: 0
            });
        });
        requestAnimationFrame(() => {
            coords = getAbsoluteCoords(targetElement);
            if(coords.top > 0) {
                scrollToElement(selector, attempt = 0, 'instant');
            }
        });
    }

    function scrollToParent(parent, child) {
        if (parent && parent !== document.body && parent instanceof HTMLElement) {
            const childRect = child.getBoundingClientRect();
            const parentRect = parent.getBoundingClientRect();

            const scrollPaddingLeft = parseInt(window.getComputedStyle(parent).scrollPaddingLeft, 10) || 0;
            const scrollLeft = parent.scrollLeft + (childRect.left - parentRect.left) - scrollPaddingLeft;
            //const scrollTop = parent.scrollTop + (childRect.top - parentRect.top);

            parent.scroll({
                //top: scrollTop,
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    }

    let currentElement = targetElement;
    while (currentElement) {
        const scrollableParent = getScrollableParent(currentElement.parentNode);
        if (scrollableParent) {
            scrollToParent(scrollableParent, currentElement);
        }
        currentElement = scrollableParent;
    }
}

function getScrollableParent(element) {
    while (element && element !== document.body) {
        if (element instanceof ShadowRoot) {
            element = element.host;
        }
        if (!(element instanceof Element)) {
            return null;
        }

        const hasScrollableContent = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        const overflowY = window.getComputedStyle(element).overflowY;
        const overflowX = window.getComputedStyle(element).overflowX;
        const isScrollable = (overflowY === 'auto' || overflowY === 'scroll') || (overflowX === 'auto' || overflowX === 'scroll');

        if (hasScrollableContent && isScrollable) {
            return element;
        }

        element = element.parentNode || element.host;
    }
    return null;
}